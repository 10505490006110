import React, { useState, useEffect, useRef, memo } from 'react';


const PuzzleCaptcha = memo((props) => {
  const {
    width = 320,
    height = 160,
    l = 42,
    r = 9,
    text = 'Slide to complete the puzzle',
    refreshIcon = `/images/captchaImages/reset.png`,// Ensure the refresh icon is placed in the public folder
    visible = true,
    onDraw,
    onCustomVerify,
    onSuccess,
    onFail,
    onRefresh,
  } = props;

  const [loading, setLoading] = useState(false);
  const [moveX, setMoveX] = useState(0);
  const [sliderClass, setSliderClass] = useState('sliderContainer');
  const [sliderText, setSliderText] = useState(text);

  const canvasRef = useRef(null);
  const blockRef = useRef(null);
  const imgRef = useRef(null);
  const isMouseDown = useRef(false);
  const trail = useRef([]);
  const originX = useRef(0);
  const originY = useRef(0);
  const x = useRef(0);
  const y = useRef(0);

  const PI = Math.PI;
  const L = l + 2 * r + 3; // Block size

  const getRandomNumber = (min, max) => Math.round(Math.random() * (max - min) + min);

  const getRandomImageSrc = () => {
    const randomIndex = getRandomNumber(1, 1084);
    return `/images/captchaImages/plasma_fractal${randomIndex}.jpg`;
  };

  // Modified createImg function to load images locally without HTTP requests
  const createImg = (onload) => {
    const img = new Image();
    img.onload = onload;
    img.onerror = () => {
      img.src = getRandomImageSrc();
    };
    img.src = getRandomImageSrc();
    return img;
  };

  const drawPath = (ctx, x, y, operation) => {
    ctx.beginPath();
    ctx.moveTo(x, y);
    ctx.arc(x + l / 2, y - r + 2, r, 0.72 * PI, 2.26 * PI);
    ctx.lineTo(x + l, y);
    ctx.arc(x + l + r - 2, y + l / 2, r, 1.21 * PI, 2.78 * PI);
    ctx.lineTo(x + l, y + l);
    ctx.lineTo(x, y + l);
    ctx.arc(x + r - 2, y + l / 2, r + 0.4, 2.76 * PI, 1.24 * PI, true);
    ctx.lineTo(x, y);
    ctx.lineWidth = 2;
    ctx.fillStyle = 'rgba(255, 255, 255, 0.7)';
    ctx.strokeStyle = 'rgba(255, 255, 255, 0.7)';
    ctx.stroke();
    ctx.globalCompositeOperation = 'destination-over';
    operation === 'fill' ? ctx.fill() : ctx.clip();
  };

  const draw = (img) => {
    const canvasCtx = canvasRef.current.getContext('2d');
    const blockCtx = blockRef.current.getContext('2d');

    // Generate random position
    x.current = getRandomNumber(L + 10, width - (L + 10));
    y.current = getRandomNumber(10 + 2 * r, height - (L + 10));

    // Draw puzzle piece on canvas
    drawPath(canvasCtx, x.current, y.current, 'fill');
    drawPath(blockCtx, x.current, y.current, 'clip');

    // Draw image on canvas
    canvasCtx.drawImage(img, 0, 0, width, height);
    blockCtx.drawImage(img, 0, 0, width, height);

    // Get the block image data
    const yPos = y.current - 2 * r - 1;
    const imageData = blockCtx.getImageData(x.current - 3, yPos, L, L);
    blockRef.current.width = L;
    blockCtx.putImageData(imageData, 0, yPos);
  };

  const initImg = () => {
    setLoading(true);
    const img = createImg(() => {
      setLoading(false);
      draw(img);
    });
    imgRef.current = img;
  };

  const reset = () => {
    const canvasCtx = canvasRef.current.getContext('2d');
    const blockCtx = blockRef.current.getContext('2d');

    setMoveX(0);
    setSliderClass('sliderContainer');
    blockRef.current.width = width;
    blockRef.current.style.left = '0px';

    canvasCtx.clearRect(0, 0, width, height);
    blockCtx.clearRect(0, 0, width, height);

    setLoading(true);
    imgRef.current.src = getRandomImageSrc();
  };

  const handleRefresh = () => {
    reset();
    if (typeof onRefresh === 'function') {
      onRefresh();
    }
  };

  const handleDragStart = (e) => {
    originX.current = e.clientX || e.touches[0].clientX;
    originY.current = e.clientY || e.touches[0].clientY;
    isMouseDown.current = true;
  };

  const handleDragMove = (e) => {
    if (!isMouseDown.current) return false;
    e.preventDefault();

    const eventX = e.clientX || e.touches[0].clientX;
    const eventY = e.clientY || e.touches[0].clientY;

    const moveX = eventX - originX.current;
    const moveY = eventY - originY.current;

    if (moveX < 0 || moveX + 38 >= width) return false;

    setMoveX(moveX);
    const blockLeft = ((width - 40 - 20) / (width - 40)) * moveX;
    blockRef.current.style.left = `${blockLeft}px`;

    setSliderClass('sliderContainer sliderContainer_active');
    trail.current.push(moveY);

    if (onDraw) {
      onDraw(blockLeft);
    }

    return true;
  };

  const handleDragEnd = (e) => {
    if (!isMouseDown.current) return false;
    isMouseDown.current = false;

    if ((e.clientX || e.changedTouches[0].clientX) === originX.current) return false;

    setSliderClass('sliderContainer');

    const data = onCustomVerify ? onCustomVerify(verifyPuzzle()) : verifyPuzzle();
    const { spliced, verified } = data;

    if (spliced) {
      if (verified) {
        setSliderClass('sliderContainer sliderContainer_success');
        if (typeof onSuccess === 'function') {
          onSuccess();
        }
      } else {
        setSliderClass('sliderContainer sliderContainer_fail');
        setSliderText('Please try again');
        reset();
      }
    } else {
      setSliderClass('sliderContainer sliderContainer_fail');
      if (typeof onFail === 'function') {
        onFail();
      }
      setTimeout(() => {
        reset();
      }, 1000);
    }

    return true;
  };

  const verifyPuzzle = () => {
    const arr = trail.current;
    const average = arr.reduce((sum, val) => sum + val, 0) / arr.length;
    const deviations = arr.map((y) => y - average);
    const stddev = Math.sqrt(
      deviations.map((d) => d * d).reduce((sum, val) => sum + val, 0) / arr.length
    );

    const left = parseInt(blockRef.current.style.left, 10);
    return {
      spliced: Math.abs(left - x.current) < 10,
      verified: stddev !== 0,
      left,
      destX: x.current,
    };
  };

  useEffect(() => {
    if (visible) {
      if (imgRef.current) {
        reset();
      } else {
        initImg();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <div
      className="verifyWrap"
      style={{ width: `${width}px`, margin: '0 auto', display: visible ? '' : 'none' }}
      onMouseMove={handleDragMove}
      onMouseUp={handleDragEnd}
      onTouchMove={handleDragMove}
      onTouchEnd={handleDragEnd}
    >
      <div className="canvasArea">
        <canvas ref={canvasRef} width={width} height={height} />
        <canvas
          ref={blockRef}
          className="block"
          width={width}
          height={height}
          onMouseDown={handleDragStart}
          onTouchStart={handleDragStart}
        />
      </div>
      <div
        className={sliderClass}
        style={{ pointerEvents: loading ? 'none' : 'auto', width: `${width}px` }}
      >
        <div className="sliderMask" style={{ width: `${moveX}px` }}>
          <div
            className="slider"
            style={{ left: `${moveX}px` }}
            onMouseDown={handleDragStart}
            onTouchStart={handleDragStart}
          >
            <div className="sliderIcon">→</div>
          </div>
        </div>
        <div className="sliderText">{sliderText}</div>
      </div>
      <div
        className="refreshIcon"
        onClick={handleRefresh}
        style={{ backgroundImage: `url(${refreshIcon})` }}
      />
      {loading && (
        <div
          className="loadingContainer"
          style={{ width: `${width}px`, height: `${height}px` }}
        >
          <div className="loadingIcon" />
          <span>Loading...</span>
        </div>
      )}
    </div>
  );
});

export default PuzzleCaptcha;
