import React from "react";
import { Text, Menu,MenuButton,MenuList,MenuItem, Icon,   } from '@chakra-ui/react';
import  {  MdLogin, MdOutlineDashboard,MdOutlineDataset,  MdOutlineHome,  MdOutlineTableChart}  from 'react-icons/md'
import { Link, useNavigate} from "react-router-dom";



function NavbarGuestArea(){

  
  const navigate = useNavigate();
  const backToLogin = () => {
    sessionStorage.setItem("username", "anonymous");
    navigate('/login')
    window.location.reload()
  }

  return(
          
                <Menu minH="100%" minW="100%" >
                  
                      <Link to={"/guestApp"}> 
                            <MenuItem _focus={{bg:"#006778"}} paddingRight={0} paddingLeft={0} paddingTop={0} mb={2} paddingBottom={0} h="100%"> 
                              
                            <MenuButton color={"yellowgreen"} w="100%" h="100%" paddingRight={"2%"} paddingLeft={"2%"} paddingTop={"2%"} paddingBottom={"2%"}
                              transition='all 0.2s' borderRadius='md' _hover={{ boxShadow:"dark-lg" }}_expanded={{ bg: 'blue.400' }}
                              _focus={{ boxShadow: 'outline' }}><Icon as={MdOutlineHome}  w={"30%"} h={"30%"} /><Text fontSize={"sm"}>Home</Text>  </MenuButton>
                            </MenuItem>
                        </Link>
                      <Link to={"/datasetGuest"}> 
                        <MenuItem _focus={{bg:"#006778"}} paddingRight={0} paddingLeft={0} paddingTop={0} mb={2} paddingBottom={0} h="100%"> 
                          
                        <MenuButton color={"yellowgreen"} w="100%" h="100%" paddingRight={"2%"} paddingLeft={"2%"} paddingTop={"2%"} paddingBottom={"2%"}
                          transition='all 0.2s' borderRadius='md' _hover={{ boxShadow:"dark-lg" }}_expanded={{ bg: 'blue.400' }}
                          _focus={{ boxShadow: 'outline' }}><Icon as={MdOutlineDataset}  w={"30%"} h={"30%"}/><Text fontSize={"sm"}>Datasets</Text>  </MenuButton>
                        </MenuItem>
                      </Link>
            

                      <Link to={"/dashboardGuest"}>
                      <MenuItem _focus={{bg:"#006778"}} paddingRight={0} paddingLeft={0} paddingTop={0} mb={2} paddingBottom={0} h="100%"> 
                          
                          <MenuButton color={"yellowgreen"} w="100%" h="100%" paddingRight={"2%"} paddingLeft={"2%"} paddingTop={"2%"} paddingBottom={"2%"}
                            transition='all 0.2s' borderRadius='md' _hover={{ boxShadow:"dark-lg" }}_expanded={{ bg: 'blue.400' }}
                            _focus={{ boxShadow: 'outline' }}>
                          <Icon as={MdOutlineDashboard}  w={"30%"} h={"30%"}/><Text fontSize={"sm"}>Dashboard</Text> </MenuButton>
                        </MenuItem>
                      </Link>

                      <Link>
                        <MenuItem _focus={{bg:"#006778"}} paddingRight={0} paddingLeft={0} paddingTop={0} mb={2} paddingBottom={0} onClick={backToLogin}>
                        <MenuButton color={"yellowgreen"} w="100%" h="100%" paddingRight={"2%"} paddingLeft={"2%"} paddingTop={"2%"} paddingBottom={"2%"}
                            transition='all 0.2s' borderRadius='md' _hover={{ boxShadow:"dark-lg" }}_expanded={{ bg: 'blue.400' }}
                            _focus={{ boxShadow: 'outline' }}>
                          <Icon as={MdLogin} w={"30%"} h={"30%"}/><Text fontSize={"sm"}>Login</Text>  </MenuButton>
                        </MenuItem>
                      </Link>


                      
                    
                </Menu>
           
  
          
        
        )
        
      }
      
        
   

     

   



  export default NavbarGuestArea;



    