import { react_api } from "../config";
import { useEffect, useState } from "react";
import { Box, useToast} from "@chakra-ui/react";


import logout from "../services/authService";
import createAxiosInstance from "../interceptors/useAxiosInterceptors"

import { useSession } from '../SessionContext';
import {  useNavigate} from "react-router-dom";

export default function Superset() {
    const toast = useToast();
   

   
    const { updateSessionData, clearSessionData} = useSession();
    const navigate = useNavigate();
    const axiosInstance = createAxiosInstance(async (axiosInstance)=> {

        logout(clearSessionData,navigate,toast,axiosInstance)
      });
   const [htmlContent, setHtmlContent] = useState(``);
    useEffect(() => {
    const fetchJupyter = async () => {
        try{

         
            const options =   {
                
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization':  sessionStorage.getItem("token")
                }
                
               
              }

            


            console.info("Chiamata verso ", `${react_api}/linkSupersetADP`);
            const urlFetch = `${react_api}/linkSupersetADP`;
            const response = await axiosInstance(urlFetch, options);
            console.info("response: ", response);
            
            const risposta =  response.data;
            console.info("risposta ", risposta )
            setHtmlContent(risposta);
            //const data = await response.json(); // Assumi che la risposta sia JSON con un campo URL
            //console.info("risposta in json:" , data);
             // Sostituisci data.url con il campo corretto
            
        }
        catch (error) {
            toast({
               title: 'Chiamata in errore',
               description: String(error),
               position: "top",
               status: 'warning',
               duration: 4000,
               isClosable: true});
         }


    } ;
    fetchJupyter();
    }
    , []);    

    return(<Box 
      dangerouslySetInnerHTML={{ __html: htmlContent }} h={"100%"}/> )
   





}