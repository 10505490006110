import { useEffect, useState } from "react";
import { TableContainer, Tr, Td, Th, Table, Thead, Tbody,  Menu, MenuButton, MenuList, MenuItem, Icon, Box, Heading, useToast, Text, HStack,  Modal, ModalOverlay, ModalContent, ModalHeader, CloseButton, ModalBody, } from "@chakra-ui/react";
//import { MdEditDocument, MdNote, MdSearch, MdEdit, } from "react-icons/md";
import { SearchIcon} from '@chakra-ui/icons';
import { react_api } from "../config";
import { HTTP_STATUS_CODES } from "../GrinsCostant";
import { MdPublic, MdTransform } from "react-icons/md";
import createAxiosInstance from "../interceptors/useAxiosInterceptors"
import { useSession } from '../SessionContext';
import {  useNavigate} from "react-router-dom";
import logout from "../services/authService";
 import convertUtcToLocal from "../services/utils";
/*
  Carica i risultati in una tabella ogni volta che viene caricata la pagina (useEffect) interrogando l'endpoint con
  l'invio di un json contente il token di identificaione, l'username dellutente e una costante caseType per indicare al backend
  di quale chiamata si tratta e quali paremetri deve inviarci. Per consultare i case type consultare il file CaseTypeConstant.js  
  */
 
 
 
 
 
export default function Risultati() {
  const [catalog, setCatalog] = useState([]);
  const toast = useToast();
  const [message, setMessage] = useState("");
 
 
  const { updateSessionData, clearSessionData} = useSession();
 
  const navigate = useNavigate();
 
 
  const axiosInstance = createAxiosInstance(async (axiosInstance)=> {
 
    logout(clearSessionData,navigate,toast,axiosInstance)
  });
  
  const [keys, setKeys] = useState([]);
  const [resultList, setResultList] = useState([]);
  const [resultTableName, setResultTableName] = useState("");
  const [isResultModalOpen, setResultModalOpen] = useState(false);

  useEffect(() => {
    const fetchCatalog = async () => {
    try {
       
        const richiesta = {
          BearerToken : sessionStorage.getItem("token"),
          username: sessionStorage.getItem("username"),
          //caseType: CASE_TYPE.CATALOG_ALL,
        }
        const options =   {
       
          headers: {
            'Content-Type': 'application/json',
          }
        }
        
       
        const urlFetch = `${react_api}/privateResults/getCatalogADP`;
        
        const response = await axiosInstance.post(urlFetch,richiesta, options);
      
        const result = response.data;
       
        if( result.status === HTTP_STATUS_CODES.OK){
          setCatalog(result.catalog)
           }
       
        else{
          toast({
          title: 'Tabella dei risultati vuota',
          description: String(result.message),
          position: "top",
          status: 'info',
          duration: 4000,
          isClosable: true});
          setMessage(result.message);}
    } catch (error) {
      toast({
          title: 'Chiamata in errore',
          description: String(error),
          position: "top",
          status: 'warning',
          duration: 4000,
          isClosable: true});
    }
      };
    fetchCatalog();}
, []);

const closeResultModal = () => {
  setResultModalOpen(false);
};
const openResultModal = (response, tableName) => {
  
  setKeys(Object.keys(response.previewTable[0]))
  setResultList(response.previewTable);
  setResultTableName(tableName);
  setResultModalOpen(true);
};

const visualizzaResult = async (tableName) => {
  try {
  
    const richiesta = {
      username : sessionStorage.getItem("username"),
      tableName : tableName
    }
    const options = {

      headers: {
        'Content-Type': 'application/json',
        'Authorization' : sessionStorage.getItem('token')
      }

    }
    
    const urlFetch = `${react_api}/getTableADP`;
  
    const response = await axiosInstance.post(urlFetch, richiesta, options); 
    
    const result =  response.data;
    if( result.status === HTTP_STATUS_CODES.OK){
      openResultModal(result, tableName)}
     
} catch (error) {
  toast({
      title: "La visualizzazione dell'elaborazione è fallita",
      description: String(error),
      position: "top",
      status: 'warning',
      duration: 4000,
      isClosable: true});
} 
}
   
        return(
          <>
      <Box alignItems={"center"} justifyContent={"center"} display={"flex"} color={"rgba(11,78,90,255)"} bgColor={"#f6f3ec"}>
        <Heading>My Results</Heading></Box>
        <Box bgColor={"white"}  overflowY="auto" direction="column" h="100%" w="100%" boxShadow="lg">
          <TableContainer px={"1%"} paddingTop={"1%"} >
         
            <Table variant='striped'>
             
              <Thead bgColor={"#0888BE"}>
                <Tr>
                  <Th  whiteSpace={"normal"} wordBreak={"break-word"} color={"white"}>Id</Th>
                  <Th  whiteSpace={"normal"} wordBreak={"break-word"} color={"white"}>Dataset</Th>
                  <Th  whiteSpace={"normal"} wordBreak={"break-word"} color={"white"}>Titolo</Th>
                  <Th  whiteSpace={"normal"} wordBreak={"break-word"} color={"white"}>Data di creazione</Th>
                  <Th  whiteSpace={"normal"} wordBreak={"break-word"} color={"white"}>Azioni</Th>
                </Tr>
              </Thead>
             
               
              <Tbody>
              {catalog.length > 0 ? (
              (catalog.map((item, index)  => (
                <Tr>
                  <Td whiteSpace={"normal"} wordBreak={"break-word"}>{item.tableId}</Td>
                  <Td whiteSpace={"normal"} wordBreak={"break-word"}>{item.tableName}</Td>
                  <Td whiteSpace={"normal"} wordBreak={"break-word"}>{item.title}</Td>
                  <Td whiteSpace={"normal"} wordBreak={"break-word"}>{convertUtcToLocal(item.createTime)}</Td>
                  <Td>
                  <HStack align={"center"}  wrap="wrap"><Icon as={SearchIcon} onClick={() =>visualizzaResult(item.tableName)} _hover={{color: "#0888BE"}} /> </HStack>
                  </Td>
                </Tr>)) ) ):(<Tr><Text fontSize={"lg"} fontWeight={"bold"}>{message}</Text></Tr>) }
              </Tbody>  
            </Table>
 
        </TableContainer></Box>
        
        
        <Modal onClose={closeResultModal} isOpen={isResultModalOpen} isCentered   >
      <ModalOverlay />
      <ModalContent  maxW="60%" h="50%">
        <ModalHeader bgColor={"#0888BE"} color="white" display="flex" justifyContent="space-between"><Box>Vista del risultato: <Text fontSize={"lg"} fontWeight={"bold"} color="yellowgreen">{resultTableName}</Text></Box><Box><CloseButton onClick={closeResultModal} /> </Box></ModalHeader>
        <ModalBody overflow={"auto"} h={"100%"}>
          <Box   >
        <Table variant="striped" size={"sm"} h={"90%"} w="100%">
        <Thead bgColor={"#0888BE"} position={"sticky"}>
          <Tr>
            {keys ? (
            keys.map((key) => (
              <Th  color="white"  key={key}>{key}</Th> // Renderizza le chiavi come intestazioni di colonna
            ))):("")}
          </Tr>
        </Thead>
        <Tbody>
        {resultList.length > 0 ?
          (resultList.map((item, index) => (
            <Tr key={index}>
              {keys.map((key) => (
                <Td key={key}>{item[key]}</Td> // Renderizza il valore corrispondente alla chiave
              ))}
            </Tr>
          ))):("")}
        </Tbody>
      </Table></Box>
        </ModalBody>
      </ModalContent>
    </Modal></>
)
}
 
 
 