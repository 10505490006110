
import {Box, Heading, Table, Tbody, Tr, Td, Th, Thead, useToast, Text} from "@chakra-ui/react";
import { useEffect, useState} from "react";
import { react_api } from "../config";
import  { HTTP_STATUS_CODES } from "../GrinsCostant";
import {CASE_TYPE} from '../CaseTypeCostant';
import logout from "../services/authService";
import createAxiosInstance from "../interceptors/useAxiosInterceptors"


import { useSession } from '../SessionContext';
import {  useNavigate} from "react-router-dom";

export default function HomeCard() {
  const toast = useToast();
  const [catalog, setCatalog] = useState([]);
  const [message, setMessage] = useState("");


  const { updateSessionData, clearSessionData} = useSession();
 
  const navigate = useNavigate();

  const axiosInstance = createAxiosInstance(async (axiosInstance)=> {

    logout(clearSessionData,navigate,toast,axiosInstance)
  });
  /*
  Carica i dataset ottenuti in una tabella ogni volta che viene caricata la pagina (useEffect) interrogando l'endpoint con 
  l'invio di un json contente il token di identificaione, l'username dellutente e una costante caseType per indicare al backend 
  di quale chiamata si tratta e quali paremetri deve inviarci. Per consultare i case type consultare il file CaseTypeConstant.js  
  */
    
  useEffect(() => {
 
  
    const fetchCatalog = async () => {
      try{
      
        const richiesta = {
          BearerToken : sessionStorage.getItem("token"),
          username: sessionStorage.getItem("username"),
          caseType: CASE_TYPE.CATALOG_OBTAINED_DATASETS,
        }
        const options =   {
        
          headers: {
            'Content-Type': 'application/json',
          }
         
        }
    
        
        const urlFetch = `${react_api}/privateCatalog/getCatalogADP`;
       
        const response = await axiosInstance.post(urlFetch,richiesta, options)
       
        const result = response.data;
        if (result.status === HTTP_STATUS_CODES.OK){
          setCatalog(result.catalog) 
        }
        else{
          
          setMessage(result.message);}
     } catch (error) {
       toast({
           title: 'Chiamata in errore',
           description: String(error),
           position: "top",
           status: 'warning',
           duration: 4000,
           isClosable: true});
     }
       };
     
    
    
       fetchCatalog();
  
  
  
  }
, []);
    

   
    return( 
            
      <Box h="100%" w="100%" >
        <Heading  size='md'  color={"rgba(11,78,90,255)"} >Datasets Ottenuti</Heading>
          <Box  overflowY="auto" direction="column" h="200px"  boxShadow="lg" borderRadius={"10px"}>
           
              <Table size="sm" variant='striped' bgcolor="white" >
                <Thead fontFamily={"-moz-initial"} bg={"#0888BE"} position="sticky" top="0" >
                  <Tr>
                    <Th  color={"white"} w={"15%"} >Dataset</Th>
                    <Th  color={"white"} w={"15%"}>Categoria</Th>
                    <Th  color={"white"} w={"30%"}>Descrizione</Th>
                    <Th  color={"white"} w={"20%"}>Manager</Th>
                    <Th  color={"white"} w={"20%"}>Visibilità</Th>
                    </Tr>
                  </Thead>
                  <Tbody>  
                  {catalog.length > 0 ? (
                    (catalog.map((item, index)  => (
                      <Tr > 
                        <Td whiteSpace={"normal"} wordBreak={"break-word"} >{item.TBL_NAME}</Td>
                        <Td whiteSpace={"normal"} wordBreak={"break-word"} >{item.category}</Td>
                        <Td whiteSpace={"normal"} wordBreak={"break-word"} >{item.description}</Td>
                        <Td whiteSpace={"normal"} wordBreak={"break-word"} >{item.OWNER}</Td>
                        <Td whiteSpace={"normal"} wordBreak={"break-word"} >{item.visibility}</Td>
                      </Tr>
                     )))):(<Tr alignContent={"center"}><Text fontSize={"lg"} color="rgba(11,78,90,255)">{message}</Text></Tr>)}
                        
                        
                      
                        
                  </Tbody>
                </Table>
           

          </Box>
      </Box>)








}