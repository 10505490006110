import { Box, Button, Text, Heading } from "@chakra-ui/react";
import { useNavigate} from "react-router-dom";
export default function DescriptionCard(props) {
    const immagine = props.ImgUrl
    const descrizione = props.description
    const titolo = props.title
    const percorso = props.path
    const navigate = useNavigate();
    const goTo = () => {
        sessionStorage.setItem("username", "anonymous");
        navigate({percorso})
        window.location.reload()
      }
  return (
    <Box
      position="relative"
      height="300px"
      width="100%"
      bgImage={immagine}
      bgSize="cover"
      bgPosition="center"
      display="flex"
      alignItems="center"
      justifyContent="center"
      _hover={{boxShadow: "dark-lg"}}
    >
      {/* Overlay trasparente */}
      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        bg="rgba(0, 0, 0, 0.5)" // Sfondo trasparente
      />

      {/* Contenuto */}
      <Box
        position="relative"
        color="white"
        textAlign="center"
        padding="20px"
        maxWidth="600px"
        paddingBottom={0}
      >
        
        <Heading as="h2" size="xl" mb={4} fontWeight="bold" lineHeight="1.2">
          {titolo}
        </Heading>
        <Text fontSize="lg" mb={6}>
          {descrizione}
        </Text>
        <Button colorScheme="green" size="lg" fontWeight="bold" onClick={goTo}>
          Scopri il Progetto
        </Button>
      </Box>
    </Box>
  );
}
