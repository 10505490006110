import {React, useState, useRef} from "react";
import {useToast, Text,NumberInput,NumberInputField,NumberInputStepper,NumberIncrementStepper,NumberDecrementStepper,
  Menu,MenuButton,MenuList,MenuItem,MenuDivider,Icon,   Avatar,  AvatarBadge,Modal,
  ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, Box, Button, useDisclosure, Stack, FormControl, FormLabel, Select, Input , 
  CloseButton, Switch} from '@chakra-ui/react';
import  { MdAddBox,MdLockReset, MdOutlineDashboard,MdOutlineDataset, MdOutlineHome,      MdOutlinePerson,   MdOutlineReceipt, MdOutlineTableChart, MdStopCircle}  from 'react-icons/md'
import {  SearchIcon } from "@chakra-ui/icons";
import { Link, useNavigate} from "react-router-dom";
import { useSession } from '../SessionContext';
import { IoMdPower, IoMdStats } from "react-icons/io";
import { SiJupyter } from "react-icons/si";
import { CgSearchLoading } from "react-icons/cg";
import { HiMiniAdjustmentsHorizontal } from "react-icons/hi2"
import { GiZeppelin, GiGearHammer } from "react-icons/gi";
import { PiSupersetOf } from "react-icons/pi";
import { react_api } from "../config";
import { HTTP_STATUS_CODES } from "../GrinsCostant";
import createAxiosInstance from "../interceptors/useAxiosInterceptors"
import logout from "../services/authService";

function Navbar(){
  const toast = useToast();
  const fileFormats = [{label: "csv", value: [".csv"]},{label: "xlsx", value:[".xlsx",".xls"]},{label: "shapeFile", value: [".zip",".rar"]}];
  const fileSelectorRef = useRef(null);
  let defaultPayloadUploadRequestValue ={version: "1.0",titolo: null, file: null,fileFormat: "csv", is_update: false}
  const [payloadUploadRequest, setPayloadUploadRequest] = useState(defaultPayloadUploadRequestValue);
  const [selectedFileFormat, setSelectedFileFormat] = useState(fileFormats[0].value);
 
  const [selectedMessage, setSelectedMessage] = useState('');

  const playMex = () => {
    if(payloadUploadRequest.fileFormat === "csv"){
    toast({
      title: 'Chiamata in errore',
      
      status: 'warning',
      duration: 4000,
      isClosable: true
    });}
    
  }

  const handleChangePayloadRequest = (event, key) => {

    switch(key) {


      case "version":
        setPayloadUploadRequest(payloadUploadRequest => ({
          ...payloadUploadRequest,
          [key]: event
        }));
      break

      case "titolo":
        setPayloadUploadRequest(payloadUploadRequest => ({
          ...payloadUploadRequest,
          [key]: event.target.value 
        }));
      break
      case "fileFormat":
        switch(event.target.selectedOptions[0].label){
          case "csv":
            toast({
              title: 'Informazioni',
              description: " I file csv ammettono come delimitatori solo ',' e ';'",
              status: 'info',
              duration: 4000,
              isClosable: true
            })
            break
          case 'shapeFile':
            toast({
              title: 'Informazioni',
              description: "Gli shapfile devono essere caricati in formato '.zip'",
              status: 'info',
              duration: 4000,
              isClosable: true
            })
        
            
        
        }

        
        setSelectedFileFormat(event.target.value.split(","))
      
        setPayloadUploadRequest(payloadUploadRequest => ({
          ...payloadUploadRequest,
          [key]: event.target.selectedOptions[0].label
        }));
       
        setPayloadUploadRequest(payloadUploadRequest => ({
          ...payloadUploadRequest,
          file: null
        }));

        fileSelectorRef.current.value = ""
        

      break

      case "file":  

      let file = event.target.files[0]
    
      if (file) {
      const isValidExtension = selectedFileFormat.some(ext => file.name.endsWith(ext));
      if (!isValidExtension) {
        event.target.value = '';
        setPayloadUploadRequest(payloadUploadRequest => ({
          ...payloadUploadRequest,
          file: null
        }));
        return
      } 
      }

      setPayloadUploadRequest(payloadUploadRequest => ({
        ...payloadUploadRequest,
        [key]:  file
      }));

      break


      case "is_update":
        const { checked } = event.target; 
     

      setPayloadUploadRequest(payloadUploadRequest => ({
        ...payloadUploadRequest,
        [key]: checked 
      }));

      break

      default:
      break
    }


    
    

  };
  const uploadDataset = async () => {

   

    if(payloadUploadRequest.titolo != null && payloadUploadRequest.titolo.length >0 &&
      payloadUploadRequest.file != null &&
      payloadUploadRequest.fileFormat != null 
    ) {
      try {
       
   
      const formData = new FormData();
      formData.append("username",sessionStorage.getItem("username"))

      Object.keys(payloadUploadRequest).forEach(payloadUploadRequestKey => {
        formData.append(payloadUploadRequestKey, payloadUploadRequest[payloadUploadRequestKey]);
      })
  
        const options = {
         
          headers: {
           
            "Authorization": sessionStorage.getItem("token")
          },
          
        }
        const urlFetch = `${react_api}/privateServices/uploadDataADP`;
        const response = await axiosInstance.post(urlFetch,formData, options);
       
        const result = response.data;
       
        switch(result.status){

          case HTTP_STATUS_CODES.OK:
            
          toast({
              title: result.message,
              description: "",
              status: 'info',
              duration: 4000,
              isClosable: true
            });
  
            onUploadModalClose()
  
            setPayloadUploadRequest(defaultPayloadUploadRequestValue)
          break

          case HTTP_STATUS_CODES.BAD_REQUEST :


              toast({
                title: result.message,
                description: "",
                status: 'warning',
                duration: 4000,
                isClosable: true
              });
            
            
          

           break 


          default:
            throw new Error('Errore nel recupero dei dati');
        }

      
      } catch (error) {
        toast({
          title: 'Chiamata in errore',
          description: String(error),
          status: 'warning',
          duration: 4000,
          isClosable: true
        });
      }
    }
    
    
  
  
  
  }
  const axiosInstance = createAxiosInstance(async (axiosInstance)=> {

    logout(clearSessionData,navigate,toast,axiosInstance)
  });


  const { updateSessionData, clearSessionData} = useSession();
  const navigate = useNavigate();
  

  function deleteAllCookies() {
    // Ottiene tutti i cookie
    const cookies = document.cookie.split(";");
   
    // Scorre ogni cookie
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      // Trova il nome del cookie
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      // Imposta ogni cookie con una data di scadenza passata
      document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    }
  }



  


  const logOutJupyterHubTwo = async () => {
    try {
      const urlFetch = `https://131.175.205.207:55548/hub/logout`;
  
      const response = await axiosInstance.get(urlFetch);
  
      if (response.status === 200) {
        toast({
          title: 'Logout Jupyter 2 effettuato con successo',
          description: String(response.data.message),
          position: "top",
          status: 'info',
          duration: 4000,
          isClosable: true
        });
      }
    } catch (error) {
      toast({
        title: 'Errore durante il logout Jupyter 2',
        description: String(error.message),
        position: "top",
        status: 'warning',
        duration: 4000,
        isClosable: true
      });
    }
  };
  


  const logOut = async  () => {
    let toastId = 1

    if (toast.isActive(toastId)) {
      return
    }
    try {
      const richiesta = {
        BearerToken : sessionStorage.getItem("token"),
       
      }
      const options =   {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(richiesta),
      }
     
      const urlFetch = `${react_api}/logoutADP`;
      const response = await fetch(urlFetch, options);
      const result = await response.json();
      if (result.status == HTTP_STATUS_CODES.OK ){

       
        if (!toast.isActive(toastId)) { 
       
        toast({
          id: toastId,
          title: 'Logout effettuato con successo',
          description: String(result.message),
          position: "top",
          status: 'info',
          duration:1700,
          isClosable: true, onCloseComplete: ()=> {

            clearSessionData();
            navigate('/home');
            window.location.reload();
          }});

        }



          
      }
    } catch (error) {
      toast({
        title: 'Chiamata in errore',
        description: String(error),
        position: "top",
        status: 'warning',
        duration: 4000,
        isClosable: true});
    }
};

  const { isOpen: isLogoutModalOpen, onOpen: onLogoutModalOpen, onClose: onLogoutModalClose } = useDisclosure();
  const { isOpen: isUploadModalOpen, onOpen: onUploadModalOpen, onClose: onUploadModalClose } = useDisclosure();
  const { isOpen: isChangePasswordModalOpen, onOpen: onChangePasswordModalOpen, onClose: onChangePasswordModalClose } = useDisclosure();
  //const { isOpen, onOpen, onClose } = useDisclosure()
  
  const { sessionData } = useSession();
  const generateAvatar = (username) => {
    const array = username.split(" ");
    const name = array[0]
    const surname = array[1] || ''
    const initials = `${name.charAt(0)} + ${surname.charAt(0)}`;
   
    return (
      <Avatar name={initials} size="md" bg={"#006778"} color={"yellowgreen"} paddingBottom={"2%"} paddingTop={"2%"} ><AvatarBadge bgColor={"green"} borderWidth={1}  boxSize={"0.8em"}/></Avatar>
    );
  };

  /*const playMex = () => {
    if(payloadUploadRequest.fileFormat === 'csv') {
      toast({
       title: 'Informazione',
       description: "Il fie csv può avere come delimitatore solo ',' o ';'",
       position: "top",
       status: 'info',
       duration: 2000,
       isClosable: true})
      } 
       else if(payloadUploadRequest.fileFormat === 'shapeFile')
         {
       toast({
         title: 'Informazione',
         description: "Lo shapeFile dev'essere caricato in formato '.zip'",
         position: "top",
         status: 'info',
         duration: 2000,
         isClosable: true})
        }
         else 
         {
          toast({
            title: 'Informazione',
            description: "prova",
            position: "top",
            status: 'info',
            duration: 2000,
            isClosable: true})
      }}*/
  

  return(
          <Menu  minH="100%" minW="100%" > 
          {/*<MenuItem bgColor={"rgba(11,78,90,255)"} paddingRight={0} paddingLeft={0} paddingTop={0} paddingBottom={0}>
              <Image src={LogoGrinsProva} alt='Logo Grins Foundation' height={"100%"} width={"100%"} />
              </MenuItem>*/}
            <Link to="/home">
              <MenuItem _focus={{bg:"#006778"}} paddingRight={0} paddingLeft={0} paddingTop={0} mb={2} paddingBottom={0}>
                <MenuButton color={"yellowgreen"} w="100%" h="100%" paddingRight={"2%"} paddingLeft={"2%"} paddingTop={"2%"} paddingBottom={"2%"}
                  transition='all 0.2s' borderRadius='md' _hover={{boxShadow:"dark-lg"}} _expanded={{ bg: 'blue.400' }} >
                  <Icon as={MdOutlineHome} w={"30%"} h={"30%"}  />
                  <Text fontSize={"sm"}> Home </Text></MenuButton>  
              </MenuItem>
            </Link>
              <MenuItem _focus={{bg:"#006778"}} paddingRight={0} paddingLeft={0} paddingTop={0} mb={2} paddingBottom={0}>  
                <Menu>
                  <MenuButton color={"yellowgreen"} w="100%" h="100%" paddingRight={"2%"} paddingLeft={"2%"} paddingTop={"2%"} paddingBottom={"2%"}
                    transition='all 0.2s' borderRadius='md' _hover={{ boxShadow:"dark-lg" }}_expanded={{ bg: 'blue.400' }}
                     _focus={{ boxShadow: 'outline' }}>
                     <Icon as={MdOutlineTableChart} w={"30%"} h={"30%"}  />
                     <Text fontSize={"sm"}> Catalog </Text>    
                  </MenuButton> 
                    <MenuList  border="solid" borderColor={"rgba(11,78,90,255)"} borderWidth={"1"} marginLeft={"40%"} >
                      <Link to={"/dataset"}> 
                        <MenuItem _hover={{bg:"gray.300"}}> 
                          <Icon as={MdOutlineDataset} />Datasets  
                        </MenuItem>
                      </Link>
                      <Link to={"/stagingarea"}> 
                        <MenuItem _hover={{bg:"gray.300"}}>
                          <Icon as={MdStopCircle} />Staging Area
                        </MenuItem>
                      </Link>
                      <MenuDivider />

                      <Link to={"/risultati"}> 
                        <MenuItem _hover={{bg:"gray.300"}}>
                          <Icon as={MdOutlineReceipt} />My Results
                        </MenuItem>
                      </Link>
                      <Link to={"/processing"}> 
                        <MenuItem _hover={{bg:"gray.300"}}>
                          <HiMiniAdjustmentsHorizontal />Processing
                        </MenuItem>
                      </Link>
                      <MenuDivider />

                      <Link to={"/dashboard"}>
                        <MenuItem _hover={{bg:"gray.300"}}>
                          <Icon as={MdOutlineDashboard} />Dashboard 
                        </MenuItem>
                      </Link>
                      
                    </MenuList>
                </Menu>
              </MenuItem>
              
            <Link to="/sviluppo">
           
            </Link>
              <MenuItem _focus={{bg:"#006778"}} paddingRight={0} paddingLeft={0} paddingTop={0} mb={2} paddingBottom={0}>
                <Menu>
                  <MenuButton color={"yellowgreen"} w="100%"h="100%" transition='all 0.2s' paddingRight={"2%"} paddingLeft={"2%"} paddingTop={"2%"} paddingBottom={"2%"}
                          borderRadius='md'_hover={{ boxShadow:"dark-lg" }}_expanded={{ bg: 'blue.400' }}
                          _focus={{ boxShadow: 'outline'}}><Icon as={CgSearchLoading}  w={"30%"} h={"30%"}  /> 
                      <Text fontSize={"sm"}> Query Tools </Text>    
                  </MenuButton>    
                  <MenuList border="solid" borderColor={"rgba(11,78,90,255)"} borderWidth={"1"} marginLeft={"40%"}>
                    <Link to="/query" ><MenuItem _hover={{bg:"gray.300"}}><Icon as={SearchIcon} /><Text> Query</Text>  </MenuItem></Link>  
                   
                    </MenuList>
                </Menu>
              </MenuItem>
              <MenuItem _focus={{bg:"#006778"}} paddingRight={0} paddingLeft={0} paddingTop={0} mb={2} paddingBottom={0}>
                <Menu>
                <MenuButton color={"yellowgreen"} w="100%"h="100%" transition='all 0.2s' paddingRight={"2%"} paddingLeft={"2%"} paddingTop={"2%"} paddingBottom={"2%"}
                          borderRadius='md'_hover={{ boxShadow:"dark-lg" }}_expanded={{ bg: 'blue.400' }}
                          _focus={{ boxShadow: 'outline'}}><Icon as={GiGearHammer}  w={"30%"} h={"30%"}  /> 
                      <Text fontSize={"sm"}> Development Tools </Text>    
                  </MenuButton>  
                  <MenuList border="solid" borderColor={"rgba(11,78,90,255)"} borderWidth={"1"} marginLeft={"40%"}>
                    <Link to="/JupyterHub"><MenuItem _hover={{bg:"gray.300"}}><SiJupyter /> Jupyter Hub</MenuItem></Link>  
                    <Link to="/Zeppelin"><MenuItem _hover={{bg:"gray.300"}}><GiZeppelin /> Zeppelin</MenuItem></Link>  
                    <Link to="/Superset"><MenuItem _hover={{bg:"gray.300"}}><PiSupersetOf />Superset</MenuItem></Link>  

                    <MenuDivider />

                        <MenuItem _hover={{bg:"gray.300"}} onClick={()=> {onUploadModalOpen();  setPayloadUploadRequest(defaultPayloadUploadRequestValue)}}>
                          <Icon as={MdAddBox} />Upload Dataset
                          <Modal  onClose={()=> {onUploadModalClose();  setPayloadUploadRequest(defaultPayloadUploadRequestValue)}}  isOpen={isUploadModalOpen} isCentered>
                              <ModalOverlay />
                              <ModalContent>
                                <ModalHeader color="white" display="flex" justifyContent="space-between" bgColor={"#0888BE"}><Box>Upload Dataset</Box> <Box><CloseButton onClick={()=> {onUploadModalClose();  setPayloadUploadRequest(defaultPayloadUploadRequestValue)}} /></Box></ModalHeader>
                                <ModalBody>
                                <Stack spacing='4'>
                    <Box>


                    <FormControl mb={4} isRequired>
                    <FormLabel>Versione del dataset</FormLabel>
                     <NumberInput
                      min={1.0}
                      step={0.1}
                      precision={1}
                      defaultValue={1.0}
                      
                      value={payloadUploadRequest.version} // Collega il valore allo stato 
                      onChange={(valueString) => handleChangePayloadRequest(valueString, 'version')}
    >
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  </FormControl>

<FormControl>
                    <Box>

                    <FormLabel fontSize='lg' color="rgba(11,78,90,255)" fontWeight='semibold'>Titolo:</FormLabel>
                    <Input placeholder='Titolo'  onChange={(e) => handleChangePayloadRequest(e, "titolo")} />
                    </Box>
                    </FormControl>
                    <FormControl>
                    <FormLabel fontSize='lg' color="rgba(11,78,90,255)" fontWeight='semibold'>Select file format:</FormLabel>
                    <Select onChange={(e) => handleChangePayloadRequest(e, "fileFormat")} >
                     

                      {fileFormats.map(option => (
                        <option key={option.value} value={option.value} >
                        {option.label}
                        </option>
                      ))}
                     
                    </Select>
                   
                    </FormControl>
                      
                    

                    </Box>
                    <Text>{selectedMessage}</Text>

                    <FormControl display='flex' alignItems='center'>
                  <FormLabel htmlFor='email-alerts' mb='0'>
    Il File è un aggiornamento?
  </FormLabel>
  <Switch id='email-alerts' onChange={(e) => handleChangePayloadRequest(e, "is_update")}  />
</FormControl>
                    <Box>


                    <FormControl isRequired>
                    <FormLabel fontSize='lg' color="rgba(11,78,90,255)" fontWeight='semibold' >Select file:</FormLabel>
                    <Input  ref={fileSelectorRef} placeholder='Select file' name='file' type='file' bg='white' accept={selectedFileFormat}   onChange={(e) => handleChangePayloadRequest(e, "file")}   />
                    </FormControl> 
                    </Box>
       
                    </Stack>
                                </ModalBody>
                                <ModalFooter>
                                <Button colorScheme='green' mr={3}  onClick={ uploadDataset}>
                                    Submit 

                                  </Button>
                                </ModalFooter>
                              </ModalContent>
                            </Modal> 
                        </MenuItem>
                      
                  </MenuList>
                </Menu>
              </MenuItem>

              
              <Box mt="auto"alignSelf={"bottom"} h="20%">
              <MenuItem _focus={{ bg: "#006778" }} padding={0}> 
                <Menu>
                  <MenuButton color={"yellowgreen"} w="100%"h="100%" paddingRight={"2%"} paddingLeft={"2%"} paddingTop={"2%"} paddingBottom={"2%"}            
                          transition='all 0.2s' borderRadius='md' _hover={{ boxShadow:"dark-lg" }} _expanded={{ bg: 'blue.400' }} >
                        {sessionStorage.displayName === "anonymous" || !sessionStorage.displayName ? (<Icon as={MdOutlinePerson} w={"30%"} h={"30%"}  />) : (generateAvatar(sessionStorage.displayName))}  
                      <Text fontSize={"sm"}> Profile </Text>  
                  </MenuButton>
                    {/*

                    <MenuList border="solid" borderColor={"rgba(11,78,90,255)"} borderWidth={"1"} marginLeft={"40%"}>
                    
                        <MenuItem _hover={{bg:"gray.300"}} onClick={onChangePasswordModalOpen}> 
                          <Icon as={MdLockReset} /> Change Password
                          <Modal  onClose={onChangePasswordModalClose} isOpen={isChangePasswordModalOpen} isCentered>
                              <ModalOverlay />
                              <ModalContent>
                              <ModalHeader display="flex" justifyContent="space-between"><Box>Change Passowrd</Box> <Box><CloseButton onClick={onChangePasswordModalClose} /></Box></ModalHeader>
                                <ModalBody>
                                <Stack  spacing='4'>
                            <Box>
                              <FormControl isRequired>
                              <FormLabel fontSize='lg' color="rgba(11,78,90,255)" fontWeight='semibold'>Old password</FormLabel>
                              <Input variant='filled' placeholder='Old Password' name='VecchiaPassword ' type='password' bg='white'/>
                              </FormControl>
                              </Box>
                              <Box>
                              <FormControl isRequired>
                              <FormLabel fontSize='lg' color="rgba(11,78,90,255)" fontWeight='semibold'>New password</FormLabel>
                              <Input variant='filled' placeholder='New Password' name='NuovaPassword1' type='password' bg='white'/>
                              </FormControl>
                              </Box>
                              <Box>
                              <FormControl isRequired>
                              <FormLabel fontSize='lg' color="rgba(11,78,90,255)" fontWeight='semibold' >Enter again new password:</FormLabel>
                              <Input variant='filled' placeholder='New Password' name='NuovaPassword2' type='password' bg='white' />
                              </FormControl>
                              </Box></Stack>
                                </ModalBody>
                                <ModalFooter>
                                  <Button colorScheme='green' mr={3} onClick={logOut}>
                                    Change
                                  </Button>
                                  
                                </ModalFooter>
                              </ModalContent>
                            </Modal> 
                        </MenuItem>
                        
                    </MenuList> */}
                   
                </Menu>
            
              </MenuItem>
          
              <MenuItem padding={0} bgColor="#BE3C08" w="100%" h="50%">
                <MenuButton  color={"white"}  w="100%" h="100%" paddingRight={"2%"} paddingLeft={"2%"} paddingTop={"2%"} paddingBottom={"2%"}
                        transition='all 0.2s' borderRadius='md'   _hover={{ boxShadow:"dark-lg" }}                  
                         onClick={onLogoutModalOpen}><Icon as={IoMdPower}  w={"30%"} h={"30%"}  />
                  
                </MenuButton>
                <Modal  onClose={onLogoutModalClose} isOpen={isLogoutModalOpen} isCentered>
                              <ModalOverlay />
                              <ModalContent>
                                <ModalHeader> Confermi di voler abbandonare la sessione?</ModalHeader>
                                <ModalBody></ModalBody>
                                <ModalFooter>
                                  <Button colorScheme='green' mr={3} onClick={logOut}>
                                    Si
                                  </Button>
                                   <Button colorScheme='red' onClick={onLogoutModalClose  }>
                                    No
                                   </Button>
                                </ModalFooter>
                              </ModalContent>
                            </Modal> 

                            
              </MenuItem>
              </Box>
              
          </Menu>
          
        
        )
        
      }
      
        
   

     

   



  export default Navbar;



    