import {  useEffect, useState } from "react";
import { Text, TableContainer, Tr, Td, Th, Table, Thead, Tbody, Icon, Heading, Box, useToast, Link, Button, Modal, ModalOverlay, ModalContent, ModalHeader, CloseButton, ModalBody, ModalFooter, useDisclosure, FormControl, FormLabel, Input, RadioGroup, Radio, Stack, Checkbox, Textarea} from "@chakra-ui/react";
import { HTTP_STATUS_CODES } from "../GrinsCostant";
import { SearchIcon} from '@chakra-ui/icons';
import { react_api } from "../config";

import createAxiosInstance from "../interceptors/useAxiosInterceptors"
import { useSession } from '../SessionContext';
import {  useNavigate} from "react-router-dom";
import logout from "../services/authService";
import { MdSearch, MdUpload } from "react-icons/md";
import DashboardView from "./DashboardView";


export default function Dashborad (){
  const categories = [
    'Agricoltura, pesca, silvicoltura e prodotti alimentari',
    'Economia e finanza',
    'Istruzione, cultura e sport',
    'Energia',
    'Ambiente',
    'Governo e settore pubblico',
    'Salute',
    'Tematiche internazionali',
    'Giustizia, sistema giuridico e sicurezza pubblica',
    'Regioni e città',
    'Popolazione e società',
    'Scienza e tecnologia',
    'Trasporti'
  ];
  const [value, setValue] = useState("");

  const [catalog, setCatalog] = useState([]);
  const toast = useToast();
  const [message, setMessage] = useState("");
  const { updateSessionData, clearSessionData} = useSession();

  const navigate = useNavigate();

  const { isOpen: isUploadModalOpen, onOpen: onUploadModalOpen, onClose: onUploadModalClose } = useDisclosure();
  const [payloadUploadDashboard, setPayloadUploadDashboard] = useState({});

  const axiosInstance = createAxiosInstance(async (axiosInstance)=> {

    logout(clearSessionData,navigate,toast,axiosInstance)
  });
  /*
  Carica le dashboards in una tabella ogni volta che viene caricata la pagina (useEffect) interrogando l'endpoint con 
  l'invio di un json contente il token di identificaione, l'username dellutente e una costante caseType per indicare al backend 
  di quale chiamata si tratta e quali paremetri deve inviarci. Per consultare i case type consultare il file CaseTypeConstant.js  
  */
  useEffect(() => {
    const fetchCatalog = async () => {
    try {
      
        const richiesta = {
          BearerToken : sessionStorage.getItem("token"),
          username: sessionStorage.getItem("username"),
                  }
        const options =   {
        
          headers: {
            'Content-Type': 'application/json',
          },
          
        }
       
        
        const urlFetch = `${react_api}/privateDashboard/getDashboardADP`;
      
        const response = await axiosInstance.post(urlFetch,richiesta, options); 
       
        const result =  response.data;
      
        if( result.status === HTTP_STATUS_CODES.OK){
          setCatalog(result.catalog) 
           }
       
        else{
          toast({
          title: 'Tabella dei risultati vuota',
          description: String(result.message),
          position: "top",
          status: 'info',
          duration: 4000,
          isClosable: true});
          setMessage(result.message);}
    } catch (error) {
      toast({
          title: 'Chiamata in errore',
          description: String(error),
          position: "top",
          status: 'warning',
          duration: 4000,
          isClosable: true});
    }
      };
    fetchCatalog();}
, []);
  
const handleChangePayloadUploadDashboard = (e, field) => {
  const value = e.target ? e.target.value : e;
 

  setPayloadUploadDashboard((prev) => ({
    ...prev,
    [field]: value,
  }));
};

const [searchCheckedCategories, setSearchCheckedCategories] = useState(
  [])



  const handleCategoryChange = (category) => {
    setSearchCheckedCategories((prevCategories) => {
      // Controlla se la categoria è già selezionata
      const isCategoryChecked = prevCategories.includes(category);
  
      // Se è già selezionata, la rimuoviamo; altrimenti, la aggiungiamo
      const updatedCategories = isCategoryChecked
        ? prevCategories.filter((c) => c !== category)  // Rimuovi la categoria
        : [...prevCategories, category];  // Aggiungi la categoria
  
      // Aggiorna il payload con le categorie modificate
      setPayloadUploadDashboard((prev) => ({
        ...prev,
        categories: updatedCategories,  // Usa le categorie aggiornate
      }));
  
      return updatedCategories;  // Ritorna l'array aggiornato
    });
  };




      const submitDashboard = async () => {
        if(payloadUploadDashboard.dashboardName != null && payloadUploadDashboard.dashboardName.length >0 &&
          payloadUploadDashboard.linkDashboard != null && payloadUploadDashboard.linkDashboard.length >0 &&
          
          payloadUploadDashboard.visibility != null && payloadUploadDashboard.categories.length > 0 &&
          payloadUploadDashboard.description != null && payloadUploadDashboard.description.length >0
        ) {
        try{
          if (!payloadUploadDashboard.linkDashboard.includes('superset')) {
            throw new Error("Il link deve provenire da Superset.");
          }

         
          let updatedCategories = [];
          payloadUploadDashboard.categories.forEach(cat => {
           
            let index = payloadUploadDashboard.categories.indexOf(cat) 

              if (index !== -1)
              updatedCategories.push(index+1);
            
          })

          // Aggiorno l'array delle categorie con gli indici
          payloadUploadDashboard.categories = updatedCategories;

  
        const formData = new FormData();
        formData.append("username",sessionStorage.getItem("username"))
        Object.keys(payloadUploadDashboard).forEach(payloadUploadRequestKey => {
          formData.append(payloadUploadRequestKey, payloadUploadDashboard[payloadUploadRequestKey]);
        })
    
        

        const options =   {
        
          headers: {
            
            'Authorization': sessionStorage.getItem('token')
          },
          
        }

        const urlFetch = `${react_api}/privateServices/publishDashboardADP`;
        const response = await axiosInstance.post(urlFetch,formData, options);         

        
        
        if( response.status.toString() === HTTP_STATUS_CODES.OK){
          toast({
            title: 'Dashboard Pubblicata con successo',
            description: "",
            position: "top",
            status: 'warning',
            duration: 4000,
            isClosable: true});

            onUploadModalClose();
      }
           }

      
      catch(error) {
        toast({
          title: 'Error',
          description: String(error),
          position: "top",
          status: 'warning',
          duration: 4000,
          isClosable: true});
    }



  }
      


      }
      
      const [openDashboardModal, setOpenDashboardModal] = useState(false);
      const [linkDashboard, setLinkDashboard] = useState("")

      const onCloseDashboardViewModal = () => {
        setOpenDashboardModal(false)
      }

      /*const openDashboardViewModal = (link) => {
      setLinkDashboard(link)
      setOpenDashboardModal(true)
      }*/

      const openDashboardViewModal = (link) => {
        //const encodedLink = encodeURIComponent(link);
        console.info("link: ", link)
        navigate('/iframe', { state: { link } });
        }


      
    return(
        <>
        <Box alignItems={"center"} justifyContent={"space-between"} display={"flex"} color={"rgba(11,78,90,255)"} bgColor={"#f6f3ec"}><Heading>Dashboards</Heading> <Button  color={"rgba(11,78,90,255)"} bgColor={"#f6f3ec"} h={"100%"} onClick={onUploadModalOpen}><Icon as={MdUpload}/>Publish Dashboards</Button></Box>
        <Box bgColor={"white"}   overflowY="auto" direction="column" h="100%" w="100%" boxShadow="lg">
        <TableContainer px={"1%"} paddingTop={"1%"} >
        
        <Table variant='striped' >
        
      <Thead bgColor={"#0888BE"}>
        <Tr>
          <Th   whiteSpace={"normal"} wordBreak={"break-word"} w={"15%"} color={"white"}>Nome della dashboard</Th>
          <Th   whiteSpace={"normal"} wordBreak={"break-word"} w={"15%"} color={"white"}>Visibilità</Th>
          <Th  whiteSpace={"normal"} wordBreak={"break-word"} w={"10%"} color={"white"}>Categorie</Th>
          <Th  whiteSpace={"normal"} wordBreak={"break-word"} w={"20%"} color={"white"}>Proprietario</Th>
          <Th  whiteSpace={"normal"} wordBreak={"break-word"} w={"40%"} color={"white"}>Descrizione</Th>
          <Th color={"white"}>Azioni</Th>
        </Tr>
      </Thead>
     
      <Tbody  maxHeight="100%" overflowY="auto" borderWidth={1} boxShadow="lg">
      {catalog.length > 0 ? 
      
    (catalog.map(item=> (
   
    <Tr>
       {/*nome dataset*/} <Td  whiteSpace={"normal"} wordBreak={"break-word"}> {item.name}</Td>
       {/*visibilità*/} <Td  whiteSpace={"normal"} wordBreak={"break-word"}>{item.visibility}</Td>
       {/*categorie*/} <Td  whiteSpace={"normal"} wordBreak={"break-word"}>{item.categories}</Td>
       {/*responsabile*/}<Td  whiteSpace={"normal"} wordBreak={"break-word"}>{item.owner}</Td>
       {/*descrizione*/} <Td  whiteSpace={"normal"} wordBreak={"break-word"}>{item.description}</Td>
       {/*azioni*/} <Td  whiteSpace={"normal"} wordBreak={"break-word"}><Icon as={SearchIcon} onClick={() => openDashboardViewModal(item.link)} _hover={{color: "blue.200"}} /></Td>
       </Tr>))): (<Tr><Text fontSize={"lg"} fontWeight={"bold"}>{message}</Text></Tr>)}

      </Tbody>
      
     
    </Table> 
  </TableContainer>
  </Box> 
  <Modal  onClose={onUploadModalClose} isOpen={isUploadModalOpen} isCentered>
                              <ModalOverlay />
                              <ModalContent maxW={"50%"} h="60%">
                                <ModalHeader color="white" display="flex" justifyContent="space-between" bgColor={"#0888BE"}><Box>Pubblica Dashboard</Box> <Box><CloseButton onClick={onUploadModalClose} /></Box></ModalHeader>
                                <ModalBody overflow="auto">
                                
                    <Table w={"100%"} h="100%" >
                    <FormControl>
                      <Tr>
                    <Box>
                    <FormLabel fontSize='lg' color="rgba(11,78,90,255)" fontWeight='semibold'>Nome Dashboard:</FormLabel>
                    <Input placeholder='name'  onChange={(e) => handleChangePayloadUploadDashboard(e.target.value, "dashboardName")} />
                    </Box></Tr>
                    <Tr>
                    <Box>
                    <FormLabel fontSize='lg' color="rgba(11,78,90,255)" fontWeight='semibold'>Link Dashboard:</FormLabel>
                    <Input placeholder='link'  onChange={(e) => handleChangePayloadUploadDashboard(e.target.value, "linkDashboard")} />
                    </Box></Tr>
                    </FormControl><br />
                    <Tr>
                    <FormLabel fontSize='lg' color="rgba(11,78,90,255)" fontWeight='semibold'>Tipologia:</FormLabel>
                    <RadioGroup  onChange={(value) => {handleChangePayloadUploadDashboard(value, 'visibility')}}>
                    <Stack direction='row'>
                      <Radio value="private">Privata</Radio>
                      <Radio value="public">Pubblica</Radio>
                     
                    </Stack>
                  </RadioGroup> <br />
                  </Tr>
                  <Tr>
                  <FormLabel fontSize='lg' color="rgba(11,78,90,255)" fontWeight='semibold'>Categorie:</FormLabel>
                  <Stack>
                  {categories.map((category, index)=> (
            
                       
                    <Checkbox
            
                      onChange={() => handleCategoryChange(category)}
                    >
                      {category}
                    </Checkbox>
                  

                  ))}
                  </Stack>
                  </Tr>
                  <Tr>
                  <Box>
                    <FormLabel fontSize='lg' color="rgba(11,78,90,255)" fontWeight='semibold'>Descrizione:</FormLabel>
                    <Textarea placeholder='description'  onChange={(e) => handleChangePayloadUploadDashboard(e.target.value, "description")} />
                    </Box>
                    </Tr></Table>
                                </ModalBody>
                                <ModalFooter>
                                <Button colorScheme='green' mr={3}  onClick={submitDashboard}>
                                    Carica 

                                  </Button>
                                </ModalFooter>
                              </ModalContent>
                            </Modal>
                            <Modal  onClose={onCloseDashboardViewModal} isOpen={openDashboardModal} isCentered>
                              <ModalOverlay />
                              <ModalContent maxW={"70%"} h="70%">
                              <ModalHeader color="white" display="flex" justifyContent="space-between" bgColor={"#0888BE"}><Box>Vsita Dashboard</Box> <Box><CloseButton onClick={onCloseDashboardViewModal} /></Box></ModalHeader>
                                <ModalBody>
                                  <iframe src={linkDashboard} height={"100%"} width="100%"></iframe>
                                </ModalBody>
                                </ModalContent></Modal>


                            </>



    )


}






