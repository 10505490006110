import { react_api } from "../config";
import { useEffect, useState } from "react";
import { Box, useToast} from "@chakra-ui/react";

import logout from "../services/authService";
import createAxiosInstance from "../interceptors/useAxiosInterceptors"

import { useSession } from '../SessionContext';
import {  useNavigate} from "react-router-dom";

export default function JupyterHub() {
    const toast = useToast();
    const { updateSessionData, clearSessionData} = useSession();
    const navigate = useNavigate();
    const axiosInstance = createAxiosInstance(async (axiosInstance)=> {

        logout(clearSessionData,navigate,toast,axiosInstance)
      });
    
    /*const htmlContent = `<html lang="en">
    <head>
        <meta charset="UTF-8">

        <title>prova</title>


        <meta http-equiv="Content-Security-Policy" content="frame-ancestors 'self' http://localhost:3000/JupyterHub">
<!--        <meta http-equiv="X-Frame-Options" content="ALLOW-FROM http://localhost:3000/JupyterHub">-->

    </head>


    <body>



                <div class="col border" style="padding-right:0; padding-left:0;">
                    <iframe src="http://www.repubblica.it" id="iframe3" width="100%" height="100%" frameborder="1" name="iframe3"></iframe>
                </div>

            <div class="row border">
                <footer>
                    Copyright © 2023 - Amelia Data Platform v. Alfa
                </footer>




    </body>
</html> `;*/
   const [htmlContent, setHtmlContent] = useState(``);
    useEffect(() => {
    const fetchJupyter = async () => {
        try{

            let body = {
                BearerToken: sessionStorage.getItem("token"),
                username: sessionStorage.getItem("username"),
              };
            const options =   {
               
                headers: {
                  'Content-Type': 'application/json',
                }
              
               
              }
           
            const urlFetch = `${react_api}/linkJupyterHubADP`;
            const response = await axiosInstance.post(urlFetch,  body,options);
           
            
            const risposta = response.data;
              console.info("htmlContent: ", risposta);
              console.info("token: ", sessionStorage.getItem('token'))
            setHtmlContent(risposta);
            //const data = await response.json(); // Assumi che la risposta sia JSON con un campo URL
         
             // Sostituisci data.url con il campo corretto
            
        }
        catch (error) {
            toast({
               title: 'Chiamata in errore',
               description: String(error),
               status: 'warning',
               duration: 4000,
               isClosable: true});
         }


    } ;
    fetchJupyter();
    }
    , []);     

    return(<Box 
         dangerouslySetInnerHTML={{ __html: htmlContent }} h={"100%"}/> )
   





}