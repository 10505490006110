import React from 'react';
import { Text, Box, CloseButton } from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';




const DashboardView= ({ link }) => {
  // Verifica che il children sia un link e ottieni l'href
  const navigate = useNavigate();
  const location = useLocation();
  const url = location.state?.link;
  console.info("link: ", url)
  // Ritorna l'iframe con l'URL se esiste un link
  return (
    <Box h={"100%"} w="100%">
        <CloseButton onClick={() => navigate(-1) } bgColor={"blue.300"} _hover={{bgColor: "white"}}/>
      {url ? (
        <iframe
          src={url}
          title="Content in Iframe"
          width="100%"
          height="100%"
          style={{ border: "none" }}
        />
      ) : (
        <Text>No URL provided</Text>
      )}
    </Box>
  );
};

export default DashboardView;
