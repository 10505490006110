
import {React, useEffect, useState}   from 'react';
import { useSession } from './SessionContext';
import { Button, Box, FormControl,FormLabel, Input,InputGroup, InputRightElement, Text, Card, useToast, Icon, Divider, Image, GridItem, Grid, Link} from '@chakra-ui/react';
import { useNavigate, Link as ReactRouterLink } from 'react-router-dom';
import  { HTTP_STATUS_CODES } from "./GrinsCostant";
import  {react_api} from './config';
//import axios from 'axios';
import { MdKey, MdPerson,MdVisibility, MdVisibilityOff } from 'react-icons/md';
//import Header from './components/Header';
import BGImage from './components/images/BgImage.jpg';
import LogoGrins from './components/images/LogoGrins.png'
import FooterLogin from './components/FooterLogin';
import { color } from 'framer-motion';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import "./components/PuzzleCaptcha.css"
import PuzzleCaptcha from './components/PuzzleCaptcha';
const Reindirizzamento = () => {
  
  //const [data, setData] = useState(null);
  const { updateSessionData, clearSessionData} = useSession();
  const navigate = useNavigate();
  const toast = useToast();
  
  const toPublicArea = () =>{
    
    sessionStorage.setItem("username", "");
    navigate('/guestApp')
    window.location.reload()
  }


  const [formInputData, setFormInputData] = useState({});

  
  const [visibleCaptcha, setVisibleCaptcha] = useState(true);

  const [loginButtonVisible, setLoginButtonVisible] = useState(false);

  const handleChangeFormInputData = (ev, key) => { 
    setFormInputData(prevUser => ({
      ...prevUser,        
      [key]: ev.target.value    
    }));

    if(loginButtonVisible) {
    setVisibleCaptcha(false)
    setTimeout(()=> {setVisibleCaptcha(true)},600)
    setLoginButtonVisible(false)
    }
    
  }
  
 
 
  const captchaOnSuccess = () => {

 
    setLoginButtonVisible(true)
  }

  const captchaOnFail = () => {
    setLoginButtonVisible(false)
  }


  const captchaOnRefresh = () => {
    setLoginButtonVisible(false)
  }


    
    
      
    const [showPassword, setShowPassword] = useState(false);

    const handlePasswordVisibility = () => {
      setShowPassword(!showPassword);
    };

      const fetchDataPost = async () => {
        let toastId = 2

        
        if (toast.isActive(toastId)) { 
          return
        }
        try {
          
          const payload = {
            username: document.getElementById('username').value,
            operID: document.getElementById('operID').value,
          };
          
          
          const options =   {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        }
          
         
          const urlFetch = `${react_api}/authenticateADP`;
          
          const response = await fetch(urlFetch, options); 
         
       
          const result = await response.json();
         
          if (result.status == HTTP_STATUS_CODES.OK)
            {
            
            setLoginButtonVisible(false)
            updateSessionData({ displayName: result.displayName, token: result.BearerToken, username: result.username, messaggio: result.message});
          
            
         
            //setData(result);
            navigate('/home');


            if (!toast.isActive(toastId)) { 

           

            toast({
              id : toastId,
              title: 'Accesso effettuato',
              description: "Accesso effettuato come " + sessionStorage.username,
              status: 'success',
              duration: 40000,
              isClosable: true,
            });
            setTimeout(()=> {window.location.reload()  },1300)


          }

            
            
            
            } else {
            toast({
              title: 'Errore ' + result.status,
              description: result.message,
              status: 'error',
              duration: 5000,
              isClosable: true});

              setVisibleCaptcha(false)
              setFormInputData({username : "", password: ""})
              setTimeout(()=> {
                setVisibleCaptcha(true)
                setLoginButtonVisible(false)
              },500)
            return(<Text>{result.status} {result.message}</Text>)
            }
        } catch (error) {
          toast({
            title: 'Errore di accesso.',
            description: String(error),
            status: 'warning',
            duration: 4000,
            isClosable: true});
            setVisibleCaptcha(false)
            setFormInputData({username : "", password: ""})
            setTimeout(()=> {
              setVisibleCaptcha(true)
              setLoginButtonVisible(false)
            },500)
           
        }
      };

      useEffect(() => { 
        sessionStorage.setItem('username', "");
        


      }, []);
    
  
  
  return (
    <Box
    width="100vw"
    height="100vh"
    display="flex"
    flexDirection="column"
    justifyContent="space-between"
    overflow="hidden"
  >
    <Box
      position="absolute"
      top="0"
      left="0"
      width="100vw"
      height="100vh"
      bgImage={BGImage} // Aggiungi il percorso alla tua immagine
      backgroundSize="cover"
      backgroundPosition="center"
      filter="blur(5px)" // Imposta il livello di sfocatura
      zIndex="-1"
    />
  
    <Box display="flex" justifyContent="flex-start" p="4">
      <Image src={LogoGrins} overflow={"dark-lg"} />
    </Box>
  
    <Box
      flex="1"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
     
 <Card
  w={{ base: "90%", sm: "80%", md: "60%", lg: "40%", xl: "30%" }} // Responsive width for different breakpoints
  p={{ base: "3", md: "4" }} // Responsive padding
  shadow="lg"
  rounded="lg"
  overflow="hidden"
  boxShadow="dark-lg"
  border="1px solid"
  borderColor="rgba(11,78,90,255)"
  bgColor="#007487"
>
  <Grid templateRows="repeat(4, auto)" rowGap={{ base: 3, md: 4 }}> {/* Use 'auto' for dynamic height */}
    {/* Username Field */}
    <GridItem>
      <FormControl isRequired>
        <FormLabel fontSize={{ base: "md", md: "lg" }} color="white" fontWeight="semibold" mb={1}>
          <Icon as={MdPerson} /> Username
        </FormLabel>
        <Input
          placeholder="Username"
          name="username"
          type="text"
          id="username"
          bg="white"
          value={formInputData.username}
          onChange={(e)=> handleChangeFormInputData(e, "username")}
          _placeholder={{ color: 'gray.500' }}
        />
      </FormControl>
    </GridItem>

    {/* Password Field */}
    <GridItem>
    <FormControl isRequired>
      <FormLabel fontSize={{ base: "md", md: "lg" }} color="white" fontWeight="semibold" mb={1}>
        <Icon as={MdKey} /> Password
      </FormLabel>
      <InputGroup>
        <Input
          placeholder="Password"
          name="operID"
          type={showPassword ? "text" : "password"} // Cambia tipo dinamicamente
          bg="white"
          id="operID"
          value={formInputData.password}
          onChange={(e) => handleChangeFormInputData(e, "password")}
          _placeholder={{ color: 'gray.500' }}
        />
        <InputRightElement width="3rem">
          <Button h="1.75rem" size="sm" onClick={handlePasswordVisibility} bg="transparent">
            {showPassword ? <Icon as={MdVisibilityOff} boxSize={5}/> : <Icon as={MdVisibility}  boxSize={5}/>}
          </Button>
        </InputRightElement>
      </InputGroup>
    </FormControl>
    </GridItem>

    {/* Verify Component */}
    <GridItem>
      <FormControl isRequired>
        <Box mt={1}> {/* Reduced margin-top for a tighter fit */}
          <PuzzleCaptcha
            visible={visibleCaptcha}
            onSuccess={() => captchaOnSuccess()}
            onFail={() =>  captchaOnFail()}
            onRefresh={() => captchaOnRefresh()}
          />
        </Box>
      </FormControl>
    </GridItem>

    {/* Submit Button */}
    <GridItem display="flex" justifyContent="center">
     
      {loginButtonVisible &&
       formInputData.username !=null && formInputData.username.length  >0 && 
      formInputData.password !=null && formInputData.password.length  >0  ? (
  <Button
  w={{ base: "70%", md: "50%" }} // Responsive button width
  bgColor="yellowgreen"
  color="rgba(11,78,90,255)"
  _hover={{ bgColor: "rgba(11,78,90,255)", color: "green.300" }}
  onClick={fetchDataPost}
>
  Log In
</Button>
      ) :(

        <Text  fontSize={{ base: "sm", md: "md", lg: "lg" }}color={"white"}>Verifica il captcha per entrare</Text> 
      )}
    
    </GridItem>

    {/* Footer with Link */}
    <Box as="hr" borderColor="white" borderWidth="1px" />
    <GridItem display="flex" justifyContent="space-between" color="white" mt={2}>
      <Link onClick={toPublicArea} color="white">
      

        Public area <ExternalLinkIcon mx="1px" />
      </Link>
      <Text fontSize={{ base: "xs", md: "sm", lg: "md" }}>v.1.0.0</Text>{/* Responsive footer text size */}
    </GridItem>
  </Grid>
</Card>




    </Box>
  
    <Box width="100%">
      <FooterLogin />
    </Box>
  </Box>
  
    
  );
};

export default Reindirizzamento;