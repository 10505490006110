
import {Box, Heading, Table, Tbody, Tr, Td, Th,   Thead, Badge, useToast, Text} from "@chakra-ui/react";
import { useEffect, useState} from "react";
import { react_api } from "../config";
import { HTTP_STATUS_CODES } from "../GrinsCostant";
import logout from "../services/authService";
import createAxiosInstance from "../interceptors/useAxiosInterceptors"
import { useSession } from '../SessionContext';
import {  useNavigate} from "react-router-dom";

import convertUtcToLocal from "../services/utils";


export default function StagingArea(){
  const [message, setMessage] = useState("");
  const [catalog, setCatalog] = useState([]);
 

  const toast = useToast();
 
 
  const { updateSessionData, clearSessionData} = useSession();
 
  const navigate = useNavigate();
 

  const axiosInstance = createAxiosInstance(async (axiosInstance)=> {

    logout(clearSessionData,navigate,toast,axiosInstance)
  });

  useEffect(() => {
    const fetchCatalog = async () => {
    try {
      
        const richiesta = {
          
          username: sessionStorage.getItem("username")
        }
        const options =   {
          
          headers: {
            'Content-Type': 'application/json',
            'Authorization': sessionStorage.getItem("token")
          }
        }
        
        const urlFetch = `${react_api}/stagingAreaResult/getCatalogADP`;
        const response = await axiosInstance.post(urlFetch,richiesta, options);
        const result = response.data;
        if( result.status === HTTP_STATUS_CODES.OK){
          setCatalog(result.catalog) 
           }
         else{
          
         setMessage(result.message);}
    } catch (error) {
      toast({
          title: 'Chiamata in errore',
          description: String(error),
          position: "top",
          status: 'warning',
          duration: 4000,
          isClosable: true});
    }
      };
    fetchCatalog();}
, []);
    /*
  Carica i dataset di cui non è stato completato l'intero caricameno dei metadati in una tabella ogni volta che viene caricata la pagina (useEffect) interrogando l'endpoint con 
  l'invio di un json contente il token di identificaione, l'username dellutente e una costante caseType per indicare al backend 
  di quale chiamata si tratta e quali paremetri deve inviarci. Per consultare i case type consultare il file CaseTypeConstant.js  
  */
 

  
    return( 
            
      
         
      <Box h="100%" w="100%" >
        <Box justifyContent={"center"} flex={"row"} >
          <Heading  size='md'  color={"rgba(11,78,90,255)"} >Staging Area</Heading>
        </Box>
        <Box  overflowY="auto" direction="column" h="180px" boxShadow="lg" borderRadius={"10px"}>
          
              <Table  size="sm" variant='striped' bgcolor="white">
                <Thead fontFamily={"-moz-initial"} bg={"#0888BE"} position="sticky" top="0" >
                  <Tr>
                    <Th  color={"white"} >Ttitolo tabella</Th>
                    <Th color={"white"} > Nome tabella</Th>
                    <Th color={"white"} >Data di creazione</Th>
                    <Th color={"white"} >Stato</Th>

                    </Tr>
                </Thead>
                <Tbody>  
                {catalog.length > 0 ? (
                    (catalog.map((item, index)  => (
                  <Tr > 
                    <Td whiteSpace={"normal"} wordBreak={"break-word"}>{item.title}</Td>
                    <Td whiteSpace={"normal"} wordBreak={"break-word"}>{item.tableName}</Td>
                    <Td whiteSpace={"normal"} wordBreak={"break-word"}>{convertUtcToLocal(item.creationTime)} </Td>
                    <Td whiteSpace={"normal"} wordBreak={"break-word"}><Badge variant={"solid"} borderRadius={"10px"} colorScheme='orange'>!!!</Badge></Td>
                  </Tr>
                    )))):(<Tr alignContent={"center"}><Text fontSize={"lg"} color="rgba(11,78,90,255)">{message}</Text></Tr>)}
                          
                          
                        
                          
               </Tbody>
              </Table>
                
              

        </Box>
      </Box>
       
 )










}