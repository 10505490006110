import NavbarGuestArea from "./components/NavbarGuestArea";
import {Box, Text, } from  "@chakra-ui/react"
import  {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Header from './components/Header';
import { ChakraProvider} from '@chakra-ui/react';
import DatasetGuest from "./components/DatasetGuest";
import DashboradGuest from "./components/DashboardGuest";
import HomeGuest from "./components/HomeGuest";

const guestApp = () =>{

  
    return (
     
    
      
        <ChakraProvider>
          
        
  
          <Box
            height="100vh"
            width="100vw"
            display="flex"
            flexDirection="column"
            overflow="hidden"  // Evita lo scrolling
          >
            <Box width="100%">
              <Header />
            </Box>
            
            <Box
              flex="1"  // Occupa lo spazio rimanente
              display="flex"
              flexDirection="row"
              overflow="hidden"  // Evita lo scrolling all'interno
            >
              
                <Box
                  height="100%"
                  width="6%"
                  bg="rgba(11,78,90,255)"
                  paddingTop={5}
                  display="flex"
                  flexDirection="column"
                  boxShadow='inner'
                >
                  <NavbarGuestArea />
                </Box>
                <Box
                  width="94%"
                  display="flex"
                  flexDirection="column"
                  overflow="hidden"  // Evita lo scrolling all'interno
                  id='workarea'
                >
                  {/* Area di lavoro */}
                  <Routes>
                
                  <Route path="/datasetGuest" element={<DatasetGuest />} />
                  <Route path="/dashboardGuest" element={<DashboradGuest />} />
                  <Route path="/home" element={<HomeGuest />} />
                  <Route path="/" element={<HomeGuest />} />
                  <Route path="/guestApp" element={<HomeGuest />} />
                  

                </Routes>
                
                </Box>
              
            </Box>
  
            <Box width="100%">
            
            </Box>
          </Box>
       
        </ChakraProvider>
 
    
  );
  }
  
  export default guestApp;