
import {Box, Heading, Table, Tbody, Tr, Td, Th, useToast,Text,  Thead} from "@chakra-ui/react";
import { useEffect, useState} from "react";
import { react_api } from "../config";
import { HTTP_STATUS_CODES } from "../GrinsCostant";
//import ProvaService from "../API/prova"
import logout from "../services/authService";
import createAxiosInstance from "../interceptors/useAxiosInterceptors"
import { useSession } from '../SessionContext';
import {  useNavigate} from "react-router-dom";
import convertUtcToLocal from "../services/utils";

export default function Elaborazioni(){
  const toast = useToast();

  const { updateSessionData, clearSessionData} = useSession();
  const navigate = useNavigate();
 
    
/*
  Carica le elaborazioni in una tabella ogni volta che viene caricata la pagina (useEffect) interrogando l'endpoint con 
  l'invio di un json contente il token di identificaione, l'username dellutente e una costante caseType per indicare al backend 
  di quale chiamata si tratta e quali paremetri deve inviarci. Per consultare i case type consultare il file CaseTypeConstant.js  
  */
  const [message, setMessage] = useState("");
  const [catalog, setCatalog] = useState([]);

  const axiosInstance = createAxiosInstance(async (axiosInstance)=> {

    logout(clearSessionData,navigate,toast,axiosInstance)
  });

  useEffect(() => {
    
  
    const fetchCatalog = async () => {
      try{
       
        const richiesta = {
          BearerToken : sessionStorage.getItem("token"),
          username: sessionStorage.getItem("username"),
          //caseType: CASE_TYPE.CATALOG_ALL,
        }
        const options =   {
         
          headers: {
            'Content-Type': 'application/json',
          }
        }
       
        
        const urlFetch = `${react_api}/privateElaboration/getCatalogADP`;
        
        const response = await axiosInstance.post(urlFetch, richiesta,options)
       
        const result = response.data;
        if (result.status === HTTP_STATUS_CODES.OK){
          setCatalog(result.catalog) 
        }
        else{
          
          setMessage(result.message);}
     } catch (error) {
       toast({
           title: 'Chiamata in errore',
           description: String(error),
           position: "top",
           status: 'warning',
           duration: 4000,
           isClosable: true});
     }
       };
     
    
    
       fetchCatalog();}
, []);
  
  
  

  
    return( 
      <Box h="100%" w="100%" borderRadius={"10px"} >
        <Heading  size='md' color={"rgba(11,78,90,255)"} >Le Mie Elaborazioni</Heading>
          <Box  overflowY="auto" direction="column" h="160px" boxShadow="lg" borderRadius={"10px"}>
           
              <Table size="sm" variant='striped' bgcolor="white" >
                <Thead fontFamily={"-moz-initial"} bg={"#0888BE"} position="sticky" top="0" >
                  <Tr>
                    <Th  color={"white"} >Id</Th>
                    <Th color={"white"} >Nome tabella</Th>
                    <Th  color={"white"} >Titolo</Th>
                    <Th color={"white"} >Data di creazione</Th>
                    </Tr>
                  </Thead>
                  <Tbody>  
                  {catalog.length > 0 ? (
                    (catalog.map((item, index)  => (
                      <Tr > 
                      <Td whiteSpace={"normal"} wordBreak={"break-word"}>{item.tableId}</Td>
                      <Td whiteSpace={"normal"} wordBreak={"break-word"}>{item.tableName}</Td>
                      <Td whiteSpace={"normal"} wordBreak={"break-word"}>{item.title}</Td>
                      <Td whiteSpace={"normal"} wordBreak={"break-word"}>{convertUtcToLocal(item.createTime)}</Td>
                      </Tr>
                      )))):(<Tr alignContent={"center"}><Text fontSize={"lg"} color="rgba(11,78,90,255)">{message}</Text></Tr>)}
                  </Tbody>
              </Table>
                 

          </Box>
      </Box>
 )










}