// axiosInstance.js
import axios from 'axios';
import { react_api } from "../config";

const createAxiosInstance = (logoutFunction) => {
  const axiosInstance = axios.create({
    baseURL: react_api,
  });

  axiosInstance.interceptors.response.use(
    (response) => {
      
    
      console.log(response)
         // Check the response status
    if (response.data.status === '601') {
      
      // Handle 401 Unauthorized response
      // Perform logout or redirect to login page
       logoutFunction(axiosInstance);
     }
      
      return response; // Return the response if it's successful
    },
    (error) => {
     
      return Promise.reject(error); // Reject the promise for further handling
    }
  );

  return axiosInstance;
};

export default createAxiosInstance;