import { useEffect, useState } from "react";
import { ModalFooter, Button, TableContainer, Tr, Td, Th, Table, Thead, Tbody,  Menu, MenuButton, MenuList, MenuItem, Icon, Box, Heading, useToast, Text, createIcon, HStack, Modal, ModalOverlay, ModalContent, ModalHeader, CloseButton, ModalBody} from "@chakra-ui/react";
import {ChevronDownIcon, DeleteIcon, SearchIcon} from '@chakra-ui/icons';
import { react_api } from "../config";
import { HTTP_STATUS_CODES } from "../GrinsCostant";
import { MdPublic, MdTransform } from "react-icons/md";
import createAxiosInstance from "../interceptors/useAxiosInterceptors"
import { useSession } from '../SessionContext';
import {  useNavigate} from "react-router-dom";
import logout from "../services/authService";
import convertUtcToLocal from "../services/utils";
//import { MdEditDocument, MdNote, MdSearch, MdEdit, } from "react-icons/md";

/*
  Carica i risultati in una tabella ogni volta che viene caricata la pagina (useEffect) interrogando l'endpoint con 
  l'invio di un json contente il token di identificaione, l'username dellutente e una costante caseType per indicare al backend 
  di quale chiamata si tratta e quali paremetri deve inviarci. Per consultare i case type consultare il file CaseTypeConstant.js  
  */
export default function Processing(){
  const [catalog, setCatalog] = useState([]);
  const toast = useToast();
  const [message, setMessage] = useState("");

  const { updateSessionData, clearSessionData} = useSession();
  const navigate = useNavigate();

  const axiosInstance = createAxiosInstance(async (axiosInstance)=> {

    logout(clearSessionData,navigate,toast,axiosInstance)
  });

  const [keys, setKeys] = useState([]);
  const [processingList, setProcessingList] = useState([]);
  const [processingTableName, setProcessingTableName] = useState("");
  const [isProcessingModalOpen, setProcessingModalOpen] = useState(false);
  const [isDeleteDatasetModalOpen, setDeleteProcessingModalOpen] = useState(false);
  const [isTransformDatasetModalOpen, setTransformProcessingModalOpen] = useState(false);

  const [deleteDatasetProcessingName, setDeleteProcessingTableName] = useState("");
  const [deleteTransformProcessingName, setTransformProcessingTableName] = useState("");




  useEffect(() => {
    const fetchCatalog = async () => {
    try {
        
        const richiesta = {
          BearerToken : sessionStorage.getItem("token"),
          username: sessionStorage.getItem("username"),
          //caseType: CASE_TYPE.CATALOG_ALL,
        }
        const options =   {
      
          headers: {
            'Content-Type': 'application/json',
          }
         
        }
     
        
        const urlFetch = `${react_api}/privateElaboration/getCatalogADP`;
       
        const response = await axiosInstance.post(urlFetch,richiesta, options); 
       
        const result =  response.data;
       
        if( result.status === HTTP_STATUS_CODES.OK){
          setCatalog(result.catalog) 
        }
         else{
          toast({
            title: 'Tabella dei risultati vuota',
            description: String(result.message),
            position: "top",
            status: 'info',
            duration: 4000,
            isClosable: true});
            setMessage(result.message);}
    } catch (error) {
      toast({
          title: 'Chiamata in errore',
          description: String(error),
          position: "top",
          status: 'warning',
          duration: 4000,
          isClosable: true});
    }
      };
    fetchCatalog();}
, []);

const closeProcessingModal = () => {
  setProcessingModalOpen(false);
};
const openProcessingModal = (response, tableName) => {
  
  setKeys(Object.keys(response.previewTable[0]))
  setProcessingList(response.previewTable);
  setProcessingTableName(tableName);
  setProcessingModalOpen(true);
};
      const visualizzaProcessing = async (tableName) => {
        try {
         
          const richiesta = {
            username : sessionStorage.getItem("username"),
            tableName : tableName
          }
          const options = {

            headers: {
              'Content-Type': 'application/json',
              'Authorization' : sessionStorage.getItem('token')
            }

          }
          
          const urlFetch = `${react_api}/getTableADP`;
          
          const response = await axiosInstance.post(urlFetch, richiesta, options); 
          
          const result =  response.data;
          
          if( result.status === HTTP_STATUS_CODES.OK){
            openProcessingModal(result, tableName)}
           
      } catch (error) {
        toast({
            title: "La visualizzazione dell'elaborazione è fallita",
            description: String(error),
            position: "top",
            status: 'warning',
            duration: 4000,
            isClosable: true});
      } 
      }


      const openTransformProcessingModal = (tableId) => {
      
        setTransformProcessingTableName(tableId);
        setTransformProcessingModalOpen(true);      }

      const closeTransformProcessingModal = () => {
        setTransformProcessingModalOpen(false);
    
      }

      const onTransformItem = (tableId) => {

   

        trasformaInRisultato(tableId).then(()=> {
          setCatalog((prevCatalogItems) => prevCatalogItems.filter((catalogItem) => catalogItem.tableId !== tableId));
          closeTransformProcessingModal()
          
         
        })  
      };
      
      const trasformaInRisultato = async (tableId) => {
        try {
         
          const urlFetch = `${react_api}/privateElaboration/moveToResultADP/${tableId}`;
          
          const response = await axiosInstance.get(urlFetch); 
          
          const result =  response.data;
          if( result.status === HTTP_STATUS_CODES.OK){
            toast({
              title: "La trasformazione in risultato è andata a buon fine",
              description: String(result.message),
              position: "top",
              status: 'info',
              duration: 4000,
              isClosable: true});
              
          }
           
      } catch (error) {
        toast({
            title: "La trasfomazione dell'elaborazione è fallita",
            description: String(error),
            position: "top",
            status: 'warning',
            duration: 4000,
            isClosable: true});
      } 
      }
      

      const openDeleteProcessingModal = (tableName) => {
      
        setDeleteProcessingTableName(tableName);
        setDeleteProcessingModalOpen(true);
      }
    
      const closeDeleteProcessingModal = () => {
        setDeleteProcessingModalOpen(false);
    
      }

      const onDeleteItem = (tableName) => {

   

        eliminaProcessing(tableName).then(()=> {
          setCatalog((prevCatalogItems) => prevCatalogItems.filter((catalogItem) => catalogItem.tableName !== tableName));
         
        })  
      };

      
    


      const eliminaProcessing = async (tableName) => {
        try {
          
          const richiesta = {
            username : sessionStorage.getItem("username"),
            tableName : tableName
          }
          const options = {

            headers: {
              'Content-Type': 'application/json',
              'Authorization' : sessionStorage.getItem('token')
            }

          }
          
          const urlFetch = `${react_api}/deleteTableADP`;
          
          const response = await axiosInstance.post(urlFetch, richiesta, options); 
          
          const result =  response.data;
          if( result.status === HTTP_STATUS_CODES.OK){
            toast({
              title: "La cancellazione dell'elaborazione è andata a buon fine",
              description: String(result.message),
              position: "top",
              status: 'info',
              duration: 4000,
              isClosable: true});
              
          }
           
      } catch (error) {
        toast({
            title: "La cancellazione dell'elaborazione è fallita",
            description: String(error),
            position: "top",
            status: 'warning',
            duration: 4000,
            isClosable: true});
      } 
      }
    
        return(
          <>
      <Box alignItems={"center"} justifyContent={"center"} display={"flex"} color={"rgba(11,78,90,255)"} bgColor={"#f6f3ec"}>
        <Heading>My Processing</Heading></Box><Box bgColor={"white"}  overflowY="auto" direction="column" h="100%" w="100%" boxShadow="lg">
          <TableContainer px={"1%"} paddingTop={"1%"} >
            <Table variant='striped'>
              
              <Thead bgColor={"#0888BE"}>
                <Tr>
                  <Th whiteSpace={"normal"} wordBreak={"break-word"} color={"white"}>Id</Th>
                  <Th whiteSpace={"normal"} wordBreak={"break-word"} color={"white"}>Dataset</Th>
                  <Th whiteSpace={"normal"} wordBreak={"break-word"} color={"white"}>Titolo</Th>
                  <Th whiteSpace={"normal"} wordBreak={"break-word"} color={"white"}>Data di creazione</Th>
                  <Th whiteSpace={"normal"} wordBreak={"break-word"} color={"white"}>Azioni</Th>
                </Tr>
              </Thead>
              
              <Tbody>
              {catalog.length > 0 ? 
      
              (catalog.map(item=> (
                <Tr>
                  <Td whiteSpace={"normal"} wordBreak={"break-word"}>{item.tableId}</Td>
                  <Td whiteSpace={"normal"} wordBreak={"break-word"}>{item.tableName}</Td>
                  <Td whiteSpace={"normal"} wordBreak={"break-word"}>{item.title}</Td>
                  <Td whiteSpace={"normal"} wordBreak={"break-word"}>{convertUtcToLocal(item.createTime)}</Td>
                  <Td whiteSpace={"normal"} wordBreak={"break-word"}><HStack align={"center"}  wrap="wrap"><Icon as={SearchIcon} onClick={() =>visualizzaProcessing(item.tableName)} _hover={{color: "#0888BE"}} /><Icon as={MdTransform} onClick={() =>openTransformProcessingModal(item.tableId)} _hover={{color: "#0888BE"}} /><Icon as={DeleteIcon} onClick={() =>openDeleteProcessingModal(item.tableName)} _hover={{color: "#0888BE"}} /> </HStack></Td>
                  
                </Tr> ))):(<Tr><Text fontSize={"lg"} fontWeight={"bold"}>{message}</Text></Tr>)} 
              </Tbody>   
            </Table>
        </TableContainer></Box>
        
        <Modal onClose={closeProcessingModal} isOpen={isProcessingModalOpen} isCentered  size={"xl"} >
      <ModalOverlay />
      <ModalContent  maxW="60%" h="60%" >
        <ModalHeader bgColor={"#0888BE"} color="white" display="flex" justifyContent="space-between"><Box>Vista dell'elaborazione: <Text fontSize={"lg"} fontWeight={"bold"} color="yellowgreen">{processingTableName}</Text></Box><Box><CloseButton onClick={closeProcessingModal} /> </Box></ModalHeader>
        <ModalBody overflow="auto" h={"100%"}>
          <Box  >
        <Table variant="striped" size={"sm"} h={"100%"} w={"100%"}>
        <Thead bgColor={"#0888BE"}>
          <Tr>
            {keys ? (
            keys.map((key) => (
              <Th color="white"  key={key}>{key}</Th> // Renderizza le chiavi come intestazioni di colonna
            ))):("")}
          </Tr>
        </Thead>
        <Tbody>
        {processingList.length > 0 ?
          (processingList.map((item, index) => (
            <Tr key={index}>
              {keys.map((key) => (
                <Td key={key}>{item[key]}</Td> // Renderizza il valore corrispondente alla chiave
              ))}
            </Tr>
          ))):("")}
        </Tbody>
      </Table></Box>
        </ModalBody>
      </ModalContent>
    </Modal>
    <Modal onClose={closeDeleteProcessingModal} isOpen={isDeleteDatasetModalOpen} isCentered  size={"md"} >
      <ModalOverlay />
      <ModalContent >
        <ModalHeader bgColor={"#0888BE"} color="white" display="flex" justifyContent="space-between"><Box>Sei sicuro di voler eliminare questa elaborazione? <Text fontSize={"lg"} fontWeight={"bold"} color="yellowgreen">{deleteDatasetProcessingName}</Text></Box><Box><CloseButton onClick={closeDeleteProcessingModal} /> </Box></ModalHeader>
        <ModalBody>
        </ModalBody>
        <ModalFooter > <Button colorScheme='green' mr={3} onClick={() => onDeleteItem(deleteDatasetProcessingName)}>
                                    Si
                                  </Button>
                                   <Button colorScheme='red' onClick={closeDeleteProcessingModal}>
                                    No
                                   </Button></ModalFooter>
      </ModalContent>
    </Modal>
    <Modal onClose={closeTransformProcessingModal} isOpen={isTransformDatasetModalOpen} isCentered  size={"md"} >
      <ModalOverlay />
      <ModalContent >
        <ModalHeader bgColor={"#0888BE"} color="white" display="flex" justifyContent="space-between"><Box>Sei sicuro di voler trasformare questa elaborazione in un risultato? <Text fontSize={"lg"} fontWeight={"bold"} color="yellowgreen">{deleteDatasetProcessingName}</Text></Box><Box><CloseButton onClick={closeTransformProcessingModal} /> </Box></ModalHeader>
        <ModalBody>
        </ModalBody>
        <ModalFooter > <Button colorScheme='green' mr={3} onClick={() => onTransformItem(deleteTransformProcessingName)}>
                                    Si
                                  </Button>
                                   <Button colorScheme='red' onClick={closeTransformProcessingModal}>
                                    No
                                   </Button></ModalFooter>
      </ModalContent>
    </Modal>
        </>
        



)


}
