import { useEffect, useState } from "react";
import { TableContainer, useDisclosure ,Modal,ModalBody,Tr, Td, Th, Table,ModalOverlay, ModalContent,ModalHeader, Thead, Tbody, Icon, Box, Heading, useToast, Text,CloseButton, ModalFooter, Button, } from "@chakra-ui/react";
import { EditIcon,SearchIcon, DeleteIcon} from '@chakra-ui/icons';
import { react_api } from "../config";
import { HTTP_STATUS_CODES } from "../GrinsCostant";
import createAxiosInstance from "../interceptors/useAxiosInterceptors"
import { useSession } from '../SessionContext';
import {  useNavigate} from "react-router-dom";
import logout from "../services/authService";
import MetadataModal from "./MetadataModal";
import convertUtcToLocal from "../services/utils";
/*
  Carica i risultati in una tabella ogni volta che viene caricata la pagina (useEffect) interrogando l'endpoint con 
  l'invio di un json contente il token di identificaione, l'username dellutente e una costante caseType per indicare al backend 
  di quale chiamata si tratta e quali paremetri deve inviarci. Per consultare i case type consultare il file CaseTypeConstant.js  
  */
export default function StagingAreaView (){
  const [message, setMessage] = useState("");
  const [catalog, setCatalog] = useState([]);
  const toast = useToast();
  const { updateSessionData, clearSessionData} = useSession();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure(); 
  const axiosInstance = createAxiosInstance(async (axiosInstance)=> {

    logout(clearSessionData,navigate,toast,axiosInstance)
  });

  const [datasetList, setDatasetList] = useState([]);
  const [keys, setKeys] = useState([]);

  const [modalData, setModalData] = useState({ item: null });
  const [datasetTableName, setDatasetTableName] = useState("");
  const [isDatasetModalOpen, setDatasetModalOpen] = useState(false);
  const [isDeleteDatasetModalOpen, setDeleteDatasetModalOpen] = useState(false);
  const [deleteDatasetTableName, setDeleteDatasetTableName] = useState("");


  const openModal = (item) => {
    setModalData({ item: item });
    onOpen();
  };


  const openDeleteDatasetModal = (item) => {
    
    setDeleteDatasetTableName(item);
    setDatasetTableName(item.tableName)
    setDeleteDatasetModalOpen(true);
  }

  const closeDeleteDatasetModal = () => {
    setDeleteDatasetModalOpen(false);

  }

  const onDeleteItem = (item) => {

   
   
    deleteTableADP(item.tableName).then(()=> {
      setCatalog((prevCatalogItems) => prevCatalogItems.filter((catalogItem) => catalogItem.tableName !== item.tableName));
     
    })  

    closeDeleteDatasetModal();
  };


  const viewData =(item) => {
    
   visualizzaDataset(item.tableName)
  }


  const openDatasetModal = (response, tableName) => {
  
    setKeys(Object.keys(response.previewTable[0]))
    setDatasetList(response.previewTable);
    setDatasetTableName(tableName);
    setDatasetModalOpen(true);
  };

  const visualizzaDataset = async (TBL_NAME) => {
    try {
      const body =  {
        username: sessionStorage.getItem("username"),
        tableName: TBL_NAME,
      };
      const options = {
        
        headers: {
          'Content-Type': 'application/json',
          'Authorization': sessionStorage.getItem("token")
        },
        
      }
      const urlFetch = `${react_api}/getTableADP`;
      const response = await axiosInstance.post(urlFetch, body, options);
      const data =  response.data;
      
  
      if (response.status.toString() === HTTP_STATUS_CODES.OK) {
        
        openDatasetModal(data, TBL_NAME)
        
        
      } else {
        throw new Error('Errore nel recupero dei dati');
      }
    } catch (error) {
      toast({
        title: 'Chiamata in errore',
        description: String(error),
        status: 'warning',
        duration: 4000,
        isClosable: true
      });
    }
  };

  const deleteTableADP = async (TBL_NAME) => {

    
    try {
      const body =  {
        username: sessionStorage.getItem("username"),
        tableName: TBL_NAME,
      };
      const options = {
        
        headers: {
          'Content-Type': 'application/json',
          'Authorization': sessionStorage.getItem("token")
        },
        
      }
      const urlFetch = `${react_api}/deleteTableADP`;
      const response = await axiosInstance.post(urlFetch, body, options);
      const data =  response.data;
    
  
      if (data.status === HTTP_STATUS_CODES.OK) {
        
        toast({
          title: 'Elemento Staging Area cancellato con successo',
          description: "",
          status: 'info',
          duration: 2000,
          isClosable: true
        });
        
        
      } else {
        throw new Error('Errore nella cancellazione elemento Staging Area');
      }
    } catch (error) {
      toast({
        title: 'Chiamata in errore',
        description: String(error),
        status: 'warning',
        duration: 4000,
        isClosable: true
      });
    }
  };
  const closeDatasetModal = () => {
    setDatasetModalOpen(false);
  };
  
  useEffect(() => {
    const fetchCatalog = async () => {
      try {
         
         
          const options =   {
            
            headers: {
              'Content-Type': 'application/json',
              'Authorization' : sessionStorage.getItem("token"),
            
            }
            
           
          }
         
         
          const urlFetch = `${react_api}/stagingAreaResult/getCatalogADP`;
          
          const response = await axiosInstance.post(urlFetch,{username:sessionStorage.getItem("username")}, options);
          
          const result =  response.data;
   
          if( result.status === HTTP_STATUS_CODES.OK){
            setCatalog(result.catalog)
             }
             if( result.status === HTTP_STATUS_CODES.OK){
              setCatalog(result.catalog)
               }
           
            else{
              toast({
              title: 'Tabella dei risultati vuota',
              description: String(result.message),
              position: "top",
              status: 'info',
              duration: 4000,
              isClosable: true});
              setMessage(result.message);}
      } catch (error) {
        
        toast({
            title: 'Chiamata in errore',
            description: String(error),
            position: "top",
            status: 'warning',
            duration: 4000,
            isClosable: true});
      }
        };

    fetchCatalog();}
, []);


        return(
          <>

<Modal onClose={closeDatasetModal} isOpen={isDatasetModalOpen} isCentered  >
      <ModalOverlay />
      <ModalContent  maxW="85%" h="50%" >
        <ModalHeader bgColor={"#0888BE"} color="white" display="flex" justifyContent="space-between"><Box>Vista del dataset: <Text fontSize={"lg"} fontWeight={"bold"} color="yellowgreen">{datasetTableName}</Text></Box><Box><CloseButton onClick={closeDatasetModal} /> </Box></ModalHeader>
        <ModalBody overflow="auto">
          <Box   >
        <Table variant="striped" size={"sm"} h={"100%"} w="100%">
        <Thead bgColor={"#0888BE"} position={"sticky"} >
          <Tr>
            {keys ? (
            keys.map((key) => (
              <Th color="white" key={key}>{key}</Th> // Renderizza le chiavi come intestazioni di colonna
            ))):("")}
          </Tr>
        </Thead>
        <Tbody>
        {datasetList.length > 0 ?
          (datasetList.map((item, index) => (
            <Tr key={index}>
              {keys.map((key) => (
                <Td key={key}>{item[key]}</Td> // Renderizza il valore corrispondente alla chiave
              ))}
            </Tr>
          ))):("")}
        </Tbody>
      </Table></Box>
        </ModalBody>
      </ModalContent>
    </Modal>

<MetadataModal isOpen={isOpen} onClose={(data)=> { if(data && data.tableId){setCatalog(catalog.filter(cat => cat.id !== data.tableId))} onClose()}} content={modalData.item} />
          
      <Box alignItems={"center"} justifyContent={"center"} display={"flex"} color={"rgba(11,78,90,255)"} bgColor={"#f6f3ec"}>
        <Heading>Staging Area</Heading></Box>
        <Box bgColor={"white"}   overflowY="auto" direction="column" h="100%" w="100%" boxShadow="lg">
          <TableContainer px={"1%"} paddingTop={"1%"} >
            <Table variant='striped'>
              
              <Thead bgColor={"#0888BE"} position="sticky" top="0">
                <Tr>
                 
                  <Th  whiteSpace={"normal"} wordBreak={"break-word"} color={"white"}>Id della tabella</Th>
                  <Th  whiteSpace={"normal"} wordBreak={"break-word"} color={"white"}>Titolo</Th>
                  <Th  whiteSpace={"normal"} wordBreak={"break-word"} color={"white"}>Nome tabella</Th>
                  <Th  whiteSpace={"normal"} wordBreak={"break-word"} color={"white"}>Data di creazione</Th>
                  <Th  whiteSpace={"normal"} wordBreak={"break-word"} color={"white"}>Azioni</Th>


                </Tr>
              </Thead>
              <Tbody>
              {catalog.length > 0 ? 
                (catalog.map((item, index) => ( //inserisci l'id della tabella nella funzione dell'azione per completare l'inserimento dei metadati
                  
                <Tr>
                 
                  {/*nome dataset*/} <Td  whiteSpace={"normal"} wordBreak={"break-word"}> {item.id}</Td>
                  {/*categoria*/}<Td  whiteSpace={"normal"} wordBreak={"break-word"}>{item.title}</Td>
                  {/*description*/} <Td  whiteSpace={"normal"} wordBreak={"break-word"}>{item.tableName}</Td>
                  <Td  whiteSpace={"normal"} wordBreak={"break-word"}>{convertUtcToLocal(item.creationTime)}</Td>
                  <Td whiteSpace={"normal"} wordBreak={"break-word"} >
                  <Icon marginLeft={"10%"} as={SearchIcon} _hover={{color: "#0888BE"}} onClick={()=>  viewData(item)  } />
                    <Icon marginLeft={"10%"} as={EditIcon} _hover={{color: "#0888BE"}} onClick={()=>  openModal(item)  } />
                    
                  <Icon marginLeft={"10%"}   as={DeleteIcon} _hover={{color: "#0888BE"}} onClick={()=>  openDeleteDatasetModal(item)  } />

            

                    </Td> 
                  </Tr>))) : (<Tr><Text fontSize={"lg"} fontWeight={"bold"}>{message}</Text></Tr>)}
              </Tbody>     
            </Table>
        </TableContainer>
        
        
        <Modal onClose={closeDeleteDatasetModal} isOpen={isDeleteDatasetModalOpen} isCentered  size={"md"} >
      <ModalOverlay />
      <ModalContent >
        <ModalHeader bgColor={"#0888BE"} color="white" display="flex" justifyContent="space-between"><Box>Sei sicuro di voler eliminare questo dataset? <Text fontSize={"lg"} fontWeight={"bold"} color="yellowgreen">{datasetTableName}</Text></Box><Box><CloseButton onClick={closeDeleteDatasetModal} /> </Box></ModalHeader>
        <ModalBody>
        </ModalBody>
        <ModalFooter > <Button colorScheme='green' mr={3} onClick={() => onDeleteItem(deleteDatasetTableName)}>
                                    Si
                                  </Button>
                                   <Button colorScheme='red' onClick={closeDeleteDatasetModal}>
                                    No
                                   </Button></ModalFooter>
      </ModalContent>
    </Modal></Box></>
)



}
