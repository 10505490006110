import  {BrowserRouter as Router, Route, Routes, useNavigate} from 'react-router-dom';
import Header from './components/Header';
import * as React from 'react';
import { ChakraProvider, Box } from '@chakra-ui/react';
import Navbar from './components/Navbar';

import GuestApp from './guestApp';

import Home from './Home/Home';
//import Footer from './components/Footer';
//import Areatool from './components/AreaTool';
import DatasetView from './components/DatasetView';
import Risultati from './components/Risultati';
import Dashboard from './components/Dashboard';
//import { Box, Grid } from "@chakra-ui/react";
//import Password from './components/cambioPassword';
import DatasetPage from './components/DatasetPage';
//import Login from './components/Login';
//import { UserProvider } from './UserContext';
import { SessionProvider } from './SessionContext';
//import DragDrop from './components/Dragdrop';
//import DndProva from './components/DndProva';
import Reindirizzamento from './Reindirizzamento';
//import CopiaDND from './components/CoppiaDND';
//import Dnd2 from './components/Dnd2';
//import DragDrop from './components/Dragdrop';
import DragAndDrop from './components/DragAndDrop';

//import Reindirizzamento2 from './Reindirizzamento2';
import JupyterHub from './components/JupyterHub';
import ZeppelinView from './components/Zeppelin';
import SupersetView from './components/Superset';
import StagingAreaView from './components/StagingAreaView';
import ProcessingView from './components/ProcessingView';
//import DashboardView from './components/DashboardView';

import DashboardView from './components/DashboardView';
//<Route path="/uploadDataset" element={<DatasetUpload />} />

function App() {
  
  return (
   
    <Router>
    <SessionProvider>
      <ChakraProvider>
   

      
      
      {!sessionStorage.username ? (
            <Routes>
              {/* Rotte per l'area Guest */}
              <Route path="/login" element={<Reindirizzamento />} />
              <Route path="/*" element={<GuestApp />} />
              {/* Se l'utente tenta di andare su una delle rotte senza essere loggato */}
            </Routes>
          ) : (
            sessionStorage.username === "anonymous" ?(
              <Reindirizzamento />
            ) : (
      

        <Box
          height="100vh"
          width="100vw"
          display="flex"
          flexDirection="column"
          overflow="hidden" 
        >
          <Box width="100%">
            <Header />
          </Box>
          
          <Box
            flex="1"  // Occupa lo spazio rimanente
            display="flex"
            flexDirection="row"
            overflow="auto"  
          >
            
              <Box
                height="100%"
                width="6%"
                bg="rgba(11,78,90,255)"
                paddingTop={5}
                display="flex"
                flexDirection="column"
                boxShadow='inner'
              >
                <Navbar />
              </Box>
              <Box
                width="94%"
                display="flex"
                flexDirection="column"
                overflow="hidden"  // Evita lo scrolling all'interno
                id='workarea'
              >
                {/* Area di lavoro */}
                <Routes>
                
                  
                  <Route path="/home" element={<Home />} />
                  <Route path="/" element={<Home />} />
                  <Route path="/dataset" element={<DatasetPage />} />
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/risultati" element={<Risultati />} />
                  
                  <Route path="/processing" element={<ProcessingView />} />
                  <Route path="/stagingarea" element={<StagingAreaView />} />               
                  <Route path="/query" element={<DragAndDrop />} />
                  <Route path="/JupyterHub" element={<JupyterHub />} />
                  <Route path="/Zeppelin" element={<ZeppelinView />} />
                  <Route path="/Superset" element={<SupersetView />} />
                  <Route path="/iframe" element={<DashboardView />} />

                </Routes>
              </Box>
            
          </Box>

          <Box width="100%">
          
          </Box>
        </Box>))}
      
      </ChakraProvider>
    </SessionProvider></Router>
  
);
}

export default App;
