import { useState, useEffect } from "react";
import { 
  TableContainer,  Tr, Td, Th, Table, Thead, Tbody, Icon, Button, Box, Heading, 
  useToast, Input, Select, Popover, PopoverTrigger, Portal
  ,PopoverContent, PopoverArrow, PopoverHeader,Checkbox, PopoverCloseButton, Stack, FormControl, FormLabel, PopoverFooter, PopoverBody, Spacer
} from "@chakra-ui/react";
import { MdArrowBack, MdArrowForward, MdSearch, MdRestartAlt } from "react-icons/md";
import { react_api } from "../config";
import { HTTP_STATUS_CODES } from "../GrinsCostant";

function DatasetGuest() {
  
  const [catalogCopy, setCatalogCopy] = useState([]);
  const categories = [
    'Agricoltura, pesca, silvicoltura e prodotti alimentari',
    'Economia e finanza',
    'Istruzione, cultura e sport',
    'Energia',
    'Ambiente',
    'Governo e settore pubblico',
    'Salute',
    'Tematiche internazionali',
    'Giustizia, sistema giuridico e sicurezza pubblica',
    'Regioni e città',
    'Popolazione e società',
    'Scienza e tecnologia',
    'Trasporti'
  ];

  const [searchDescriptionInput, setSearchDescriptionInput] = useState("");
 

  const [searchSelectedValue, setSearchSelectedValue] = useState('name');
  

  const [allChecked, setAllChecked] = useState(false);
  const [isIndeterminate, setIsIndeterminate] = useState(false);
  
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [catalog, setCatalog] = useState([]);
  const toast = useToast();

  const [searchNameInput, setSearchNameInput] = useState("");

  const [searchTitleInput, setSearchTitleInput] = useState("");

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= Math.ceil(catalog.length / itemsPerPage)) {
      setCurrentPage(newPage);
    }
  };


  const handleSelectSearchChange = (event) => {
        
    setSearchSelectedValue(event.target.value);
   
  };

  const handleSearchDescriptionInput = (event) => {
        
    setSearchDescriptionInput(event.target.value);
   
  };








  const [searchCheckedCategories, setSearchCheckedCategories] = useState(
    Array(categories.length).fill(false))



  const handleCategoryChange = (index) => (e) => {
    const updatedCheckedCategories = [...searchCheckedCategories];
    updatedCheckedCategories[index] = e.target.checked;
    setSearchCheckedCategories(updatedCheckedCategories);
  };

  const handleSelectAllChange = (e) => {
    const isChecked = e.target.checked;
    setSearchCheckedCategories(Array(categories.length).fill(isChecked));
    setAllChecked(isChecked);
    setIsIndeterminate(false);
  };

  const handleSearchNameInput = (event) => {
        
    setSearchNameInput(event.target.value);
   
  };

  const handleSearchTitleInput = (event) => {
        
    setSearchTitleInput(event.target.value);
   
  };
  useEffect(() => {
 
    
    const allChecked = searchCheckedCategories.every(Boolean);
    const someChecked = searchCheckedCategories.some(Boolean);
    setAllChecked(allChecked);
    setIsIndeterminate(!allChecked && someChecked);

    const fetchCatalog = async () => {
      try {
        
        const options = {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
          
        }
        const urlFetch = `${react_api}/guestDataset/getDatasetCatalogADP`;
        const response = await fetch(urlFetch, options);
       
        const result = await response.json();
      
        if (result.status === HTTP_STATUS_CODES.OK) {
          setCatalog(result.catalogues);
          setCatalogCopy(result.catalogues)
        }
      } catch (error) {
        toast({
          title: 'Chiamata in errore',
          description: String(error),
          position: "top",
          status: 'warning',
          duration: 4000,
          isClosable: true
        });
      }
    };
    fetchCatalog();
  }, [searchCheckedCategories]);
  

 
  const applyFilterDataset = () => {
   

    function updatePaginatedDate() {
        setCurrentPage(1)

       paginatedData =catalogFiltered.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
       totalPages = Math.ceil(catalogFiltered.length / itemsPerPage);

    }
    let catalogFiltered = catalogCopy
    

    if(searchCheckedCategories != null && searchCheckedCategories.length >0) {

      let categoriesFilter = []
      searchCheckedCategories.forEach((value, index) => {
      if(value)
      categoriesFilter.push(categories[index])
      });

      

      if(categoriesFilter != null && categoriesFilter.length >0) {
        
        catalogFiltered =  catalogFiltered.filter(pageData =>
            pageData.categories.length > 0 && pageData.categories.some(cat => categoriesFilter.includes(cat))
        );
        updatePaginatedDate()
      }
       
    }



    
    if(searchTitleInput != null && searchTitleInput.length >0 ) {
     
     
      catalogFiltered =catalogFiltered.filter(pageData => pageData.title != null).filter(pageData => pageData.title.toLowerCase() === searchTitleInput.toLowerCase() ||  pageData.title.toLowerCase().includes(searchTitleInput.toLowerCase()))
      updatePaginatedDate()

    
    }

    if(searchNameInput != null && searchNameInput.length >0 ) {
     
     
      catalogFiltered =catalogFiltered.filter(pageData => pageData.tableName.toLowerCase() === searchNameInput.toLowerCase() ||  pageData.tableName.toLowerCase().includes(searchNameInput.toLowerCase()))
      updatePaginatedDate()

    
    }

 
    if(searchDescriptionInput != null && searchDescriptionInput.length >0 ) {
     
   
      catalogFiltered =catalogFiltered.filter(pageData => pageData.description.toLowerCase() === searchDescriptionInput.toLowerCase() ||  pageData.description.toLowerCase().includes(searchDescriptionInput.toLowerCase()))
      updatePaginatedDate()

    
    }

    

    setCatalog(catalogFiltered)

  };




  const resetFilterDataset= () => {
    setCatalog(catalogCopy)
    setCurrentPage(1)
    paginatedData =catalog.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
    totalPages =Math.ceil(catalog.length / itemsPerPage);
    setSearchCheckedCategories(Array(categories.length).fill(false))
    setSearchDescriptionInput("")
    setSearchTitleInput("")
    setSearchNameInput("")
    setSearchSelectedValue("name")
   
  } 
  
 
  
  
    
 
  

  var paginatedData = catalog.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
  var totalPages = Math.ceil(catalog.length / itemsPerPage);
  const startIndex = currentPage * itemsPerPage - 9;
  const endIndex =  Math.min((currentPage ) * itemsPerPage, catalog.length);
  return (
    <>
      <Box alignItems={"center"} justifyContent={"space-between"} display={"flex"} color={"rgba(11,78,90,255)"} bgColor={"#f6f3ec"} ><Heading>Datasets</Heading>
      <Button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} bg={"#f6f3ec"}><MdArrowBack /></Button>
          <Box>Page {currentPage}/{totalPages} <Spacer /> Dataset {startIndex} - {endIndex} di {catalog.length}  </Box>
          <Button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage * itemsPerPage >= catalog.length}  bg={"#f6f3ec"} ><MdArrowForward /></Button>
          
          <Box>
          <Popover >  
            <PopoverTrigger>
              <Button  color={"rgba(11,78,90,255)"} bgColor={"#f6f3ec"} h={"100%"}><Icon as={MdSearch}/>Search</Button>
            </PopoverTrigger>
            <Portal>
              <PopoverContent bgColor={"white"} borderWidth={2} width={"100%"} borderColor={"#0888BE"}>
                <PopoverArrow />
                <PopoverHeader color={"rgba(11,78,90,255)"} fontSize='lg' fontWeight='semibold'>Search in the table
                <Button  ml={4}
                  
                  leftIcon={<Icon as={MdRestartAlt} fontSize="2xl" />} // Set the icon to the left of the text
                  colorScheme="teal" // Adjust the color scheme as needed
                  variant="solid" // Adjust the button variant
                  onClick={resetFilterDataset} // Attach the onClick handler
                  >Reset
                </Button>
                </PopoverHeader>
                <PopoverCloseButton color={"rgba(11,78,90,255)"} />
                <PopoverBody>
                <Stack spacing='4'>
                <Box>
                    <FormControl>
                    <FormLabel fontSize='lg' color="rgba(11,78,90,255)" fontWeight='semibold'>Category:</FormLabel>
                    <Select
                      value={searchSelectedValue}
                      onChange={handleSelectSearchChange}
                      variant="filled"
                    >
                       <option value='name' variant="filled" defaultChecked >Name</option>
                       <option value='title' variant="filled">Title</option>
                       <option value='category' variant="filled"  >Category</option>
                       <option value='description' variant="filled">Description</option>
                    
                      
                     

                       
    
                    </Select>
                    </FormControl>
                    </Box>
                    <Box>
                    

                      
                    {searchSelectedValue === "visibility"  ?  
                       
                       
                       (""
                   ): (
   
                     searchSelectedValue === "category"  ?  (
                       <Box>
                       <Checkbox
                       isChecked={allChecked}
                       isIndeterminate={isIndeterminate}
                       onChange={handleSelectAllChange}
                     >
                         Seleziona/Deseleziona Tutti
                     </Checkbox>
                     <Stack pl={6} mt={1} spacing={1}>
   
                    
   
                       {categories.map((category , index)=> (
            
                       
                       <Checkbox
                         isChecked={searchCheckedCategories[index]}
                         onChange={handleCategoryChange(index)}
                       >
                         {category}
                       </Checkbox>
                      
   
                      ))}
                     </Stack>
                   </Box>
   
                     ) 
                     
                     :(
                      
                             
                      searchSelectedValue === "description"  ?  (
                           
                        <Box><FormLabel fontSize='lg' color="rgba(11,78,90,255)" fontWeight='semibold'>Inserisci una descrizione:</FormLabel>
                        <Input variant='filled' placeholder='value' name='valueForSearch' type='text' bg='white'  value={searchDescriptionInput}   onChange={handleSearchDescriptionInput}  /></Box>
                            
                    ) : (


                     searchSelectedValue === "name"  ?  (

                       <Box><FormLabel fontSize='lg' color="rgba(11,78,90,255)" fontWeight='semibold'>Inserisci un nome di dataset:</FormLabel>
                        <Input variant='filled' placeholder='value' name='valueForSearch' type='text' bg='white' value={searchNameInput}   onChange={handleSearchNameInput}   /></Box>
                     ): (
                       
                      <Box><FormLabel fontSize='lg' color="rgba(11,78,90,255)" fontWeight='semibold'>Inserisci un titolo di dataset:</FormLabel>
                      <Input variant='filled' placeholder='value' name='valueForSearch' type='text' bg='white' value={searchTitleInput}   onChange={handleSearchTitleInput}   /></Box>
                     )

                        
                       
                       )
                     
                    
   
                     
                       )
                      )
                       }
   
                    
                    </Box>
                    </Stack>
                  
                </PopoverBody>
                <PopoverFooter><Button colorScheme='green' onClick={applyFilterDataset} ><Icon as={MdSearch } />Search</Button></PopoverFooter>
              </PopoverContent>
            </Portal>
          </Popover>
          
          </Box>
</Box>
      

      <Box   overflowY="auto" direction="column" h="100%" w="100%" boxShadow="lg">
      
        <TableContainer px={"1%"} paddingTop={"1%"}>
          <Table variant='striped' w="100%">
            <Thead bgColor={"#0888BE"} position="sticky" top="0">
              <Tr>
                
                <Th align="left" w={"20%"}  color={"white"}>Dataset</Th>
                <Th  color={"white"} w={"15%"} whiteSpace={"normal"} wordBreak={"break-word"}>Dataset Id</Th>
                <Th  color={"white"} w={"25%"} whiteSpace={"normal"} wordBreak={"break-word"}>Title</Th>
                <Th  color={"white"} w={"15%"} whiteSpace={"normal"} wordBreak={"break-word"}>Description</Th>
                <Th  color={"white"} w={"10%"} whiteSpace={"normal"} wordBreak={"break-word"}>Categories</Th>
              </Tr>
            </Thead>
            <Tbody>
              {catalog ? (
                paginatedData.map((item, index) => {
                    
                //const categorie = item.category.split("\\"); 
                return (
                  
                <Tr key={index}>
                  <Td whiteSpace={"normal"} wordBreak={"break-word"} >{item.tableName}</Td>
                  <Td whiteSpace={"normal"} wordBreak={"break-word"}>{item.id}</Td>
                  <Td whiteSpace={"normal"} wordBreak={"break-word"} >{item.title}</Td>
                  <Td whiteSpace={"normal"} wordBreak={"break-word"} >{item.description}</Td>
                  <Td whiteSpace={"normal"} wordBreak={"break-word"}>  {item.categories}</Td>
                </Tr>);})) : ("")}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
      
    
      
    </>
  );
}

export default DatasetGuest;
