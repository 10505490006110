function convertUtcToLocal(utcDateStr) {
  // Proviamo a creare una data a partire dalla stringa
  const date = new Date(utcDateStr + 'Z'); // Aggiungi 'Z' per indicare che è UTC

  // Verifica se la data è valida
  if (!isNaN(date.getTime())) {
    // Se la data è valida, restituiamo la data in formato "YYYY-MM-DD HH:MM:SS"
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Mesi da 0 a 11
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  } else {
    // Se la data non è valida, restituisci la stringa originale
    return utcDateStr;
  }
  }

  export default convertUtcToLocal