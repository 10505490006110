import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  ChakraProvider,Badge,
  Box,
  Spacer,
  Button,
  Spinner,
  Center,
  Heading,
  Text,
  Flex,
  useToast,
  Icon,
  Select,
  Input,
  Modal,
  ModalOverlay,
  ModalContent, 
  ModalHeader,
  ModalBody,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  FormControl,
  FormLabel,
  ModalFooter,
  CloseButton
} from "@chakra-ui/react";
import { MdAdd, MdArrowBack, MdArrowForward, MdClose, MdDelete, MdOutlinePlayArrow, MdSave } from "react-icons/md";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { react_api } from "../config";
import { CASE_TYPE } from "../CaseTypeCostant";
import { HTTP_STATUS_CODES } from "../GrinsCostant";
import logout from "../services/authService";
import createAxiosInstance from "../interceptors/useAxiosInterceptors"



import { useSession } from '../SessionContext';
import {  useNavigate} from "react-router-dom";



var buttonsOperationSelect = [
  { label: "Visualizza dataset", id: "SELECT *", class: "btn-success" },
  {
    label: "Visualizza più colonne",
    id: "SELECT MULTICOL",
    class: "btn-success",
  },
  {
    label: "Visualizza colonne con raggruppamento",
    id: "SELECT AGGR",
    class: "btn-success",
  },
  {
    label: "Visualizza dataset con filtro",
    id: "SELECT WHERE",
    class: "btn-success",
  },
];

const getBackgroundColorByVisibility = (visibility) => {
  
    
  if (visibility === "private") {
    return "green.400";    
  } else if (visibility === "obtained") {
    return "orange.400"; 
  }else if(visibility === "public"){
    return "yellow.400";
  }
  else if(visibility === "general"){
  return "blue.400";
}
else{
  return "blue.500"
}
}

var buttonsOperationJoin = [
  { label: "Unione", id: "INNER JOIN", class: "btn-success" },
  { label: "Tutto da Sinistra", id: "LEFT JOIN", class: "btn-success" },
  { label: "Tutto da Destra", id: "RIGHT JOIN", class: "btn-success" },
  { label: "Combinazioni Complete", id: "FULL JOIN", class: "btn-success" },
];

var aggregationTypes = [
  
  { label: "AVG", id: "avg" },
  { label: "SUM", id: "sum" },
  { label: "MIN", id: "min" },
  { label: "MAX", id: "max" },
  { label: "COUNT", id: "count" }
];

var matTypes = [
  { label: "=", id: "uguale" },
  { label: "!=", id: "diverso" },
  { label: ">", id: "maggiore" },
  { label: "<", id: "minore" },
  { label: ">=", id: "maggiore-uguale" },
  { label: "<=", id: "minore-uguale" },
];


var textTypes = [
  { label: "=", id: "Uguale A" },
  { label: "!=", id: "Diverso Da" },
];
var catalog1 = ([] = []);

// Draggable Component
const DraggableItem = ({ id, text ,visibility}) => {
 
  const [, drag] = useDrag(() => ({
    type: "ITEM",
    item: {id:  id.id,  type: id.type },
  }));
  

    let buttonColor = getBackgroundColorByVisibility(visibility)
  
     return (
    <Button h="20px" ref={drag} bg={buttonColor} borderWidth={1} borderColor={"black"}>
      {text}
    </Button>
  );
  



};

// Droppable Component
const DroppableArea = ({ id, onDrop, children}) => {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: "ITEM",
    drop: (item) => {
      let btnOperationJoin = buttonsOperationJoin.find(
        (btnOperationJoin) => btnOperationJoin.label == item.id
      );
      let btnOperationSelect = buttonsOperationSelect.find(
        (btnOperationSelect) => btnOperationSelect.label == item.id
      );

    

      onDrop(
        item.id,
        id,
        btnOperationJoin != null ? btnOperationJoin : btnOperationSelect,
        item.type
      );
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  return (
    
    <Box
      ref={drop}
      borderWidth="1px"
      borderRadius="md"
      p={3}
      mb={3}
      h="100%"
      borderColor={"black"}
      bgColor={isOver ? "green.200" : "gray.200"}
    >
      {children}
    </Box>
  
  );
};

function DragAndDrop() {
  const [operation, setOperation] = useState();
  const [datasetView, setDatasetView] = useState();

  const [firstUnionDataset, setFirstUnionDataset] = useState();
  const [secondUnionDataset, setSecondUnionDataset] = useState();

  const [columnsDroppedFirstUnionDataset, setColumnsDroppedFirstUnionDataset] = useState([]);
  const [columnsDroppedSecondUnionDataset, setColumnsDroppedSecondUnionDataset] = useState([]);

 
  const [columnsFirstUnionDataset, setColumnsFirstUnionDataset] = useState([]);
  const [columnsSecondUnionDataset, setColumnsSecondUnionDataset] = useState([]);
 
  const [columnsFirstUnionDatasetCopy, setColumnsFirstUnionDatasetCopy] = useState([]);
  const [columnsSecondUnionDatasetCopy, setColumnsSecondUnionDatasetCopy] = useState([]);


  const [operazioniSelect, setOperazioniSelect] = useState([]);
  const [operazioniJoin, setOperazioniJoin] = useState([]);
  const [columns, setColumns] = useState([]);
  const [columnsCopy, setColumnsCopy] = useState([]);
  const [columnsDropped, setColumnsDropped] = useState([]);
  const [catalog, setCatalog] = useState([]);
  const toast = useToast();

  const [aggregationView, setAggregationView] = useState([]);
  const [matematicalFilterView, setMatematicalFilterView] = useState([]);
  const [textualFilterView, setTextualFilterView] = useState([]);
  const [unionKeysView, setUnionKeysView] = useState([]);


  const [activitySelected, setActivitySelected] = useState("");

  const [objectPagination, setObjectPagination] = useState({});

  const [generalLoading, setGeneralLoading] = useState(false);
  const [resultLoading, setResultLoading] = useState(false);



  const [isModalResultOpen, setIsModalResultOpen] = useState(false);
  const [keysResult, setKeysResult] = useState([]);
  const [resultData, setResultData] = useState([]);
  const [payloadRequestResult, setPayloadRequestResult] = useState({});
  const [isModalSave ,setIsModalSave] = useState(false)


  const closeResultModal = () => {
    setIsModalResultOpen(false);
  };

  const closeSaveModal = () => {
    setIsModalSave(false);
  };
  const openaResultModal = () => {
    setIsModalSave(true)
    
  };
  const getCOUNT = () => {

   

    removeCountFromAggregationTypes();

    return <Box  display="flex"             // Imposta il display a flex
  alignItems="center"       // Allinea verticalmente al centro
  justifyContent="center"    // Allinea orizzontalmente al centro
              // Imposta l'altezza del box (puoi modificare questo valore)
    >
    
  {"COUNT"}</Box>
    
  };

  


  const [query, setQuery] = useState('');

  const salvaRisultato = async (tableName) => {
    try {
      const richiesta = {
        username: sessionStorage.getItem('username'),
        query: query,
        tableName: tableName
      }

      const options = {
        "Content-Type": "application/json",
        'Authorization' : sessionStorage.getItem('token')
      }

        const urlFetch = `${react_api}/DragAndDrop/saveDatasetADP`;
        const response = await axiosInstance.post(urlFetch,richiesta, options);
        const result = response.data;
        if (result.status === HTTP_STATUS_CODES.OK) {
          toast({
            title: "Chiamata andata a buon fine",
            description: "",
            position: "top",
            status: "success",
            duration: 4000,
            isClosable: true,
          });
          closeSaveModal();
        }
          
    }
    catch (error) {
      toast({
        title: "Chiamata in errore",
        description: String(error),
        position: "top",
        status: "warning",
        duration: 4000,
        isClosable: true,
      });
    }
  }

  const backResult = () => {
    // Update the state and then run the request
    setPayloadRequestResult(prevState => {
      const newState = {
        ...prevState,
        window: prevState.window - 1
      };
      setResultLoading(true)

      // Run the request with the new state
      runRequest(newState).then(res => {
       
        if (res != null && res.data != null && res.data.length > 0) {
          let objPagination = res.data.find(item => item.hasOwnProperty('max_window_num'))
          setObjectPagination(objPagination)
          const dataWithRowNumbers = addRowNumbers(res.data, objPagination.offset);
          setResultData(dataWithRowNumbers);
          setResultLoading(false)

        }
      });
  
      // Log the new state
      console.log(objectPagination);
      console.log(newState.window);
  
      return newState; // Return the new state
    });
  };
  
  const nextResult = () => {
    // Update the state and then run the request
    setPayloadRequestResult(prevState => {
      const newState = {
        ...prevState,
        window: prevState.window + 1
      };
      

      setResultLoading(true)
      // Run the request with the new state
      runRequest(newState).then(res => {
        if (res != null && res.data != null && res.data.length > 0) {
          // Calcola il numero di righe attuale per il numero di partenza
          let objPagination = res.data.find(item => item.hasOwnProperty('max_window_num'))

          setObjectPagination(objPagination)
          const dataWithRowNumbers = addRowNumbers(res.data, objPagination.offset);
          delete dataWithRowNumbers[dataWithRowNumbers.length -1]
          setResultData(dataWithRowNumbers);
          setResultLoading(false)
        }
      });
  
      // Log the new state
      console.log(objectPagination);
      console.log(newState.window);
  
      return newState; // Return the new state
    });
  };


  const { updateSessionData, clearSessionData} = useSession();
  const navigate = useNavigate();
  const axiosInstance = createAxiosInstance(async (axiosInstance)=> {

    logout(clearSessionData,navigate,toast,axiosInstance)
  });


  useEffect(() => {


    
    /*const fetchCatalog = async () => {
      try {
        const richiesta = {
          BearerToken: sessionStorage.getItem("token"),
          username: sessionStorage.getItem("username"),
          caseType: CASE_TYPE.CATALOG_ALL,
        };
        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(richiesta),
        };
        const urlFetch = `${react_api}/DragAndDrop/getDatasetsADP`;
        const response = await fetch(urlFetch, options);
        const result = await response.json();
        if (result.status === HTTP_STATUS_CODES.OK) {



          setCatalog(result.tables_info);
          catalog1 = result.tables_info;
        }

         
        } catch (error) {
        toast({
          title: "Chiamata in errore",
          description: String(error),
          position: "top",
          status: "warning",
          duration: 4000,
          isClosable: true,
        });
      }
    };
    fetchCatalog();*/
    const fetchCatalog = async () => {
      try {
        const richiesta = {
          BearerToken: sessionStorage.getItem("token"),
          username: sessionStorage.getItem("username"),
          caseType: CASE_TYPE.CATALOG_ALL,
        };
        const options = {
       
          headers: {
            "Content-Type": "application/json",
          }
        };
        const urlFetch = `${react_api}/DragAndDrop/getDatasetsADP`;
        const response = await axiosInstance.post(urlFetch,richiesta, options);
        const result = response.data;
        if (result.status === HTTP_STATUS_CODES.OK) {



          setCatalog(result.tables_info);
          catalog1 = result.tables_info;
        }

         
        } catch (error) {
        toast({
          title: "Chiamata in errore",
          description: String(error),
          position: "top",
          status: "warning",
          duration: 4000,
          isClosable: true,
        });
      }
    };
    fetchCatalog();
  }, [toast]);

  /*const fetchColumns = async (tableId) => {
    try {
    
      const options = {
        method: "GET"
       
       
      };
      const urlFetch = `${react_api}/DragAndDrop/getDatasetColADP/${tableId}`;
      const response = await fetch(urlFetch, options);
      const result = await response.json();
      return result;
      
    } catch (error) {
      toast({
        title: "Chiamata in errore",
        description: String(error),
        position: "top",
        status: "warning",
        duration: 4000,
        isClosable: true,
      });
    }
  };*/
  const fetchColumns = async (tableId) => {
    try {
    
    
      const urlFetch = `${react_api}/DragAndDrop/getDatasetColADP/${tableId}`;
      const response = await axiosInstance.get(urlFetch);
      const result =  response.data;
      return result;
      
    } catch (error) {
      toast({
        title: "Chiamata in errore",
        description: String(error),
        position: "top",
        status: "warning",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  const handleIconClickOperazioni = () => {


    setColumns([])
    setColumnsCopy([])

    setColumnsDropped([])

    setColumnsFirstUnionDataset([])
    setColumnsSecondUnionDataset([])

    setDatasetView()

   


    
  
    if (buttonsOperationJoin.map((btn) => btn.label).includes(operation)) {
      
      if(activitySelected != "Visualizza"){
      
     
      setColumnsDropped([])  
      setOperazioniJoin(buttonsOperationJoin);
      }
    }
    if (buttonsOperationSelect.map((btn) => btn.label).includes(operation)) {
      
      if(activitySelected == "Visualizza") {

      setFirstUnionDataset()
      setSecondUnionDataset()
      setColumnsDroppedFirstUnionDataset([])
      setColumnsDroppedSecondUnionDataset([])  
      setOperazioniSelect(buttonsOperationSelect);
      reInitializaAggregationTypes();
      
      }

    }

   
    setOperation();
  };

  const handleIconClickDataset = (droppableAreaId) => {
  
    switch(droppableAreaId) {
      case "selected_dataset":
        setDatasetView();
        setColumns([])
        setColumnsDropped([])
        reInitializaAggregationTypes();
      
        break

        case "selected_first_dataset":
          setFirstUnionDataset()
          setColumnsFirstUnionDataset([])
          setColumnsFirstUnionDatasetCopy([])
          setColumnsDroppedFirstUnionDataset([])
          break

          case "selected_second_dataset":
            setSecondUnionDataset()
            setColumnsSecondUnionDataset([])
            setColumnsSecondUnionDatasetCopy([])
            setColumnsDroppedSecondUnionDataset([])
            break

            default:
              break;

    }
    
    
   
    
    
  };


  
  const handleIconClickColumnsFirstUnionDataset = (item) => {
    setColumnsDroppedFirstUnionDataset((prevColumnsFirstUnionDatasetDropped) =>
      prevColumnsFirstUnionDatasetDropped.filter((column) => column !== item)
    );

    setColumnsFirstUnionDataset((prevColumns) => [item, ...prevColumns]);
  };

  const handleIconClickColumnsSecondUnionDataset = (item) => {
    setColumnsDroppedSecondUnionDataset((prevColumnsSecondUnionDatasetDropped) =>
      prevColumnsSecondUnionDatasetDropped.filter((column) => column !== item)
    );

    setColumnsSecondUnionDataset((prevColumns) => [item, ...prevColumns]);
  };

  const handleIconClickColumns = (item) => {
   

   
    setColumnsDropped((prevColumnsDropped) => {
      
     let columnsDroppedFiltered =   prevColumnsDropped.filter((column) => column !== item)
     matematicalFilterView.filter(matFilter => matFilter.attribute === item).forEach(matFilter => {
     
      setMatematicalFilterView((matematicalFilterView) =>
        matematicalFilterView.map((filter) => 
          filter.id === matFilter.id 
            ? { ...filter, attribute: columnsDroppedFiltered[0] }  // Modifica solo il campo "attribute"
            : filter
        )
      );
    })

    textualFilterView.filter(textFilter => textFilter.attribute === item).forEach(textFilter => {
      console.log(textFilter)
      setTextualFilterView((textualFilterView) =>
        textualFilterView.map((filter) => 
          filter.id === textFilter.id 
            ? { ...filter, attribute: columnsDroppedFiltered[0] }  // Modifica solo il campo "attribute"
            : filter
        )
      );
    })

     return columnsDroppedFiltered;
      
    }
    );

    
    setColumns((prevColumns) => [item, ...prevColumns]);

    reInitializaAggregationTypes();
  

    

   
    }

    



    

  

  const handleIconClickSelectAllColumns = () => {

    
      setColumnsDropped(columnsCopy);
      setColumns([]);

  };

  const handleIconClickSelectAllColumnsFirstDataset = () => {

    setColumnsDroppedFirstUnionDataset(columnsFirstUnionDatasetCopy)
    
    
      setColumnsFirstUnionDataset([]);
  };



  const handleIconClickSelectAllColumnsSecondDataset = () => {

  
      setColumnsDroppedSecondUnionDataset(columnsSecondUnionDatasetCopy)
    
    
      setColumnsSecondUnionDataset([]);
  };


  const handleIconClickDeSelectAllColumns = () => {
    console.log("desel norm", columnsDropped)
    setColumnsDropped([]);
    setColumns(columnsCopy);
    reInitializaAggregationTypes();
  };


  const handleIconClickDeSelectAllColumnsFirstDataset = () => {
    console.log("desel first dat")
    setColumnsDroppedFirstUnionDataset([]);
    setColumnsFirstUnionDataset(columnsFirstUnionDatasetCopy);
  };



  const handleIconClickDeSelectAllColumnsSecondDataset = () => {
    console.log("desel second dat")

    setColumnsDroppedSecondUnionDataset([]);
    setColumnsSecondUnionDataset(columnsSecondUnionDatasetCopy);
  };


  const addAggregationView = (id) => {
   
    
    if(columnsCopy.length === columnsDropped.length) {
      if(!aggregationView.find(aggregationView => aggregationView.operation === "COUNT")) { 
        setAggregationView((aggregation) => [...aggregation, {id: id.toString(), attribute:"*",operation:"COUNT", columnAlias: null}]);
      }

    }else {
      setAggregationView((aggregation) => [...aggregation, {id: id.toString(), attribute:columns[0],operation:aggregationTypes[0].label, columnAlias: null}]);

    }

    

    removeCountFromAggregationTypes();
    

   

    
  };

  const removeCountFromAggregationTypes  =() => {
    if(aggregationView.find(aggregationView => aggregationView.operation === "COUNT")) {
      
      aggregationTypes = aggregationTypes.filter(aggregationTypes => aggregationTypes.label != "COUNT")
  }
  }
 
  const removeAggregationView = (aggregationViewFilter) => {

    setAggregationView((aggregationViews) =>
      aggregationViews.filter((aggregationView) =>  aggregationView.id !== aggregationViewFilter.id)
    );
    if(aggregationViewFilter.operation === "COUNT") {
      aggregationTypes.push({ label: "COUNT", id: "count" });

    }

    
  };

  const addMatematicFilterView = (id) => {
   
    setMatematicalFilterView((matematicalFilterView) => [
      ...matematicalFilterView,

     
      {id: id, operation:"uguale",attribute:columnsDropped[0], userInput: null },
    ]);
  };

  const removeMatematicFilterView = (matematicalFilter) => {
    setMatematicalFilterView((matematicalFilterViews) =>
      matematicalFilterViews.filter(
        (matematicalFilterView) => matematicalFilterView.id !== matematicalFilter.id
      )
    );
  };

  const addTextualFilterView = (id) => {

    
    setTextualFilterView((textualFilterView) => [...textualFilterView, {id: id, operation:"Uguale A",attribute:columnsDropped[0], userInput: null }]);
  };

  const removeTextualFilterView = (textualFilter) => {
    setTextualFilterView((textualFilterViews) =>
      textualFilterViews.filter((textualFilterView) => textualFilterView.id !== textualFilter.id)
    );
  };


  const addUnionKeysView = (id) => {
    
    setUnionKeysView((textualFilterView) => [...textualFilterView, {id: id, colonnaSelezionataPrimoDataset:columnsFirstUnionDatasetCopy[0],colonnaSelezionataSecondoDataset:columnsSecondUnionDatasetCopy[0]}]);
  };

  const removeUnionKeysView = (unionKeysFilter) => {

    
    setUnionKeysView((unionKeysViews) =>
      unionKeysViews.filter((unionKeysView) => unionKeysView.id !== unionKeysFilter.id)
    );
  };

  const updateUnionKeysView = (id, val,identifier) => {
    
    setUnionKeysView((unionKeysView) =>
      unionKeysView.map((item) =>  {
       

        if(item.id == id) {
          identifier == "Colonne Primo Dataset" ? item.colonnaSelezionataPrimoDataset = val :  item.colonnaSelezionataSecondoDataset= val
          return item;
        }
        return item;
      
      })
        
    );
  };


  const updateMatematicalFilterView = (id, val,identifier) => {

    
    setMatematicalFilterView((matematicalFilterView) =>
      matematicalFilterView.map((item) =>  {
       

        if(item.id == id) {
          
          item[identifier] = val

          return item;
        }
        return item;
      
      })
        
    );
  };


  const updateTextualFilterView = (id, val,identifier) => {
    
    setTextualFilterView((textualFilterView) =>
      textualFilterView.map((item) =>  {
       

        if(item.id == id) {
          
          item[identifier] = val

          return item;
        }
        return item;
      
      })
        
    );
  };

  
  const updateAggregationView = (id, val,identifier) => {
    console.log(id, val,identifier)
    setAggregationView((aggregationView) =>
      aggregationView.map((item) =>  {
       

        if(item.id === id) {
          
          item[identifier] = val

          return item;
        }
        return item;
      
      })
        
    );

    console.log(aggregationView)
  };

  const [resultName, setResultName] = useState('')
  const handleChangeResultName = (val) =>{
    setResultName(val)
  }
  
  const handleChangeUnionKeysView = (val, id, identifier) => {
    

      updateUnionKeysView(id.id ,val, identifier)

      console.log(unionKeysView)


  };

  const handleChangeMatematicalFilterView = (val, id, identifier) => {

    updateMatematicalFilterView(id.id ,val, identifier)

    console.log(matematicalFilterView)


};


const handleChangeTextualFilterView = (val, id, identifier) => {

  

  updateTextualFilterView(id.id ,val, identifier)

  


};


const handleChangeAggregationView = (val, id, identifier) => {

 
 updateAggregationView(id.id ,val, identifier)
 
};




const handleClickEsegui = () => {
  setGeneralLoading(true)
 runRequest(null).then(res => {

  


    console.log(res, "RunRequestADP")
    setGeneralLoading(false)
    if(res != null &&  res.data != null && res.data.length >1) {
      let objPagination = res.data.find(item => item.hasOwnProperty('max_window_num'))

      if(objPagination && objPagination.max_window_num) {
        setObjectPagination(objPagination)

        console.log(objPagination)

      } 

      const dataWithRowNumbers = addRowNumbers(res.data,objPagination.offset);

      setKeysResult(Object.keys(dataWithRowNumbers[0]));

      // Imposta i dati con i numeri di riga
      setResultData(dataWithRowNumbers);
      
      setIsModalResultOpen(true)
    }else if(res !=null && res.data != null && res.data.length === 1) {


      

      toast({
        title: 'Non vi sono dati da mostrare',
        description: "",
        position: "top",
        status: 'info',
        duration: 4000,
        isClosable: true
      });

      
    }
    
   
   
  })
};

const addRowNumbers = (data, startNumber) => {
  return data.filter(data => !data.hasOwnProperty("max_window_num")).map((item, index) => ({
    "Numero Riga": startNumber + index, // Aggiunge il numero di riga partendo da `startNumber`
    ...item
  }));
};

/*const runRequest = async (payloadRequestResult) => {
  try {
    let options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      }
    }

    if(operation == null || operation == "") 
      return
    
    if(payloadRequestResult == null) {
      
      let operationSelect = buttonsOperationSelect.find(btnOperationSelect => btnOperationSelect.label === operation);
      let operationJoin = buttonsOperationJoin.find(btnOperationJoin => btnOperationJoin.label === operation);
  
      const richiesta = {
        operation: operationSelect == null ? operationJoin.id : operationSelect.id,
        dataset: [datasetView],
        attribute: [],
        options: [],
        window: 1
      };
  
      switch (operation) {
        case "Visualizza dataset":
          richiesta.attribute = [];
          richiesta.options = [];

          if(datasetView == null || datasetView == "") 
            return

          break;
        case "Visualizza più colonne":
          richiesta.attribute = columnsDropped;
          richiesta.options = [];

          if(datasetView == null || datasetView == "" || columnsDropped== null || columnsDropped.length ===0) 
            return

          break;
        case "Visualizza dataset con filtro":
          richiesta.attribute = columnsDropped;
          richiesta.options = matematicalFilterView.concat(textualFilterView).map(filter => {
            let operatorMatType = matTypes.find(matType => matType.id === filter.operation);
            let operatorTextType = textTypes.find(textType => textType.id === filter.operation);
            return {
              operation: operatorMatType == null ? operatorTextType.label : operatorMatType.label,
              attribute: filter.attribute,
              userInput: filter.userInput
            };
          });

          if(datasetView == null || datasetView == "" || columnsDropped== null || columnsDropped.length ===0) 
            return



          if(richiesta.options == null || richiesta.options.length === 0 || richiesta.options.some(item => item.userInput == null || item.userInput == "")) {
            return
          }

          break;
        case "Visualizza colonne con raggruppamento":
          richiesta.attribute = columnsDropped;
          richiesta.options = aggregationView.map(filter => {
            return {
              operation: filter.operation,
              attribute: filter.attribute,
              userInput: filter.columnAlias
            };
          });


          if(datasetView == null || datasetView == "" || columnsDropped== null || columnsDropped.length ===0) 
            return

          if(richiesta.options.some(item => item.columnAlias == null || item.columnAlias == "")) {
            return
          }
          

          break;
        case "Unione":
        case "Tutto da Sinistra":
        case "Tutto da Destra":
        case "Combinazioni Complete":
          richiesta.dataset = [firstUnionDataset, secondUnionDataset]
          richiesta.attribute = columnsDroppedFirstUnionDataset.concat(columnsDroppedSecondUnionDataset);
          richiesta.attribute = richiesta.attribute.map(attribute => {
            
            if (columnsDroppedFirstUnionDataset.includes(attribute)) {
                return `${firstUnionDataset}.${attribute}`;
            } else {
                return `${secondUnionDataset}.${attribute}`;
            }
          });
          richiesta.options = unionKeysView.map(unionKeyView => {
            return {
              leftKey: unionKeyView.colonnaSelezionataPrimoDataset,
              rightKey: unionKeyView.colonnaSelezionataSecondoDataset
            };
          });


          if(firstUnionDataset == null || firstUnionDataset == "" || 
            secondUnionDataset == null || secondUnionDataset == "" || 
            columnsDroppedFirstUnionDataset== null || columnsDroppedFirstUnionDataset.length ===0 ||
            columnsDroppedSecondUnionDataset== null || columnsDroppedSecondUnionDataset.length ===0 || unionKeysView.length === 0
          ) 
            return


          break;
        default:
          return null; // Explicitly return null for unhandled operations
      }
  
      
        options.body= JSON.stringify(richiesta)
        setPayloadRequestResult(richiesta);
    }else {
      options.body=  JSON.stringify(payloadRequestResult)
    }
    
    

    const urlFetch = `${react_api}/DragAndDrop/runRequestADP`;
    const response = await fetch(urlFetch, options);
    console.info("response: ", response);

    // Check if the response is OK and return the parsed JSON
    if (response.ok) { // response.ok is a shorthand for checking status 200-299
      return await response.json(); // Await the JSON to get the actual data
    } else {
      console.error("Error: ", response.status, response.statusText);
      return null; // Return null for non-OK responses
    }
  } catch (error) {
    console.error("Error in runRequest: ", error);
    toast({
      title: 'Chiamata in errore',
      description: String(error),
      position: "top",
      status: 'warning',
      duration: 4000,
      isClosable: true
    });
    return null; // Return null in case of an error
  }
};*/

const runRequest = async (payloadRequestResult) => {
  try {
    let options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      }
    }

    if(operation == null || operation == "") 
      return
    
    if(payloadRequestResult == null) {
      
      let operationSelect = buttonsOperationSelect.find(btnOperationSelect => btnOperationSelect.label === operation);
      let operationJoin = buttonsOperationJoin.find(btnOperationJoin => btnOperationJoin.label === operation);
  
      const richiesta = {
        operation: operationSelect == null ? operationJoin.id : operationSelect.id,
        dataset: [datasetView],
        attribute: [],
        options: [],
        window: 1
      };
  
      switch (operation) {
        case "Visualizza dataset":
          richiesta.attribute = [];
          richiesta.options = [];

          if(datasetView == null || datasetView == "") 
            return

          break;
        case "Visualizza più colonne":
          richiesta.attribute = columnsDropped;
          richiesta.options = [];

          if(datasetView == null || datasetView == "" || columnsDropped== null || columnsDropped.length ===0) 
            return

          break;
        case "Visualizza dataset con filtro":
          richiesta.attribute = columnsDropped;


          matematicalFilterView.forEach(filter => {
            let operatorMatType = matTypes.find(matType => matType.id === filter.operation);
            richiesta.options.push({
              operation:  operatorMatType.label,
              attribute: filter.attribute,
              userInput: filter.userInput
            })
           
          })

          
          textualFilterView.forEach(filter => {

            
            let operatorTextType = textTypes.find(textType => textType.id === filter.operation);
            richiesta.options.push({
              operation:  operatorTextType.label,
              attribute: filter.attribute,
              userInput: filter.userInput
            })
           
          })

         
          if(datasetView == null || datasetView == "" || columnsDropped== null || columnsDropped.length ===0) 
            return



          if(richiesta.options == null || richiesta.options.length === 0 || richiesta.options.some(item => item.userInput == null || item.userInput == "")) {
            return
          }

          break;
        case "Visualizza colonne con raggruppamento":
          richiesta.attribute = columnsDropped;
          richiesta.options = aggregationView.map(filter => {

            if(filter.operation === "COUNT") {
              return {
                operation: filter.operation,
                attribute: "*",
                userInput: filter.columnAlias
              };
            }

            return {
              operation: filter.operation,
              attribute: filter.attribute,
              userInput: filter.columnAlias
            };
          });


          if(datasetView == null || datasetView == "" || columnsDropped== null || columnsDropped.length ===0) 
            return

          if(richiesta.options == null || richiesta.options.length === 0 || richiesta.options.some(item => item.userInput == null || item.userInput == "")) {
            return
          }
          

          break;
        case "Unione":
        case "Tutto da Sinistra":
        case "Tutto da Destra":
        case "Combinazioni Complete":
          richiesta.dataset = [firstUnionDataset, secondUnionDataset]
         

          columnsDroppedFirstUnionDataset.forEach(columnsDroppedFirstUnionDataset => {
            richiesta.attribute.push(`${firstUnionDataset}.${columnsDroppedFirstUnionDataset}`)
          })

          columnsDroppedSecondUnionDataset.forEach(columnsDroppedSecondUnionDataset => {
            richiesta.attribute.push(`${secondUnionDataset}.${columnsDroppedSecondUnionDataset}`)
          })
         

        
          richiesta.options = unionKeysView.map(unionKeyView => {
            return {
              leftKey: unionKeyView.colonnaSelezionataPrimoDataset,
              rightKey: unionKeyView.colonnaSelezionataSecondoDataset
            };
          });


          if(firstUnionDataset == null || firstUnionDataset == "" || 
            secondUnionDataset == null || secondUnionDataset == "" || 
            columnsDroppedFirstUnionDataset== null || columnsDroppedFirstUnionDataset.length ===0 ||
            columnsDroppedSecondUnionDataset== null || columnsDroppedSecondUnionDataset.length ===0 || unionKeysView.length === 0
          ) 
            return


          break;
        default:
          return null; // Explicitly return null for unhandled operations
      }
  
      
        options.body= richiesta
        setPayloadRequestResult(richiesta);
    }else {
      options.body= payloadRequestResult
    }
    let body = options.body
    delete  options['body'];



    body.username = sessionStorage.getItem("username")
    const urlFetch = `${react_api}/DragAndDrop/runRequestADP`;
    const response = await axiosInstance.post(urlFetch, body,options);
    console.info("response: ", response);
    
    // Check if the response is OK and return the parsed JSON
    if (response.status.toString() === HTTP_STATUS_CODES.OK) { // response.ok is a shorthand for checking status 200-299
      const result = response.data;
      console.info("query: ", result.query)
      setQuery(result.query);
      return  response.data; // Await the JSON to get the actual data
    } else {
      console.error("Error: ", response.status, response.statusText);
      return null; // Return null for non-OK responses
    }
  } catch (error) {
    console.error("Error in runRequest: ", error);
    toast({
      title: 'Chiamata in errore',
      description: String(error),
      position: "top",
      status: 'warning',
      duration: 4000,
      isClosable: true
    });
    return null; // Return null in case of an error
  }
};


  const handleDrop = (itemId, targetId, object , type) => {
   

    setTextualFilterView([])
    setMatematicalFilterView([])
    setAggregationView([])
    setUnionKeysView([])


    switch(targetId) {
      case "selected_operation":
        if (object == null) {
          return;
        }
  
        if (operation != null) {
          return;
        }
  
        
        setOperation(itemId);
  
        if (buttonsOperationSelect.includes(object)) {
          setOperazioniSelect(
            buttonsOperationSelect.filter(
              (operazioneSelect) =>
                operazioneSelect.label.toLowerCase() !==
                object.label.toLowerCase()
            )
          );
        }
  
        if (buttonsOperationJoin.includes(object)) {
          setOperazioniJoin(
            buttonsOperationJoin.filter(
              (operazioneJoin) =>
                operazioneJoin.label.toLowerCase() !== object.label.toLowerCase()
            )
          );
        }
      break;

      case "selected_dataset":

      if(type !== "dataset") {
        return
      }

      setColumnsDropped([]);
      setColumns([])
      setColumnsCopy([])
      if (object != null) {
        return;
      }
      setDatasetView(itemId);


      setColumnsByTableName(itemId)
       
        break;

        case "selected_attibute":

          if(type !== "column") {
            return
          }

          if (object != null) {
            return;
          }
    
          setColumnsDropped((prevColumnsDropped) => [
            ...prevColumnsDropped,
            itemId,
          ]);
    
          setColumns((prevColumns) =>
            prevColumns.filter((column) => column !== itemId)
          );
        break
          
        case "selected_first_dataset":

        if(!catalog1.map(cat => cat.table_name).includes(itemId)) {
            return
        }
        if(secondUnionDataset === itemId) {
          return
        }



        
        setColumnsDropped([])
        setColumnsFirstUnionDataset([])
        setColumnsFirstUnionDatasetCopy([])
        setColumnsSecondUnionDataset([])
        setColumnsSecondUnionDatasetCopy([])
        
        setColumnsDroppedFirstUnionDataset([])
        setColumnsDroppedSecondUnionDataset([])

        if (object != null) {
          return;
        }
        setFirstUnionDataset(itemId);
        
        setColumnsFirstUnionByTableName(itemId)

        console.log(columnsDroppedFirstUnionDataset)
  
       


        break

        case "selected_second_dataset":

        if(!catalog1.map(cat => cat.table_name).includes(itemId)) {
          return
        }
       
        if(firstUnionDataset === itemId) {
          return
        }

        setColumnsSecondUnionDataset([])
        setColumnsSecondUnionDatasetCopy([])
        setColumnsDroppedSecondUnionDataset([])

        if (object != null) {
          return;
        }
        setSecondUnionDataset(itemId);
  
        setColumnsSecondUnionByTableName(itemId)


        break


        case "selected_attibute_first_dataset":

          

          if(type == null || type === "second") {
            return
          }

          if(catalog1.find(cat => cat.table_name === itemId)) {
            return
          }

          



          setColumnsDroppedFirstUnionDataset((prevColumnsDropped) => [
            ...prevColumnsDropped,
            itemId,
          ]);
    
          setColumnsFirstUnionDataset((prevColumns) =>
            prevColumns.filter((column) => column !== itemId)
          );

          
       
         

        break


        case "selected_attibute_second_dataset":

        if(type == null || type === "first") {
          return
        }
       
          
        if(catalog1.find(cat => cat.table_name === itemId)) {
          return
        }
          
      
    
          setColumnsDroppedSecondUnionDataset((prevColumnsDropped) => [
            ...prevColumnsDropped,
            itemId,
          ]);
    
          setColumnsSecondUnionDataset((prevColumns) =>
            prevColumns.filter((column) => column !== itemId)
          );


         
        break

       default:
        break 

    }


    
  };

  const setColumnsByTableName =(tableName) => {
    catalog1
    .filter((tableInfo) => tableInfo.table_name == tableName)
    .forEach((tableInfo) => {
      
      fetchColumns(tableInfo.table_id).then((columnRes) => {
        let columnNames = columnRes.tableInfo.map(
          (tableInfo) => tableInfo.columnName
        );

        

        setColumns((prevColumns) => [...prevColumns, ...columnNames]);

        setColumnsCopy((prevColumns) => [...prevColumns, ...columnNames]);

       
      });
    });
  }


  const setColumnsFirstUnionByTableName =(tableName) => {
    catalog1
    .filter((tableInfo) => tableInfo.table_name == tableName)
    .forEach((tableInfo) => {
      
      fetchColumns(tableInfo.table_id).then((columnRes) => {
        let columnNames = columnRes.tableInfo.map(
          (tableInfo) => tableInfo.columnName
        );

        

        setColumnsFirstUnionDataset((prevColumns) => [...prevColumns, ...columnNames]);

        setColumnsFirstUnionDatasetCopy((prevColumns) => [...prevColumns, ...columnNames]);

       
      });
    });
  }

  const setColumnsSecondUnionByTableName =(tableName) => {
    catalog1
    .filter((tableInfo) => tableInfo.table_name == tableName)
    .forEach((tableInfo) => {
      
      fetchColumns(tableInfo.table_id).then((columnRes) => {
        let columnNames = columnRes.tableInfo.map(
          (tableInfo) => tableInfo.columnName
        );

        

        setColumnsSecondUnionDataset((prevColumns) => [...prevColumns, ...columnNames]);

        setColumnsSecondUnionDatasetCopy((prevColumns) => [...prevColumns, ...columnNames]);

       
      });
    });
  }




  const IteraSelect = () => {


    setDatasetView();
    setColumnsDropped([])
    setColumns([])
    setColumnsCopy([])
    setColumnsDroppedFirstUnionDataset([])  
    setColumnsDroppedSecondUnionDataset([])  

    setColumnsFirstUnionDataset([])
    setColumnsSecondUnionDataset([])

    setColumnsFirstUnionDatasetCopy([])
    setColumnsSecondUnionDatasetCopy([])

  

    setOperation();
    setActivitySelected("Visualizza")
    setOperazioniSelect(
      buttonsOperationSelect
    );

    setOperazioniJoin([]);
    
    
  };

  const IteraJoin = () => {


    setFirstUnionDataset()
    setSecondUnionDataset()
    setColumnsDroppedFirstUnionDataset([])
    setColumnsDroppedSecondUnionDataset([])

    setColumnsFirstUnionDataset([])
    setColumnsSecondUnionDataset([])

    setColumnsFirstUnionDatasetCopy([])
    setColumnsSecondUnionDatasetCopy([])

    setColumnsDropped([])
    setColumns([])
    setColumnsCopy([])


    
    setOperation();
    setActivitySelected("Unione dataset")
    setOperazioniSelect([]);
    setOperazioniJoin(
      buttonsOperationJoin
    );
  };


  const reInitializaAggregationTypes = () => { 
     aggregationTypes = [
  
    { label: "AVG", id: "avg" },
    { label: "SUM", id: "sum" },
    { label: "MIN", id: "min" },
    { label: "MAX", id: "max" },
    { label: "COUNT", id: "count" }
  ];

}
  const pulisci = () => {
    
    setActivitySelected("");
    setAggregationView([]);
    setColumns([]);
    setColumnsByTableName();
    setColumnsCopy([]);
    setColumnsDropped([]);
    setColumnsDroppedFirstUnionDataset([]);
    setColumnsDroppedSecondUnionDataset([]);
    setColumnsFirstUnionByTableName([]);
    setColumnsFirstUnionDataset([]);
    setColumnsFirstUnionDatasetCopy([]);
    setColumnsSecondUnionByTableName([]);
    setColumnsSecondUnionDataset([]);
    setColumnsSecondUnionDatasetCopy([]);
    setDatasetView();
    setFirstUnionDataset();
    setKeysResult([]);
    setMatematicalFilterView([]);
    setObjectPagination({});
    setOperation();
    setOperazioniJoin([]);
    setOperazioniSelect([]);
    setPayloadRequestResult({});
    setResultData([]);
    setSecondUnionDataset();
    setTextualFilterView([]);
    setUnionKeysView([]);
    
    reInitializaAggregationTypes();
    
   
  };

  return (


    <Box position="relative" height="100vh" width="100%">
    {generalLoading && (
      <Box
        position="absolute"
        top="0"
        left="0"
        width="100%"
        height="100%"
        bg="rgba(255, 255, 255, 0.8)" // Semi-transparent background
        display="flex"
        alignItems="center"
        justifyContent="center"
        zIndex="1000" // Ensure it overlays other content
      >
        <Center>
          <Spinner size="xl" />
        </Center>
      </Box>
    )}
    
    <ChakraProvider>
      <DndProvider backend={HTML5Backend}>
        <Flex direction="column" justifyContent="between" height="100%" pl={0} bgColor={"#f6f3ec"}>
          <Flex direction="row" h="100%" w="100%"  >
            
            <Box
              flex={1}
              border="1px"
              borderColor="gray.200"
              overflow="scroll"
              pl={0}
              pr={0}
            >
             
                    
                  
             
                  
              <Box border="1px" borderColor="gray.200" >
              <Flex direction="row"  justifyContent={"right"} position={"sticky"}>
              <Box w="20%" right={0} position={""} zIndex={0} top={0}>
              <Button
                      id="clean-btn"
                    
                      width="50%"
                      onClick={pulisci}
                      colorScheme="red"
                    >
                      <MdDelete size={24} />
                    </Button>
                    <Button
                      id="run-btn"
                      
                      width="50%"
                      onClick={() =>handleClickEsegui()}
                      colorScheme="green"
                    >
                      <MdOutlinePlayArrow size={24} />
                    </Button></Box >                </Flex >
                <Flex direction="row" mt={4}>
                  <Box w="25%" pr={4}>
                    <Box id="main_operations" mb={4}>
                      <Heading fontSize={"lg"}>Attività Principali</Heading>
                      <Box id="main_operations_content" mt={2}>
                        <Button
                          variant="outline"
                          width="45%"
                          id="select"
                          onClick={IteraSelect}
                          bgColor={activitySelected == "Visualizza" && activitySelected != "" ? "#BDC3C7":  "#0888BE"} 
                        >
                          Visualizza
                        </Button>
                        <Button
                          variant="outline"
                          width="45%"
                          id="join"
                          onClick={IteraJoin}
                          bgColor={activitySelected != "Visualizza" && activitySelected != "" ? "#BDC3C7":  "#0888BE"} 
                        >
                          Unione dataset
                        </Button>
                      </Box>
                    </Box>

                    <Box id="sub_operations" mb={4}>
                      <Heading fontSize={"lg"}>Operazioni</Heading>
                      <Box id="sub_operations_content" mt={2}>
                        {operazioniSelect.length > 0 &&
                          operazioniSelect.map((item) => (
                            <DraggableItem
                              key={item.id}
                              id={{id:item.label}}
                              text={item.label}
                            />
                          ))}
                        {operazioniJoin.length > 0 &&
                          operazioniJoin.map((item) => (
                            <DraggableItem
                              key={item.id}
                              id={{id:item.label}}
                              text={item.label}
                            />
                          ))}
                      </Box>
                    </Box>
                  </Box>
                  <Box flex="4"  overflowY="scroll"  height={{ base: "50vh", md: "70vh", lg: "80vh" }} >
                    <Box  id="work_area" mb={4} margin={1} borderWidth={2} >

                      
                      <Heading fontSize={"lg"}>Area di Lavoro</Heading>
                      <Text id="title-operation"> Operazione</Text>
                      <DroppableArea
                        id="selected_operation"
                        onDrop={handleDrop}
                        
                      >
                        {operation != null ? (
                          <Button
                            key={operation}
                            bg={"green.400"}
                            borderWidth={1}
                            borderColor={"black"}
                            m={1}
                          >
                            {operation}
                            <Icon
                              as={MdDelete} // Change this to the icon you want
                              boxSize={5}
                              onClick={handleIconClickOperazioni} // Add click handler
                              cursor="pointer"
                              ml={2} // Margin left to space the icon from the text
                            />
                          </Button>
                        ):("")}

                      </DroppableArea>
                      
                      {operation != null &&
                      operation.length > 0 &&
                     !buttonsOperationJoin.map(btnOperationJoin => btnOperationJoin.label)
                      .some(label => label == operation )
                      ? (<Box>
                      <Text id="title-dataset">Seleziona Dataset</Text>
                      <DroppableArea id="selected_dataset" onDrop={handleDrop}>
                        {datasetView != null ? (
                          <Button
                            key={datasetView}


                            bg={ getBackgroundColorByVisibility(catalog.find(cat => cat.table_name == datasetView))}
                            borderWidth={1}
                            borderColor={"black"}
                            m={1}
                          >
                            {datasetView}
                            <Icon
                              as={MdDelete} // Change this to the icon you want
                              boxSize={5}
                              // Add click handler
                              onClick={()=> handleIconClickDataset("selected_dataset")}
                              cursor="pointer"
                              ml={2} // Margin left to space the icon from the text
                            />
                          </Button>
                        ):("")}
                      </DroppableArea></Box>):("")
                      }

                      {operation != null &&
                      operation.length > 0 &&
                      operation != "Visualizza dataset" &&
                      !buttonsOperationJoin.map(btnOperationJoin => btnOperationJoin.label)
                      .some(label =>  label == operation )
                      ? (
                        <Box>
                          <Text id="title-column">
                            Seleziona Colonna
                            {columnsDropped.length > 0  ? (
                              <Button
                                bg={"grey.200"}
                                borderWidth={1}
                                borderColor={"black"}
                                m={1}
                                onClick={handleIconClickDeSelectAllColumns}
                              >
                                Deseleziona tutto 
                              </Button>
                            ) : (
                              ""
                            )}

                         

                          </Text>
                          <DroppableArea
                            id="selected_attibute"
                            onDrop={handleDrop}
                          >
                            {columnsDropped.map((item, index) => (
                              <Button
                                key={index}
                                bg={"green.400"}
                                borderWidth={1}
                                borderColor={"black"}
                                m={1}
                              >
                                {item}
                                <Icon
                                  as={MdDelete} // Change this to the icon you want
                                  boxSize={5}
                                  // Add click handler
                                  onClick={() => handleIconClickColumns(item)}
                                  cursor="pointer"
                                  ml={2} // Margin left to space the icon from the text
                                />
                              </Button>
                            ))}
                          </DroppableArea>
                        </Box>
                      ) : (
                        ""
                      )}

                      {operation != null &&
                      operation.length > 0 &&
                      operation ==
                        "Visualizza colonne con raggruppamento" && aggregationView != null && columnsDropped.length >0
                       ? (
                        
                          <Box>
                          <Button
                            bgGradient="linear(to-r, teal.400, blue.500)"
                            color="white"
                            borderWidth={2}
                            borderColor="teal.500"
                            borderRadius="md"
                            m={2}
                            px={4}
                            py={2}
                            _hover={{
                              bgGradient: "linear(to-r, blue.500, teal.400)",
                              transform: "scale(1.05)",
                              boxShadow: "xl",
                            }}
                            _active={{
                              bgGradient: "linear(to-r, teal.500, blue.600)",
                              transform: "scale(0.98)",
                            }}
                            _focus={{
                              outline: "none",
                              boxShadow: "outline",
                            }}
                            onClick={() => addAggregationView(uuidv4())}
                          >
                            Aggiungi colonna Aggregazione
                          </Button>
                          <Box
                            overflow="scroll"
                            maxHeight="300px"
                            mb={4}
                            margin={1}
                            borderWidth={2}
                            borderColor={"black"}
                            bgColor={"lightgoldenrodyellow"}
                          >
                            
                            <Text id="title-operation">
                              {" "}
                              Crea nuova colonna di aggregazione
                            </Text>

                            {aggregationView.map((item, index) => (
                              <Box key={item.id || index}> 

                              {item.operation !== "COUNT" ? (
                                <Box>
                                <Select onChange={(e) => handleChangeAggregationView(e.target.value, item, "attribute" )}>

                                {columnsCopy.filter(col => !columnsDropped.includes(col)).map((option) => (
                                  <option
                                    key={option}
                                    value={option}
                                    >

                                    {option}
                                    </option>
                                  ))}
                                </Select>

<Select

                                
onChange={(e) => handleChangeAggregationView(e.target.value, item, "operation" )}
>
  {aggregationTypes.map((option) => (
    <option
      key={option.label}
      value={option.label}
    >
      {option.label}
    </option>
  ))}
</Select></Box>

                              ): (  

                                getCOUNT()
                              )}
                              

                               

                                <Input 
                                
                                onChange={(e) => handleChangeAggregationView(e.target.value, item, "columnAlias" )}
                                
                                placeholder="Insert column alias" />
                                
                                <Icon
                                  as={MdDelete} // Change this to the icon you want
                                  boxSize={5}
                                  onClick={() => removeAggregationView(item)}
                                  cursor="pointer"
                                  ml="50%" // Margin left to space the icon from the text
                                />
                              </Box>
                            ))}
                          </Box>
                        </Box>
                         
                        
                      ) : (
                        ""
                      )}

                      {operation != null &&
                      operation.length > 0 &&
                      operation == "Visualizza dataset con filtro" && datasetView != null && columnsDropped.length >0  ? (


                      

                        <Box>
                          <Button
                            marginLeft={"20%"}
                            bgGradient="linear(to-r, teal.400, blue.500)"
                            color="white"
                            borderWidth={2}
                            borderColor="teal.500"
                            borderRadius="md"
                            m={2}
                            px={4}
                            py={2}
                            _hover={{
                              bgGradient: "linear(to-r, blue.500, teal.400)",
                              transform: "scale(1.05)",
                              boxShadow: "xl",
                            }}
                            _active={{
                              bgGradient: "linear(to-r, teal.500, blue.600)",
                              transform: "scale(0.98)",
                            }}
                            _focus={{
                              outline: "none",
                              boxShadow: "outline",
                            }}
                     

                            onClick={() => addMatematicFilterView(uuidv4())}
                          >
                            Filtro Matematico
                          </Button>

                          <Button
                            bgGradient="linear(to-r, teal.400, blue.500)"
                            color="white"
                            borderWidth={2}
                            borderColor="teal.500"
                            borderRadius="md"
                            m={2}
                            px={4}
                            py={2}
                            _hover={{
                              bgGradient: "linear(to-r, blue.500, teal.400)",
                              transform: "scale(1.05)",
                              boxShadow: "xl",
                            }}
                            _active={{
                              bgGradient: "linear(to-r, teal.500, blue.600)",
                              transform: "scale(0.98)",
                            }}
                            _focus={{
                              outline: "none",
                              boxShadow: "outline",
                            }}
                           

                            onClick={() => addTextualFilterView(uuidv4())}
                          >
                            Filtro Testuale
                          </Button>

                          <Box
                            overflow="scroll"
                            maxHeight="300px"
                            mb={4}
                            margin={1}
                            borderWidth={2}
                          >
                            
                            <Text id="title-operation">
                              {" "}
                              Crea nuova colonna di aggregazione
                            </Text>

                            {matematicalFilterView.map((item,index) => (
                               <Box key={item.id || index}>
                                <Select
                                
                                onChange={(e) => handleChangeMatematicalFilterView(e.target.value, item, "attribute" )}
                                >
                                  {columnsDropped.map((option) => (
                                    <option
                                      key={option}
                                      value={option}
                                    >
                                      {option}
                                    </option>
                                  ))}
                                </Select>

                                <Select
                                
                                onChange={(e) => handleChangeMatematicalFilterView(e.target.value, item, "operation" )}

                                >
                                  {matTypes.map((option) => (
                                    <option
                                      key={option.id}
                                      value={option.id}
                                    >
                                      {option.id}
                                    </option>
                                  ))}
                                </Select>

                                <Input 
                               type='number'
                                onChange={(e) => handleChangeMatematicalFilterView(e.target.value, item, "userInput" )}
                                placeholder="Insert number" 
                                />

                                <Icon
                                  as={MdDelete} // Change this to the icon you want
                                  boxSize={5}
                                 
                                  onClick={() => removeMatematicFilterView(item)}
                                  
                                  cursor="pointer"
                                  ml="50%" // Margin left to space the icon from the text
                                />
                              </Box>
                            ))}

                            {textualFilterView.map((item, index) => (
                                <Box key={item.id || index}>
                                <Select
                                      onChange={(e) => handleChangeTextualFilterView(e.target.value, item, "attribute" )}
                                >
                                  {columnsDropped.map((option) => (
                                    <option
                                      key={option}
                                      value={option}
                                    >
                                      {option}
                                    </option>
                                  ))}
                                </Select>

                                <Select
                                      onChange={(e) => handleChangeTextualFilterView(e.target.value, item, "operation" )}
                                >
                                  {textTypes.map((option) => (
                                    <option
                                      key={option.id}
                                      value={option.id}
                                    >
                                      {option.id}
                                    </option>
                                  ))}
                                </Select>

                                <Input
                                
                                onChange={(e) => handleChangeTextualFilterView(e.target.value, item, "userInput" )}
                                placeholder="Insert text" />

                                <Icon
                                  as={MdDelete} // Change this to the icon you want
                                  boxSize={5}
                                  onClick={() => removeTextualFilterView(item)}
                                  cursor="pointer"
                                  ml="50%" // Margin left to space the icon from the text
                                />
                              </Box>
                            ))}
                          </Box>
                        </Box>
                      ) : (
                        ""
                      )}


                      {operation != null &&
                      operation.length > 0 &&
                      buttonsOperationJoin.map(btnOperationJoin => btnOperationJoin.label)
                      .some(label => operation == label)  ? (
                        <Box>
                          <Text id="title-dataset">Seleziona Primo Dataset</Text>
                      <DroppableArea id="selected_first_dataset" onDrop={handleDrop}>
                        {firstUnionDataset != null ? (
                          <Button
                            key={firstUnionDataset}
                            bg={"green.400"}
                            borderWidth={1}
                            borderColor={"black"}
                            m={1}
                          >
                            {firstUnionDataset}
                            <Icon
                              as={MdDelete} // Change this to the icon you want
                              boxSize={5}
                              // Add click handler
                              onClick={()=> handleIconClickDataset("selected_first_dataset")}

                              cursor="pointer"
                              ml={2} // Margin left to space the icon from the text
                            />
                          </Button>
                        ): ("")}
                      </DroppableArea>

                      <Text id="title-column">
                            Seleziona Colonne Primo Dataset
                            {columnsDroppedFirstUnionDataset.length > 0 ? (
                              <Button
                                bg={"grey.200"}
                                borderWidth={1}
                                borderColor={"black"}
                                m={1}
                                onClick={handleIconClickDeSelectAllColumnsFirstDataset}
                              >
                                Deseleziona tutto
                              </Button>
                            ) : (
                              ""
                            )}


                      </Text>
                          <DroppableArea
                            id="selected_attibute_first_dataset"
                            onDrop={handleDrop}
                          >
                            {columnsDroppedFirstUnionDataset.map((item, index) => (
                              <Button
                                key={index}
                                bg={"green.400"}
                                borderWidth={1}
                                borderColor={"black"}
                                m={1}
                              >
                                {item}
                                <Icon
                                  as={MdDelete} // Change this to the icon you want
                                  boxSize={5}
                                  // Add click handler
                                  onClick={() => handleIconClickColumnsFirstUnionDataset(item)}
                                  cursor="pointer"
                                  ml={2} // Margin left to space the icon from the text
                                />
                              </Button>
                            ))}
                          </DroppableArea>



                              {columnsDroppedFirstUnionDataset != null && 
                              columnsDroppedFirstUnionDataset.length >0 ? (

                                <Box>
                                <Text id="title-dataset">Seleziona Secondo Dataset</Text>
                                <DroppableArea id="selected_second_dataset" onDrop={handleDrop}>
                                  {secondUnionDataset !=null  ? (
                                    <Button
                                      key={secondUnionDataset}
                                      bg={"green.400"}
                                      borderWidth={1}
                                      borderColor={"black"}
                                      m={1}
                                    >
                                      {secondUnionDataset}
                                      <Icon
                                        as={MdDelete} // Change this to the icon you want
                                        boxSize={5}
                                        // Add click handler
                                        onClick={()=> handleIconClickDataset("selected_second_dataset")}
                                        cursor="pointer"
                                        ml={2} // Margin left to space the icon from the text
                                      />
                                    </Button>
                                  ):("")}
                                </DroppableArea>
          
                                
                                    
          
          
                                    
                                    <Text id="title-column">
                                      Seleziona Colonne Secondo Dataset
                                      {columnsDroppedSecondUnionDataset.length > 0 ? (
                                        <Button
                                          bg={"grey.200"}
                                          borderWidth={1}
                                          borderColor={"black"}
                                          m={1}
                                          onClick={handleIconClickDeSelectAllColumnsSecondDataset}
                                        >
                                          Deseleziona tutto
                                        </Button>
                                      ) : (
                                        ""
                                      )}




                                    </Text>
                                    <DroppableArea
                                      id="selected_attibute_second_dataset"
                                      onDrop={handleDrop}
                                    >
                                      {columnsDroppedSecondUnionDataset.map((item, index) => (
                                        <Button
                                          key={index}
                                          bg={"green.400"}
                                          borderWidth={1}
                                          borderColor={"black"}
                                          m={1}
                                        >
                                          {item}
                                          <Icon
                                            as={MdDelete} // Change this to the icon you want
                                            boxSize={5}
                                            // Add click handler
                                            onClick={() => handleIconClickColumnsSecondUnionDataset(item)}
                                            cursor="pointer"
                                            ml={2} // Margin left to space the icon from the text
                                          />
                                        </Button>
                                      ))}
                                    </DroppableArea>
                                    </Box>
                              ) : ("")
                    
                            }
                          
                         

                          {firstUnionDataset != null && secondUnionDataset != null  && columnsDroppedFirstUnionDataset.length >0 && columnsDroppedSecondUnionDataset.length >0 ? (
                              <Box
                              overflow="scroll"
                              maxHeight={"400px"}
                              mb={4}
                              margin={1}
                              borderWidth={2}
                            >
                              <Text id="title-operation">
                              {" "}
                              Seleziona chiavi di Unione

                              <Icon
                                  as={MdAdd} // Change this to the icon you want
                                  boxSize={5}
                                 
                                  onClick={() => addUnionKeysView(uuidv4())}
                                  
                                  cursor="pointer"
                                  ml="50%" // Margin left to space the icon from the text
                                />
                            </Text>
                            
                            {unionKeysView.map((item, index) => (
                           <Box key={item.id || index}>

                            <Text >Chiave unione {index+1}º </Text>
                                <Select
                                  
                                 
                                  onChange={(e) => handleChangeUnionKeysView(e.target.value, item, "Colonne Primo Dataset" )}


                                >
                                  {columnsFirstUnionDatasetCopy.map((option) => (
                                    <option
                                      key={option}
                                      value={option}
                                    >
                                      {option}
                                    </option>
                                  ))}
                                </Select>

                                <Select
                                      onChange={(e) => handleChangeUnionKeysView(e.target.value, item, "Colonne Secondo Dataset" )}

                                >
                                  {columnsSecondUnionDatasetCopy.map((option) => (
                                    <option
                                    key={option}
                                      value={option}
                                    >
                                      {option}
                                    </option>
                                  ))}
                                </Select>

                               

                                <Icon
                                  as={MdDelete} // Change this to the icon you want
                                  boxSize={5}
                                 
                                  onClick={() => removeUnionKeysView(item)}
                                  
                                  cursor="pointer"
                                  ml="50%" // Margin left to space the icon from the text
                                />
                              </Box>
                            ))}

                          </Box>
                          ): ("")}

                        

                          

                            {textualFilterView.map((item) => (
                              <Box>
                                <Select>
                                  {columns.map((option) => (
                                    <option
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </option>
                                  ))}
                                </Select>

                                <Select>
                                  {textTypes.map((option) => (
                                    <option
                                      key={option.id}
                                      value={option.id}
                                    >
                                      {option.id}
                                    </option>
                                  ))}
                                </Select>

                                <Input placeholder="Insert text" />

                                <Icon
                                  as={MdDelete} // Change this to the icon you want
                                  boxSize={5}
                                  onClick={() => removeTextualFilterView(item)}
                                  cursor="pointer"
                                  ml="50%" // Margin left to space the icon from the text
                                />
                              </Box>
                            ))}
                          </Box>
                        
                      ) : (
                        ""
                      )}

                    </Box>
                  </Box>

               

                  <Box flex="3" borderWidth={2}  overflowY="scroll"
  height={{ base: "50vh", md: "70vh", lg: "80vh" }}   >
                  <Box justifyContent={"space-between"} ><Heading fontSize={"lg"}>Dataset</Heading><Box w="100%" justifyContent={"space-between"}>Legenda:   <Badge colorScheme="green" variant="solid" >Private</Badge><Badge colorScheme="yellow" variant="solid" >Public</Badge><Badge colorScheme="orange" variant="solid" >Obtained</Badge> </Box>
                    </Box>
                    <Box
                      id="data_area_content"
                    
                      direction="column"
                     
                      mt={2}
                      borderRadius="md"

                    >
                      {catalog.length > 0 ? (
                        Array.from(
                          new Set(catalog.map((item) => item.table_name))
                        ).map((uniqueTableName, index) => {
                          const item = catalog.find(
                            (item) => item.table_name === uniqueTableName
                          );
                          return (
                            <DraggableItem
                              key={index}
                              id={{id:uniqueTableName, type: "dataset"}}
                              text={uniqueTableName}
                              visibility={item.visibility}

                              
                            />
                          );
                        })
                      ) : (
                        <Text>Nessun dataset presente...</Text>
                      )}
                    </Box>
                  </Box>

                      {operation != null &&
                      operation.length > 0 &&
                      operation !== "Visualizza dataset" ? (

                        <Box flex="3" borderWidth={2}>
                        <Heading fontSize={"lg"}>
                          Colonne
                          {columns.length > 0 ? (
                            <Button
                              bg={"grey.200"}
                              borderWidth={1}
                              borderColor={"black"}
                              m={1}
                              onClick={handleIconClickSelectAllColumns}
                            >
                              Seleziona tutto
                            </Button>
                          ) : (
                            
                     ""
                          )}


                     


                 


                        </Heading>
                        <Box
                          id="data_area_content"
                          
                          direction="column"
                          height="700px"
                          mt={2}
                          borderRadius="md"
                        >


                          {columns.map((item) => (
                            <DraggableItem key={item} id={{id:item, type: "column"}} text={item} />
                          ))}
                          

                          {columnsFirstUnionDataset.length > 0 ? (

                          <Box borderWidth={1} borderColor="black" borderRadius="md" p={4} mb={4}>
                          <Text fontSize="lg" fontWeight="bold" mb={2} textAlign="center">
                          Colonne Primo dataset
                          </Text>

                            


                          <Flex justifyContent="center">
                            <Button
                            
                              bg={"grey.200"}
                              borderWidth={1}
                              borderColor={"black"}
                              m={1}
                              onClick={handleIconClickSelectAllColumnsFirstDataset}
                            >
                              Seleziona tutto
                            </Button>
                            </Flex>
                        

                          <br></br>
                          <Box overflowY="auto">
                           {columnsFirstUnionDataset.map((item) => (
                            <DraggableItem key={item} id={{id: item, type: "first"}} text={item} />
                            

                          ))}</Box>

                          </Box>): ("")}

                          
                          <br></br>

                          {columnsSecondUnionDataset.length > 0 ? (
                          <Box borderWidth={1} borderColor="black" borderRadius="md" p={4} mb={4}>
                          <Text fontSize="lg" fontWeight="bold" mb={2} textAlign="center">
                        Colonne Secondo dataset
                      </Text>

                      <Flex justifyContent="center">
                            <Button
                              bg={"grey.200"}
                              borderWidth={1}
                              borderColor={"black"}
                              m={1}
                              onClick={handleIconClickSelectAllColumnsSecondDataset}
                            >
                              Seleziona tutto
                            </Button>
                            </Flex>
                            <br></br>

                          

                            <Box overflowY={"auto"}> 
                          {columnsSecondUnionDataset.map((item) => (
                            <DraggableItem key={item} id={{id: item, type: "second"}} text={item} />
                          ))}</Box>
                        </Box>) : (
                            ""
                          )}
                        </Box>
                      </Box>
                      ):("")}

                 
                </Flex>
              
                 


                <Modal onClose={closeResultModal} isOpen={isModalResultOpen} isCentered>
  <ModalOverlay />
  <ModalContent maxW="85%" h="60%">
    <ModalHeader
      bgColor={"#0888BE"}
      color={"white"}
      position="sticky"
      top={0}
      zIndex={10}
    >
      <Flex alignItems="center">
        Catalogo risultati
        <Icon
          as={MdSave}
          boxSize={5}
          onClick={openaResultModal}
          cursor="pointer"
          ml={2}
        />
        {payloadRequestResult.window && objectPagination.max_window_num ? (
          <Box fontSize="15px" paddingLeft={"15px"}>
            Pagina {payloadRequestResult.window}/{objectPagination.max_window_num}{" "}
            <Spacer /> Risultati {objectPagination.offset} -{" "}
            {Math.min(payloadRequestResult.window * 100, objectPagination.totalLen)} di {objectPagination.totalLen}
          </Box>
        ) : (
          ""
        )}
      </Flex>
      <Text fontSize={"lg"} fontWeight={"bold"} color="yellowgreen"></Text>
      <Icon
        as={MdClose}
        boxSize={5}
        onClick={closeResultModal}
        cursor="pointer"
        position="absolute"
        top="8px"
        right="8px"
      />
    </ModalHeader>

    <ModalBody overflow={"auto"} h="100%">
      {resultLoading ? (
        <Center height="600px">
          <Spinner size="xl" />
        </Center>
      ) : (
        <Box>
          <Table variant="striped" size={"sm"}>
            <Thead bgColor={"#0888BE"} position="sticky" top={0} zIndex={9} fontWeight={"bold"}>
              <Tr>
                {keysResult
                  ? keysResult.map((key) => (
                      <Th
                        color={"white"}
                        key={key}
                        position="sticky"
                        top={0}
                        zIndex={9}
                        padding="8px"
                      >
                        {key}
                      </Th>
                    ))
                  : ""}
              </Tr>
            </Thead>
            <Tbody>
              {resultData.length > 0
                ? resultData.slice(0, 100).map((item, index) => (
                    <Tr key={index}>
                      {keysResult.map((key) => (
                        <Td key={key}>{item[key]}</Td>
                      ))}
                    </Tr>
                  ))
                : ""}
            </Tbody>
          </Table>
          <Flex justifyContent="center" alignItems="center" marginTop={4} marginBottom={4}>
            {payloadRequestResult.window > 1 ? (
              <Icon
                as={MdArrowBack}
                boxSize={6}
                onClick={backResult}
                cursor="pointer"
                marginRight={4}
              />
            ) : (
              ""
            )}
            {payloadRequestResult.window < objectPagination.max_window_num ? (
              <Icon
                as={MdArrowForward}
                boxSize={6}
                onClick={nextResult}
                cursor="pointer"
                marginLeft={4}
              />
            ) : (
              ""
            )}
          </Flex>
        </Box>
      )}
    </ModalBody>
  </ModalContent>
</Modal>

<Modal onClose={closeSaveModal} isOpen={isModalSave} >
  <ModalOverlay />
<ModalContent>
    <ModalHeader 
      color={"white"} 
      position="sticky"
      top={0}
      zIndex={10}
      display="flex" justifyContent="space-between" bgColor={"#0888BE"}><Text>Salva Risultato</Text> <CloseButton onClick={closeSaveModal} /></ModalHeader>
    <ModalBody>
      <FormControl>
        <FormLabel>
          Inserisci nome del risultato:
        </FormLabel>
        <Input placeholder="Insert text" onChange={(e) =>handleChangeResultName(e.target.value)}/>
      </FormControl>
    </ModalBody>
    <ModalFooter><Button colorScheme={"green"}onClick={() => salvaRisultato(resultName)}>Submit</Button></ModalFooter>
  </ModalContent>


</Modal>




            
              </Box>
            </Box>
          </Flex>
        </Flex>
      </DndProvider>
    </ChakraProvider>

    {!generalLoading && <Box>Your main content here</Box>}
  </Box>

  
  );
}

export default DragAndDrop;
