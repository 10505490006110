import { Text, Box, Heading, Image, Button, Flex, Grid, GridItem,  Icon  } from "@chakra-ui/react";
import query_tool from './images/querytool.png'
import uploadDataset from "./images/uploadDataset.png"
import BgImage from './images/BgImage.jpg'
import DescriptionCard from "./DescriptionCard";
import { useNavigate} from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";

export default  function HomeGuest() {
    const navigate = useNavigate();
    const goToAmeliaDP = () => {
        sessionStorage.setItem("username", "anonymous");
        navigate('/login')
        window.location.reload()
      }

return (
    <Box h="100%" w="100%" bgColor="#f6f3ec" overflowY="auto">
    <Box h="10%" w="100%" paddingLeft="10px">
      <Heading  fontFamily="inherit" fontWeight="bold" color="green.400">
        Benvenuto in AMELIA
      </Heading>
    </Box>

    <Box
      display="flex"
      w="98%"
      marginLeft={"1%"}
      marginRight={"1%"}
     
      
    >
      {/* Testo a sinistra */}
      <Box flex={1} bg="#003b47" color="white" p={5} borderRadius="lg" margin={"2%"}>
      <Flex justify="space-between" mb={8}>
        <Box maxW="60%">
         
          <Heading as="h2" fontSize="3xl" mb={4}>
            Public Area
          </Heading>
          <Text fontSize="lg">
          Questa è l'area pubblica, qui potrai osservare i dataset e le
          dashboard pubbliche presenti in piattaforma. Effettuando il login
          potrai aver accesso all'intera piattaforma. Amelia è una data
          platform che ti permette di caricare dati in molteplici formati,
          analizzare gli stessi attraverso gli strumenti integrati, come il
          query server, salvare e condividere i tuoi risultati con altri
          utenti tutto nella stessa piattaforma.
        
          </Text>
        </Box>
        {/* Pulsante "Vedi Tutti" */}
       
      </Flex>
        
      </Box>
    </Box>

    <br />

    <Flex w="100%" gap={2}  >
      {/* Prima DescriptionCard */}
      <Box flex={1} marginLeft={"2%"} >
        <DescriptionCard
          title="Query Tool"
          description="Il query tool permette a tutti gli utenti grazie alla tecnologia Drag and Drop di poter costruire query sql semplicemente trascinando i blocchi desiderati."
          ImgUrl={query_tool}
          path = '/query'
        />
      </Box>

      {/* Seconda DescriptionCard */}
      <Box flex={1} marginRight={"2%"}  w="50%">
        <DescriptionCard
          title="Upload Dataset"
          description=""
          ImgUrl={uploadDataset}
          path = '/dataset'
        />
      </Box>
      
    </Flex>
    <Box bg="#003b47" color="white" p={10} borderRadius="lg" margin={"2%"}>
      {/* Titolo principale */}
      <Flex justify="space-between" mb={8}>
        <Box maxW="50%">
          <Text fontSize="sm" color="#b4dbdb" mb={2}>
            ATTIVITA' SULLA PIATTAFORMA
          </Text>
          <Heading as="h2" fontSize="3xl" mb={4}>
            Cosa offre AmeliaDP.
          </Heading>
          <Text fontSize="lg">
          Amelia DP offre una vasta selezione di strumenti per analizzare e studiare i dati. Consente la pubblicazione e l'osservazione di vari tipi di cataloghi attraverso spazi dedicati e fornisce la possibilità di ingerire un'ampia gamma di formati di dati          </Text>
        </Box>
        {/* Pulsante "Vedi Tutti" */}
        <Button variant="outline" borderColor="#b4dbdb" color="#b4dbdb" borderRadius="full" _hover={{ bg: "#b4dbdb", color: "#003b47" }} onClick={goToAmeliaDP}>
          VAI A AMELIADP
        </Button>
      </Flex>

      {/* Griglia con le aree */}
      <Grid templateColumns="repeat(3, 1fr)" gap={6}>
        {/* Card Area 01 */}
        <GridItem bg="white" borderRadius="lg" p={6} color="#003b47" position="relative">
          <Heading as="h4" size="md" mb={4}>
            Tools
          </Heading>
          <Text fontWeight="bold" mb={2}>What can I use?</Text>
          <Text href="#" color="#00686b" mb={1} display="block">
             - Query Tool
          </Text>
          <Text href="#" color="#00686b" mb={1} display="block">
             - Statistical Tool
          </Text>
          <Text href="#" color="#00686b" mb={1} display="block">
             - Jupyter
          </Text>
          <Text href="#" color="#00686b" mb={1} display="block">
             - Zeppelin
          </Text>
          <Text href="#" color="#00686b" mb={1} display="block">
             - Superset
          </Text>
          {/* Icona freccia */}
         
        </GridItem>

        {/* Card Area 02 */}
        <GridItem bg="white" borderRadius="lg" p={6} color="#003b47" position="relative">
          <Heading as="h4" size="md" mb={4}>
            Cataloghi
          </Heading>
          <Text fontWeight="bold" mb={2}>What can I observe?</Text>
          <Text href="#" color="#00686b" mb={1} display="block">
            - Datasets
          </Text>
          <Text href="#" color="#00686b" mb={1} display="block">
            - Staging Area
          </Text>
          <Text href="#" color="#00686b" mb={1} display="block">
            - Risultati
          </Text>
          <Text href="#" color="#00686b" mb={1} display="block">
            - Elaborazioni
          </Text>
          <Text href="#" color="#00686b" mb={1} display="block">
            - Dashboards
          </Text>
          {/* Icona freccia */}
          
        </GridItem>

        {/* Card Area 03 */}
        <GridItem bg="white" borderRadius="lg" p={6} color="#003b47" position="relative">
          <Heading as="h4" size="md" mb={4}>
            Formati di dati supportati
          </Heading>
          <Text fontWeight="bold" mb={2}>What can I upload?</Text>
          <Text href="#" color="#00686b" mb={1} display="block">
            - Shapefile
          </Text>
          <Text href="#" color="#00686b" mb={1} display="block">
            - Csv
          </Text>
          <Text href="#" color="#00686b" mb={1} display="block">
            - Json
          </Text>
          <Text href="#" color="#00686b" mb={1} display="block">
            - Excel
          </Text>
          {/* Icona freccia */}
          
        </GridItem>
      </Grid>
    </Box>
  </Box>
  

)





}   