import {React, useState, useRef,useEffect } from "react";
import { 
  TableContainer,  Tr, Td, Th, Table, Thead, Tbody, Icon, Button, Box, Heading, 
  useToast, Modal, 
  NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
  ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, useDisclosure, Input, Select, Popover, PopoverTrigger, Portal
  ,PopoverContent, PopoverArrow, PopoverHeader,Checkbox,CheckboxGroup, PopoverCloseButton, Stack, FormControl, FormLabel, PopoverFooter, PopoverBody, CloseButton, RadioGroup, Text, Radio, Textarea, HStack,Badge,  Spacer
} from "@chakra-ui/react";
import { MdArrowBack, MdArrowForward, MdUploadFile, MdSearch, MdRestartAlt } from "react-icons/md";
import {  EditIcon, InfoIcon, SearchIcon } from '@chakra-ui/icons';
import { react_api } from "../config";
import { HTTP_STATUS_CODES } from "../GrinsCostant";
import { CASE_TYPE } from "../CaseTypeCostant";
import createAxiosInstance from "../interceptors/useAxiosInterceptors"
import { useSession } from '../SessionContext';
import {   useNavigate} from "react-router-dom";
import logout from "../services/authService";
import { Switch } from '@chakra-ui/react'
import convertUtcToLocal from "../services/utils";
function DatasetPage() {
  const [message, setMessage] = useState("");
  const [datasetList, setDatasetList] = useState([]);
  const [catalogCopy, setCatalogCopy] = useState([]);
  const [scrollBehavior, setScrollBehavior] = useState('inside')
  const categories = [
    'Agricoltura, pesca, silvicoltura e prodotti alimentari',
    'Economia e finanza',
    'Istruzione, cultura e sport',
    'Energia',
    'Ambiente',
    'Governo e settore pubblico',
    'Salute',
    'Tematiche internazionali',
    'Giustizia, sistema giuridico e sicurezza pubblica',
    'Regioni e città',
    'Popolazione e società',
    'Scienza e tecnologia',
    'Trasporti'
  ];

  const [searchDescriptionInput, setSearchDescriptionInput] = useState("");
  const [searchManagerInput, setSearchManagerInput] = useState("");

  const [searchNameInput, setSearchNameInput] = useState("");


  const [searchSelectedValue, setSearchSelectedValue] = useState('name');
  const [selectedSearchVisibilityValues, setSelectedSearchVisibilityValues] = useState([]);

  const [allChecked, setAllChecked] = useState(false);
  const [isIndeterminate, setIsIndeterminate] = useState(false);
  
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [catalog, setCatalog] = useState([]);
  const { isOpen: isUploadModalOpen, onOpen: onUploadModalOpen, onClose: onUploadModalClose } = useDisclosure();

  const fileFormats = [{label: "csv", value: [".csv"]},{label: "xlsx", value:[".xlsx",".xls"]},{label: "shapeFile", value: [".zip",".rar"]}];
  const [selectedFileFormat, setSelectedFileFormat] = useState(fileFormats[0].value);


  const defaultPayloadUploadRequestValue ={version : "1.0", titolo: null, file: null,fileFormat: "csv", is_update: false}

  //Per l'upload del dataset
  const [payloadUploadRequest, setPayloadUploadRequest] = useState(defaultPayloadUploadRequestValue);

  const fileSelectorRef = useRef(null);





  const toast = useToast();
  const [activeIndex, setActiveIndex] = useState(null);
  //const onOpen = (index) => setActiveIndex(index);
  //const onClose = () => setActiveIndex(null);

  const [keys, setKeys] = useState([]);
  const { updateSessionData, clearSessionData} = useSession();

  const navigate = useNavigate();


  const axiosInstance = createAxiosInstance(async (axiosInstance)=> {

    logout(clearSessionData,navigate,toast,axiosInstance)
  });

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= Math.ceil(catalog.length / itemsPerPage)) {
      setCurrentPage(newPage);
    }
  };

  
  const handleChangePayloadRequest = (event, key) => {

    switch(key) {


      case "version":
        setPayloadUploadRequest(payloadUploadRequest => ({
          ...payloadUploadRequest,
          [key]: event
        }));
      break

      case "titolo":

        setPayloadUploadRequest(payloadUploadRequest => ({
          ...payloadUploadRequest,
          [key]: event.target.value 
        }));
      break
      case "fileFormat":
        switch(event.target.selectedOptions[0].label){
          case "csv":
            toast({
              title: 'Informazioni',
              description: " I file csv ammettono come delimitatori solo ',' e ';'",
              status: 'info',
              duration: 4000,
              isClosable: true
            })
            break
          case 'shapeFile':
            toast({
              title: 'Informazioni',
              description: "Gli shapfile devono essere caricati in formato '.zip'",
              status: 'info',
              duration: 4000,
              isClosable: true
            })
        
        
        }
      
        setSelectedFileFormat(event.target.value.split(","))
        setPayloadUploadRequest(payloadUploadRequest => ({
          ...payloadUploadRequest,
          [key]: event.target.selectedOptions[0].label
        }));
        setPayloadUploadRequest(payloadUploadRequest => ({
          ...payloadUploadRequest,
          file: null
        }));

        fileSelectorRef.current.value = ""


      break

      case "file":  

      let file = event.target.files[0]
    
      if (file) {
      const isValidExtension = selectedFileFormat.some(ext => file.name.endsWith(ext));
      if (!isValidExtension) {
        event.target.value = '';
        setPayloadUploadRequest(payloadUploadRequest => ({
          ...payloadUploadRequest,
          file: null
        }));
        return
      } 
      }

      setPayloadUploadRequest(payloadUploadRequest => ({
        ...payloadUploadRequest,
        [key]:  file
      }));

      break


      case "is_update":
        const { checked } = event.target; 
     

      setPayloadUploadRequest(payloadUploadRequest => ({
        ...payloadUploadRequest,
        [key]: checked 
      }));

      break

      default:
      break
    }


    
    

  };
  
  
 

  const handleSelectSearchChange = (event) => {
        
    setSearchSelectedValue(event.target.value);
   
  };

  const handleSearchDescriptionInput = (event) => {
        
    setSearchDescriptionInput(event.target.value);
   
  };

  const handleSearchManagerInput = (event) => {
        
    setSearchManagerInput(event.target.value);
   
  };


  const handleSearchNameInput = (event) => {
        
    setSearchNameInput(event.target.value);
   
  };


  const handleSelectedSearchVisibilityValuesChange = (event) => {

      setSelectedSearchVisibilityValues(event);
     
  };




  const [searchCheckedCategories, setSearchCheckedCategories] = useState(
    Array(categories.length).fill(false))



  const handleCategoryChange = (index) => (e) => {
    const updatedCheckedCategories = [...searchCheckedCategories];
    updatedCheckedCategories[index] = e.target.checked;
    setSearchCheckedCategories(updatedCheckedCategories);
  };

  const handleSelectAllChange = (e) => {
    const isChecked = e.target.checked;
    setSearchCheckedCategories(Array(categories.length).fill(isChecked));
    setAllChecked(isChecked);
    setIsIndeterminate(false);
  };

const [isMetadataModalOpen, setMetadataModalOpen] = useState(false);
const [metadata, setMetadata] = useState();
const [metadataTableName, setMetadataTableName] = useState("");
const [datasetTableName, setDatasetTableName] = useState("");
const [ isEditDatasetModalOpen, setEditDatasetModalOpen] = useState(false);
const [isDatasetModalOpen, setDatasetModalOpen] = useState(false);
const [sharedUsersList, setSharedUsersList] = useState([]);
const [tableId, setTableId] = useState();
const [sharedUserName, setSharedUserName] = useState("");
const handleChengeUsernameUser= (event) => {
  const user= event.target.value
  setSharedUserName(user)
}
const openMetadataModal = (response, tableName) => {
  
  setMetadata(response);
  setMetadataTableName(tableName);
  setMetadataModalOpen(true);
};

const openDatasetModal = (response, tableName) => {
  
  setKeys(Object.keys(response.previewTable[0]))
  setDatasetList(response.previewTable);
  setDatasetTableName(tableName);
  setDatasetModalOpen(true);
};
 
const closeMetadataModal = () => {
  setMetadataModalOpen(false);
};
const closeDatasetModal = () => {
  setDatasetModalOpen(false);
};


const openEditModal = (response,tableName, tableId) => {
  setTableId(tableId);
  setDatasetTableName(tableName);
  setSharedUsersList(response.grantee)
  setEditDatasetModalOpen(true);
};

const closeEditModal = () => {
  setEditDatasetModalOpen(false);
};

 //Funzione per condividere il dataset con altri utenti
 const shareWithUser = async () => {
  try {
    
    if (sharedUserName != null && sharedUserName != ""){
      
    const richiesta = {
      BearerToken: sessionStorage.getItem("token"),
      username: sessionStorage.getItem("username"),
      tableName: datasetTableName,
      userNameForm: sharedUserName
    }
    /*const options = {
     
      headers: {
        'Content-Type': 'application/json',
      },
      
    }*/
    const urlFetch = `${react_api}/privateCatalog/uploadGrantADP`;
    const response = await axiosInstance.post(urlFetch,richiesta, {
     
      headers: {
        'Content-Type': 'application/json',
      },
      
    });
    const result = response.data;
    if (result.status.toString() === HTTP_STATUS_CODES.OK) {
    
      toast({
        title: 'Condivisione avventa con successo',
        description: "hai condiviso il dataset con " + sharedUserName,
        status: 'info',
        duration: 4000,
        isClosable: true
      });

      closeEditModal();
    
    } else {
      throw new Error('Errore nel recupero dei dati');
    }}else{
      toast({
        title: 'Nome utente vuoto',
        description: "Inserire il nome dell'utente con cui si voglia condividere il dataset",
        status: 'warning',
        duration: 4000,
        isClosable: true
      });
    }
  
  } catch (error) {
    toast({
      title: 'Chiamata in errore',
      description: String(error),
      status: 'warning',
      duration: 4000,
      isClosable: true
    });
  }
}

//Funzione per pubblicare il dataset 
const publishDataset = async () => {

  try {
    const richiesta = {
      BearerToken: sessionStorage.getItem("token"),
      username: sessionStorage.getItem("username"),
      tableName: datasetTableName,
      tableId: tableId
    }
    const options = {
     
      headers: {
        'Content-Type': 'application/json',
      },
      
    }
    const urlFetch = `${react_api}/privateCatalog/datasetPublicationADP`;
    const response = await axiosInstance.post(urlFetch,richiesta, options);
 
    const result = response.data;
    if (result.status.toString() === HTTP_STATUS_CODES.OK) {
      
      toast({
        title: 'Pubblicazione avvenuta con successo',
        description: response.data.message,
        status: 'info',
        duration: 4000,
        isClosable: true
      });

      closeEditModal()
    
    } else {
      throw new Error('Errore nel recupero dei dati');
    }
  
  } catch (error) {
    toast({
      title: 'Chiamata in errore',
      description: String(error),
      status: 'warning',
      duration: 4000,
      isClosable: true
    });
  }
}


const getSharedUsers = async (tableName, tableId) => {
  try {
    const richiesta = {
      BearerToken: sessionStorage.getItem("token"),
      username: sessionStorage.getItem("username"),
      tableId: tableId
    }
    const options = {
     
      headers: {
        'Content-Type': 'application/json',
      },
      
    }
    const urlFetch = `${react_api}/privateCatalog/getTableGranteeADP`;
    const response = await axiosInstance.post(urlFetch,richiesta, options);
   
    const result = response.data;
    if (result.status.toString() === HTTP_STATUS_CODES.OK) {
      
      openEditModal(response.data, tableName, tableId)
     
    
    } else {
      throw new Error('Errore nel recupero dei dati');
    }
  
  } catch (error) {
    toast({
      title: 'Chiamata in errore',
      description: String(error),
      status: 'warning',
      duration: 4000,
      isClosable: true
    });
  }



}


const visualizzaMetadati = async (tableId, tableName) => {
  try {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      
    }
    const urlFetch = `${react_api}/getMetadataADP/${tableId}`;
    const response = await axiosInstance.get(urlFetch, options);
   
    if (response.status.toString() === HTTP_STATUS_CODES.OK) {
      openMetadataModal(response.data, tableName);
    
    } else {
      throw new Error('Errore nel recupero dei dati');
    }
  
  } catch (error) {
    toast({
      title: 'Chiamata in errore',
      description: String(error),
      status: 'warning',
      duration: 4000,
      isClosable: true
    });
  }
};


const visualizzaDataset = async (TBL_NAME) => {
  try {
    const body =  {
      username: sessionStorage.getItem("username"),
      tableName: TBL_NAME,
    };
    const options = {
      
      headers: {
        'Content-Type': 'application/json',
        'Authorization': sessionStorage.getItem("token")
      },
      
    }
    const urlFetch = `${react_api}/getTableADP`;
    const response = await axiosInstance.post(urlFetch, body, options);
    const data =  response.data;
   

    if (response.status.toString() === HTTP_STATUS_CODES.OK) {
      
      openDatasetModal(data, TBL_NAME)
      
      
    } else {
      throw new Error('Errore nel recupero dei dati');
    }
  } catch (error) {
    toast({
      title: 'Chiamata in errore',
      description: String(error),
      status: 'warning',
      duration: 4000,
      isClosable: true
    });
  }
};
  useEffect(() => {
 
    
    const allChecked = searchCheckedCategories.every(Boolean);
    const someChecked = searchCheckedCategories.some(Boolean);
    setAllChecked(allChecked);
    setIsIndeterminate(!allChecked && someChecked);

    
const fetchCatalog = async () => {
  try {
    const richiesta = {
      BearerToken: sessionStorage.getItem("token"),
      username: sessionStorage.getItem("username"),
      caseType: CASE_TYPE.CATALOG_ALL,
    };

    const urlFetch = `${react_api}/privateCatalog/getCatalogADP`;

    // Make the POST request using Axios
    const response = await axiosInstance.post(urlFetch, richiesta, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const result = response.data; 
    if (result.status === HTTP_STATUS_CODES.OK) {

    
      setCatalog(result.catalog.filter(cat => cat.visibility !== 'null'));
      setCatalogCopy(result.catalog.filter(cat => cat.visibility !== 'null'));
    } else {
      toast({
        title: 'Tabella dei risultati vuota',
        description: String(result.message),
        position: "top",
        status: 'info',
        duration: 4000,
        isClosable: true,
      });
      setMessage(result.message);
    }
  } catch (error) {
    // Handle error response
    if (error.response) {
      // The request was made and the server responded with a status code
      toast({
        title: 'Chiamata in errore',
        description: String(error.response.data.message || error.response.statusText),
        position: "top",
        status: 'warning',
        duration: 4000,
        isClosable: true,
      });
    } else if (error.request) {
      // The request was made but no response was received
      toast({
        title: 'Chiamata in errore',
        description: 'Nessuna risposta dal server.',
        position: "top",
        status: 'warning',
        duration: 4000,
        isClosable: true,
      });
    } else {
      // Something happened in setting up the request that triggered an Error
      toast({
        title: 'Chiamata in errore',
        description: String(error.message),
        position: "top",
        status: 'warning',
        duration: 4000,
        isClosable: true,
      });
    }
  }
};
    fetchCatalog();
  }, [searchCheckedCategories]);
  

  const applyFilterDataset = () => {

    function updatePaginatedDate() {
        setCurrentPage(1)

       paginatedData =catalogFiltered.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
       totalPages = Math.ceil(catalogFiltered.length / itemsPerPage);

    }
    let catalogFiltered = catalogCopy
    if(selectedSearchVisibilityValues != null && selectedSearchVisibilityValues.length > 0 )  {
    
      catalogFiltered = catalogFiltered.filter(pageData => selectedSearchVisibilityValues.includes(pageData.visibility))
      updatePaginatedDate()
    }

    if(searchCheckedCategories != null && searchCheckedCategories.length >0) {

      let categoriesFilter = []
      searchCheckedCategories.forEach((value, index) => {
      if(value)
      categoriesFilter.push(categories[index])
      });
      if(categoriesFilter != null && categoriesFilter.length >0) {
        
        catalogFiltered =  catalogFiltered.filter(pageData =>
            pageData.category.length > 0 && pageData.category.some(cat => categoriesFilter.includes(cat))
        );
        updatePaginatedDate()
      }
       
    }


    if(searchNameInput != null && searchNameInput.length >0 ) {
     
     
      catalogFiltered =catalogFiltered.filter(pageData => pageData.TBL_NAME.toLowerCase() === searchNameInput.toLowerCase() ||  pageData.TBL_NAME.toLowerCase().includes(searchNameInput.toLowerCase()))
      updatePaginatedDate()

    
    }

 
    if(searchDescriptionInput != null && searchDescriptionInput.length >0 ) {
     
   
      catalogFiltered =catalogFiltered.filter(pageData => pageData.description.toLowerCase() === searchDescriptionInput.toLowerCase() ||  pageData.description.toLowerCase().includes(searchDescriptionInput.toLowerCase()))
      updatePaginatedDate()

    
    }

    if(searchManagerInput != null  && searchManagerInput.length >0 ) {
     
      catalogFiltered =catalogFiltered.filter(pageData => pageData.OWNER.toLowerCase() === searchManagerInput.toLowerCase() || pageData.OWNER.toLowerCase().includes(searchManagerInput.toLowerCase())  )
      updatePaginatedDate()
    }

    setCatalog(catalogFiltered)

  };

  const resetFilterDataset= () => {
    setCatalog(catalogCopy)
    setCurrentPage(1)
    paginatedData =catalog.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
    totalPages =Math.ceil(catalog.length / itemsPerPage);
    setSelectedSearchVisibilityValues([])
    setSearchCheckedCategories(Array(categories.length).fill(false))
    setSearchDescriptionInput("")
    setSearchManagerInput("")
    setSearchNameInput("")
    setSearchSelectedValue("name")
   
  } 
 



  const uploadDataset = async () => {


    if(payloadUploadRequest.titolo != null && payloadUploadRequest.titolo.length >0 &&
      payloadUploadRequest.file != null &&
      payloadUploadRequest.fileFormat != null 
    ) {
      try {
       
   
      const formData = new FormData();
      formData.append("username",sessionStorage.getItem("username"))

      Object.keys(payloadUploadRequest).forEach(payloadUploadRequestKey => {
        formData.append(payloadUploadRequestKey, payloadUploadRequest[payloadUploadRequestKey]);
      })
  
        const options = {
         
          headers: {
           
            "Authorization": sessionStorage.getItem("token")
          },
          
        }
        const urlFetch = `${react_api}/privateServices/uploadDataADP`;
        const response = await axiosInstance.post(urlFetch,formData, options);
        const result = response.data;


        switch(result.status){

          case HTTP_STATUS_CODES.OK:
            
          toast({
              title: result.message,
              description: "",
              status: 'info',
              duration: 4000,
              isClosable: true
            });
  
            onUploadModalClose()
  
            setPayloadUploadRequest(defaultPayloadUploadRequestValue)
          break

          case HTTP_STATUS_CODES.BAD_REQUEST :


              toast({
                title: result.message,
                description: "",
                status: 'warning',
                duration: 4000,
                isClosable: true
              });
            
            
          

           break 


          default:
            throw new Error('Errore nel recupero dei dati');
        }


        
      
      } catch (error) {
        toast({
          title: 'Chiamata in errore',
          description: String(error),
          status: 'warning',
          duration: 4000,
          isClosable: true
        });
      }
    }
    
    
  
  
  
  }
 
  
 
  

  var paginatedData = catalog.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
  var totalPages = Math.ceil(catalog.length / itemsPerPage);
  const startIndex = currentPage * itemsPerPage - 9;
  const endIndex =  Math.min((currentPage ) * itemsPerPage, catalog.length);
  return (
    <>
      <Box alignItems={"center"} justifyContent={"space-between"} display={"flex"} color={"rgba(11,78,90,255)"} bgColor={"#f6f3ec"} ><Heading>Datasets</Heading>
      <Button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} bg={"#f6f3ec"}><MdArrowBack /></Button>
          <Box>Pagina {currentPage}/{totalPages} <Spacer /> Dataset {startIndex} - {endIndex} di {catalog.length}  </Box>
          <Button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage * itemsPerPage >= catalog.length}  bg={"#f6f3ec"} ><MdArrowForward /></Button>
          
          <Box >
          <Popover >  
            <PopoverTrigger>
              <Button  color={"rgba(11,78,90,255)"} bgColor={"#f6f3ec"} h={"100%"}><Icon as={MdSearch}/>Cerca</Button>
            </PopoverTrigger>
            <Portal>
              <PopoverContent bgColor={"white"} borderWidth={2} width="100%" borderColor={"#0888BE"}>
                <PopoverArrow />
                <PopoverHeader color={"rgba(11,78,90,255)"} fontSize='lg' fontWeight='semibold'>Cerca nella tabella
                <Button  ml={4}
                  
                  leftIcon={<Icon as={MdRestartAlt} fontSize="2xl" />} // Set the icon to the left of the text
                  colorScheme="teal" // Adjust the color scheme as needed
                  variant="solid" // Adjust the button variant
                  onClick={resetFilterDataset} // Attach the onClick handler
                  >Reset
                </Button>
                </PopoverHeader>
                <PopoverCloseButton color={"rgba(11,78,90,255)"} />
                <PopoverBody>
                <Stack spacing='4'>
                <Box>
                    <FormControl>
                    <FormLabel fontSize='lg' color="rgba(11,78,90,255)" fontWeight='semibold'>Filtro:</FormLabel>
                    <Select
                      value={searchSelectedValue}
                      onChange={handleSelectSearchChange}
                      variant="filled"
                    >

                       <option value='name' variant="filled" defaultChecked>Nome</option>
                       <option value='category' variant="filled" >Categoria</option>
                       <option value='description' variant="filled">Descrizione</option>
                       <option value='manager' variant="filled" >Manager</option>
                       <option value='visibility' variant="filled">Visibilità</option>

                       
    
                    </Select>
                    </FormControl>
                    </Box>
                    <Box>
                   

                      
                    {searchSelectedValue === "visibility"  ?  
                       
                       
                       (<CheckboxGroup colorScheme='green' 
                     value={selectedSearchVisibilityValues}
                     onChange={handleSelectedSearchVisibilityValuesChange}
                     >
                       <Stack spacing={[1, 5]} direction={['column', 'row']}>
                         <Checkbox value='private'>PRIVATA</Checkbox>
                         <Checkbox value='public'>PUBBLICA</Checkbox>
                         <Checkbox value='obtained'>OTTENUTA</Checkbox>
                         <Checkbox value='general'>GENERALE</Checkbox>
                       </Stack>
                     </CheckboxGroup>
                   ): (
   
                     searchSelectedValue === "category"  ?  (
                       <Box>
                       <Checkbox
                       isChecked={allChecked}
                       isIndeterminate={isIndeterminate}
                       onChange={handleSelectAllChange}
                     >
                         Seleziona/Deseleziona Tutti
                     </Checkbox>
                     <Stack pl={6} mt={1} spacing={1}>
   
                    
   
                       {categories.map((category , index)=> (
            
                       
                       <Checkbox
                         isChecked={searchCheckedCategories[index]}
                         onChange={handleCategoryChange(index)}
                       >
                         {category}
                       </Checkbox>
                      
   
                      ))}
                     </Stack>
                   </Box>
   
                     ) 
                     
                     :(
                      
                       searchSelectedValue === "description"  ?  (
                           
                           <Box><FormLabel fontSize='lg' color="rgba(11,78,90,255)" fontWeight='semibold'>Inserisci una descrizione:</FormLabel>
                           <Input variant='filled' placeholder='value' name='valueForSearch' type='text' bg='white'  value={searchDescriptionInput}   onChange={handleSearchDescriptionInput}  /></Box>
                               
                       ) : (


                        searchSelectedValue === "name"  ?  (

                          <Box><FormLabel fontSize='lg' color="rgba(11,78,90,255)" fontWeight='semibold'>Inserisci un nome di dataset:</FormLabel>
                           <Input variant='filled' placeholder='value' name='valueForSearch' type='text' bg='white' value={searchNameInput}   onChange={handleSearchNameInput}   /></Box>
                        ): (
                          <Box><FormLabel fontSize='lg' color="rgba(11,78,90,255)" fontWeight='semibold'>Inserisci un manager:</FormLabel>
                           <Input variant='filled' placeholder='value' name='valueForSearch' type='text' bg='white' value={searchManagerInput}   onChange={handleSearchManagerInput}   /></Box>
                        )

                           
                          
                          )
                     
                    
   
                     
                       )
                      )
                       }
   
                  
                    </Box>
                    </Stack>
                  
                </PopoverBody>
                <PopoverFooter><Button colorScheme='green' onClick={applyFilterDataset} ><Icon as={MdSearch } />Cerca</Button></PopoverFooter>
              </PopoverContent>
            </Portal>
          </Popover>
          <Button color={"rgba(11,78,90,255)"}  bgColor={"#f6f3ec"} h={"100%"} onClick={()=> {onUploadModalOpen();  setPayloadUploadRequest(defaultPayloadUploadRequestValue)}}><Icon as={MdUploadFile} />Carica Dataset</Button>
          </Box>
</Box>
      

      <Box   overflowY="auto" direction="column" h="100%" w="100%" boxShadow="lg">
      
        <TableContainer px={"1%"} paddingTop={"1%"}>
          <Table variant='striped' w="100%">
            <Thead bgColor={"#0888BE"} position="sticky" top="0">
              <Tr>
                
                <Th  align="left" w={"20%"}  color={"white"}>Datasets</Th>
                <Th  color={"white"} w={"15%"} whiteSpace={"normal"} wordBreak={"break-word"}>Categoria</Th>
                <Th  color={"white"} w={"25%"} whiteSpace={"normal"} wordBreak={"break-word"}>Descrizione</Th>
                <Th  color={"white"} w={"15%"} whiteSpace={"normal"} wordBreak={"break-word"}>Manager</Th>
                <Th  color={"white"} w={"10%"} whiteSpace={"normal"} wordBreak={"break-word"}>Visibilità</Th>
                <Th  color={"white"} w={"15%"}>Azioni</Th>
              </Tr>
            </Thead>
            <Tbody>
              {catalog.length > 0 ? (
                paginatedData.map((item, index) => {
                
                //const categorie = item.category.split("\\"); 
                return (
                  
                <Tr key={index}>
                  <Td  whiteSpace={"normal"} wordBreak={"break-word"} >{item.TBL_NAME}</Td>
                  <Td  whiteSpace={"normal"} wordBreak={"break-word"}>{item.category}</Td>
                  <Td  whiteSpace={"normal"} wordBreak={"break-word"} >{item.description}</Td>
                  <Td  whiteSpace={"normal"} wordBreak={"break-word"} >{item.OWNER}</Td>
                  <Td  whiteSpace={"normal"} wordBreak={"break-word"}>  <Badge variant='solid' colorScheme={item.visibility === "private" ? ("green" ) : (item.visibility === "public" ? ("yellow"):(item.visibility === "obtained" ? ("orange") : ("blue")))}>
                  {item.visibility}
                  </Badge></Td>
                
                  <Td w="15%" maxW="15%" overflow="hidden">
                  <HStack align={"center"}  wrap="wrap">
          <Icon as={InfoIcon} onClick={() =>visualizzaMetadati( item.tableId ,item.TBL_NAME)} _hover={{color: "#0888BE"}} />
          {item.visibility === "public" || item.visibility === "general" ? ( <Icon as={SearchIcon} _hover={{color: "#0888BE"}} onClick={() =>visualizzaDataset(item.TBL_NAME)}/>):
            (item.visibility === "private"? (item.OWNER === sessionStorage.username ? 
              (<><Icon as={SearchIcon} _hover={{color: "#0888BE"}} onClick={() =>visualizzaDataset(item.TBL_NAME)}/><Icon as={EditIcon} _hover={{color: "#0888BE"}} onClick={() =>getSharedUsers(item.TBL_NAME, item.tableId)}/></>) : 
                ("")) : 
              (item.visibility === "obtained" ? (<Icon as={SearchIcon} _hover={{color: "#0888BE"}} onClick={() =>visualizzaDataset(item.TBL_NAME)}/>) : (""))
              )} </HStack>
        
          
                  </Td>
                </Tr>);})) : (<Tr><Text fontSize={"lg"} fontWeight={"bold"}>{message}</Text></Tr>)}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
      <Modal  onClose={closeMetadataModal} isOpen={isMetadataModalOpen} isCentered  scrollBehavior={scrollBehavior}>
      <ModalOverlay />
      <ModalContent maxW="60%" h="80%">
        <ModalHeader bgColor={"#0888BE"} color="white" display="flex" justifyContent="space-between"><Box>Vista Metadati del dataset: <Text fontSize={"lg"} fontWeight={"bold"} color="yellowgreen">{metadataTableName}</Text></Box><Box><CloseButton onClick={closeMetadataModal} /></Box></ModalHeader>
        
        
            {metadata ? (

              <ModalBody>
                <Box overflowY="auto" >
               <Table size="sm" variant='striped' w={"100%"} h={"100%"}>
               <Thead fontFamily={"-moz-initial"} bg={"#0888BE"} position="sticky" top="0" zIndex={1}>
                 <Tr>
                   <Th  color={"black"}>Id del dataset</Th>
                   <Th  color={"black"}>Titolo del dataset</Th>
                   <Th  color={"black"}>Nome della tabella</Th>
                   <Th  color={"black"}>Nomdel del file raw</Th>
                 </Tr>
               </Thead>
              <Tbody>
                <Tr>
                  
                  <Td  color={"black"} whiteSpace={"normal"} wordBreak={"break-word"}>{metadata.dataset_id == null ? ("-") : (metadata.dataset_id)}</Td>
               
                  <Td  color={"black"} whiteSpace={"normal"} wordBreak={"break-word"}>{metadata.title == null ? ("-") : (metadata.title)}</Td>
                
                  <Td  color={"black"} whiteSpace={"normal"} wordBreak={"break-word"}>{metadata.table_name == null ? ("-") : (metadata.table_name)}</Td>
               
                  <Td  color={"black"} whiteSpace={"normal"} wordBreak={"break-word"}>{metadata.file_name == null ? ("-") : (metadata.file_name)}</Td>
                </Tr>
                </Tbody>
           
          </Table> <br />
          <Table size="sm" variant='striped' w={"100%"} h={"100%"}>
               <Thead fontFamily={"-moz-initial"} bg={"#0888BE"} position="sticky" top="0" zIndex={1}>
                 <Tr>
                   <Th  color={"black"}>Data Manager</Th>
                   <Th  color={"black"}>Proprietario</Th>
                   <Th  color={"black"}>Editore</Th>
                   
                 </Tr>
               </Thead><Tbody>
                <Tr>
                  
                  <Td  color={"black"} whiteSpace={"normal"} wordBreak={"break-word"}>{metadata.data_manager == null ? ("-") : (metadata.data_manager)}</Td>
                
                  <Td  color={"black"} whiteSpace={"normal"} wordBreak={"break-word"}>{metadata.owner == null ? ("-") : (metadata.owner)}</Td>
             
                  <Td  color={"black"} whiteSpace={"normal"} wordBreak={"break-word"}>{metadata.publisher == null ? ("-") : (metadata.publisher)}</Td>
                  
                </Tr> </Tbody>
           
           </Table> <br />

           <Table size="sm" variant='striped' w={"100%"} h={"100%"}><Thead fontFamily={"-moz-initial"} bg={"#0888BE"}><Tr><Th color={"black"}>Descrizione</Th></Tr></Thead>
           <Tbody><Tr><Td>{metadata.description}</Td></Tr></Tbody></Table><br />

           <Table size="sm" variant='striped' w={"100%"} h={"100%"}><Thead fontFamily={"-moz-initial"} bg={"#0888BE"}> <Tr>
                   <Th  color={"black"}>Data di rilacio</Th>
                   <Th  color={"black"}>Data ultima modifica</Th>
                   <Th  color={"black"}>Data di creazione</Th>
                   
                 </Tr></Thead><Tbody>
            <Tr>    
                  <Td   whiteSpace={"normal"} wordBreak={"break-word"}>{metadata.release_date == null ? ("-") : ( convertUtcToLocal(metadata.release_date))}</Td>
               
                  <Td  color={"black"} whiteSpace={"normal"} wordBreak={"break-word"}>{metadata.last_modified_date == null ? ("-") : (convertUtcToLocal(metadata.last_modified_date))}</Td>
               
                  <Td  color={"black"} whiteSpace={"normal"} wordBreak={"break-word"}>{metadata.creation_date == null ? ("-") : (metadata.creation_date)}</Td>
                </Tr></Tbody></Table>
                <br />
           <Table size="sm" variant='striped' w={"100%"} h={"100%"}>
           <Thead fontFamily={"-moz-initial"} bg={"#0888BE"} >
                 <Tr>
                   <Th  color={"black"}>Campo</Th>
                   <Th   color={"black"}>Valore</Th>
                 </Tr>
               </Thead><Tbody>
                <Tr>
                  <Td  color={"black"}>Id esterno</Td>
                  <Td  color={"black"} whiteSpace={"normal"} wordBreak={"break-word"}>{metadata.external_id == null ? ("-") : (metadata.external_id)}</Td>
                </Tr>
                
                <Tr>
                  <Td  color={"black"}>Versione Interna</Td>
                  <Td  color={"black"} whiteSpace={"normal"} wordBreak={"break-word"}>{metadata.version == null ? ("-") : (metadata.internal_version)}</Td>
                </Tr>
                <Tr>
                  <Td  color={"black"}>Versione Esterna</Td>
                  <Td  color={"black"} whiteSpace={"normal"} wordBreak={"break-word"}>{metadata.version == null ? ("-") : (metadata.external_version)}</Td>
                </Tr>
                <Tr>
                  <Td  color={"black"}>Url</Td>
                  <Td  color={"black"} whiteSpace={"normal"} wordBreak={"break-word"}>{metadata.url == null ? ("-") : (metadata.url)}</Td>
                </Tr>
                <Tr>
                  <Td  color={"black"}>Id di riferimento</Td>
                  <Td  color={"black"} whiteSpace={"normal"} wordBreak={"break-word"}>{metadata.referenced_id == null ? ("-") : (metadata.referenced_id)}</Td>
                </Tr>
                <Tr>
                  <Td  color={"black"}>Visibilità</Td>
                  <Td  color={"black"} whiteSpace={"normal"} wordBreak={"break-word"}>{metadata.visibility == null ? ("-") : (metadata.visibility)}</Td>
                </Tr>
                <Tr>
                  <Td  color={"black"}>Encoding</Td>
                  <Td  color={"black"} whiteSpace={"normal"} wordBreak={"break-word"}>{metadata.encoding == null ? ("-") : (metadata.encoding)}</Td>
                </Tr>
                <Tr>
                  <Td  color={"black"}>Provenioente da elaborazione?</Td>
                  <Td  color={"black"} whiteSpace={"normal"} wordBreak={"break-word"}>{metadata.is_processed == null ? ("-") : (metadata.is_processed)}</Td>
                </Tr>
                <Tr>
                  <Td  color={"black"}>Nome tag</Td>
                  <Td  color={"black"} whiteSpace={"normal"} wordBreak={"break-word"}>{metadata.tag_name == null? ("-"): (metadata.tag_name)}</Td>
                </Tr>
                <Tr>
                  <Td color={"black"} >Nome licenza</Td>
                  <Td color={"black"}  whiteSpace={"normal"} wordBreak={"break-word"}>{metadata.licence_name == null ? ("-") : (metadata.licence_name)}</Td>
                </Tr>
                <Tr>
                  <Td color={"black"}>Latitudine</Td>
                  <Td color={"black"}  whiteSpace={"normal"} wordBreak={"break-word"}>{metadata.latitude == null ? ("-") : (metadata.latitude)}</Td>
                </Tr>
                <Tr>
                  <Td color={"black"}>Longitudine</Td>
                  <Td color={"black"}  whiteSpace={"normal"} wordBreak={"break-word"}>{metadata.longitude == null ? ("-") : (metadata.longitude)}</Td>
                </Tr>
                <Tr>
                  <Td color={"black"}>Categorie</Td>
                  <Td color={"black"}  whiteSpace={"normal"} wordBreak={"break-word"}>{metadata.categories == null ? ("-") : (metadata.categories.toString())}</Td>
                </Tr>
              </Tbody>
           
          </Table></Box> </ModalBody>) : null}
        
      </ModalContent>
    </Modal>
    <Modal onClose={closeDatasetModal} isOpen={isDatasetModalOpen} isCentered   >
      <ModalOverlay />
      <ModalContent maxW="60%" h="60%">
        <ModalHeader bgColor={"#0888BE"} color="white" display="flex" justifyContent="space-between"><Box>Vista del dataset: <Text fontSize={"lg"} fontWeight={"bold"} color="yellowgreen">{datasetTableName}</Text></Box><Box><CloseButton onClick={closeDatasetModal} /> </Box></ModalHeader>
        <ModalBody>
          <Box overflow="auto"  >
        <Table size={"sm"}  variant='striped' w={"100%"} h={"100%"} >
        <Thead  bgColor={"#0888BE"}>
          <Tr >
            {keys ? (
            keys.map((key) => (
              <Th color="white" key={key} >{key}</Th> // Renderizza le chiavi come intestazioni di colonna
            ))):("")}
          </Tr>
        </Thead>
        <Tbody>
        {datasetList.length > 0 ?
          (datasetList.map((item, index) => (
            <Tr key={index}>
              {keys.map((key) => (

                <Td   key={key}>{ 
                  key.toLowerCase().includes("date") || 
                  key.toLowerCase().includes("data") || 
                  key.toLowerCase().includes("ora") ||
                  key.toLowerCase().includes("hour") ||
                  key.toLowerCase().includes("hours") ||
                  key.toLowerCase().includes("time") 
                  ?  convertUtcToLocal(item[key]) : item[key] }</Td> // Renderizza il valore corrispondente alla chiave
              ))}
            </Tr>
          ))):("")}
        </Tbody>
      </Table></Box>
        </ModalBody>
      </ModalContent>
    </Modal>
      <Modal  onClose={()=> {onUploadModalClose();  setPayloadUploadRequest(defaultPayloadUploadRequestValue)}} isOpen={isUploadModalOpen} isCentered>
                              <ModalOverlay />
                              <ModalContent>
                                <ModalHeader color="white" display="flex" justifyContent="space-between" bgColor={"#0888BE"}><Box>Carica Dataset</Box> <Box><CloseButton  onClick={()=> {onUploadModalClose();  setPayloadUploadRequest(defaultPayloadUploadRequestValue)}}/></Box></ModalHeader>
                                <ModalBody>
                                <Stack spacing='4'>
                    <Box>

                    <FormControl mb={4} isRequired>
                    <FormLabel>Versione del dataset</FormLabel>
                     <NumberInput
                      min={1.0}
                      step={0.1}
                      precision={1}
                      defaultValue={1.0}
                      
                      value={payloadUploadRequest.version} // Collega il valore allo stato 
                      onChange={(valueString) => handleChangePayloadRequest(valueString, 'version')}
    >
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  </FormControl>

<FormControl>
                    <Box>
                    <FormLabel fontSize='lg' color="rgba(11,78,90,255)" fontWeight='semibold'>Titolo:</FormLabel>
                    <Input placeholder='Titolo'  onChange={(e) => handleChangePayloadRequest(e, "titolo")} />
                    </Box>
                    </FormControl>
                    <FormControl>
                    <FormLabel fontSize='lg' color="rgba(11,78,90,255)" fontWeight='semibold'>Seleziona formato del file:</FormLabel>
                    <Select onChange={(e) => handleChangePayloadRequest(e, "fileFormat")} >
                     

                      {fileFormats.map(option => (
                        <option key={option.value} value={option.value}>
                        {option.label}
                        </option>
                      ))}
                     
                    </Select>
                    </FormControl>


                    </Box>

                    <FormControl display='flex' alignItems='center'>
                  <FormLabel htmlFor='email-alerts' mb='0'>
    Il File è un aggiornamento?
  </FormLabel>
  <Switch id='email-alerts' onChange={(e) => handleChangePayloadRequest(e, "is_update")}  />
</FormControl>
                    <Box>


                    <FormControl isRequired>
                    <FormLabel fontSize='lg' color="rgba(11,78,90,255)" fontWeight='semibold' >Seleziona file:</FormLabel>
                    <Input  ref={fileSelectorRef} placeholder='Select file' name='file' type='file' bg='white' accept={selectedFileFormat}   onChange={(e) => handleChangePayloadRequest(e, "file")}   />
                    </FormControl> 
                    </Box>
       
                    </Stack>
                                </ModalBody>
                                <ModalFooter>
                                <Button colorScheme='green' mr={3}  onClick={ uploadDataset}>
                                    Carica 

                                  </Button>
                                </ModalFooter>
                              </ModalContent>
                            </Modal> 

                            <Modal onClose={closeEditModal} isOpen={isEditDatasetModalOpen} isCentered size={"lg"}>
                              <ModalOverlay />
                              <ModalContent>
                                <ModalHeader bgColor={"#0888BE"} color="white" display="flex" justifyContent="space-between"><Box><Text>Condivisione del dataset</Text><Text color="yellowgreen"> {datasetTableName}</Text></Box><CloseButton onClick={closeEditModal}/></ModalHeader>
                                <ModalBody>
                                  <Table>
                                    <Tr><Text color={"#0888BE"} fontWeight={"bold"}>Inserisci il nome di un utente:</Text></Tr>
                                    <Tr>
                                      <Td><Input size="sm" onChange={handleChengeUsernameUser} borderWidth={1} borderColor={"black"}></Input></Td>
                                      <Td><Button colorScheme="green" onClick={() =>shareWithUser()} >Condividi</Button></Td>
                                  </Tr>
                                  <Tr>
                                   
      
                                    <Table margin={2} >
                                      <Tr > <Text color={"#0888BE"} fontWeight={"bold"} >Utenti con cui hai condiviso il dataset:</Text></Tr>
                                      {sharedUsersList.length > 0 ?
                                        (sharedUsersList.map((item, index) => (
                                          <Tr><Text variant={"striped"}> • {item}</Text></Tr>
                                        )
                                      )




                                        ):(<Tr><Text fontWeight={"bold"} color={"red"}>Non hai condiviso il dataset con nessun utente</Text></Tr>)  
                                    
                                    }
                                    </Table></Tr>
                                  </Table>
                                </ModalBody>
                                <ModalFooter borderTopWidth={1} borderTopColor={"#0888BE"} display="flex" justifyContent="space-between" ><Text color={"#0888BE"} fontWeight={"bold"}>Rendi il dataset pubblico:</Text><Button colorScheme="green" onClick={() =>publishDataset()}>Pubblica il dataset</Button></ModalFooter>
                              </ModalContent>
                            </Modal>
    </>
  );
}

export default DatasetPage;
