import React, { createContext, useContext, useState } from 'react';

const SessionContext = createContext();

export const SessionProvider = ({ children }) => {
  const [sessionData, setSessionData] = useState({ username: 'anonymous', token: '' });

  const updateSessionData = (newData) => {
    setSessionData((prevData) => ({ ...prevData, ...newData }));
    sessionStorage.setItem('sessionData', JSON.stringify({ ...sessionData, ...newData }));
    sessionStorage.setItem('username', newData.username);
    sessionStorage.setItem('token', newData.token);
    sessionStorage.setItem('messaggio', newData.messaggio);
    sessionStorage.setItem('displayName', newData.displayName);
    sessionStorage.setItem('conteggio', 1);
    sessionStorage.setItem('loggato', true)
  };

  const clearSessionData = () => {
    setSessionData({ username: 'anonymous', token: '' });
    sessionStorage.removeItem('sessionData');
    sessionStorage.removeItem('displayName');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('messaggio');
    sessionStorage.removeItem('username');
    sessionStorage.removeItem('conteggio');
    sessionStorage.removeItem('loggato');
  };


 

  return (
    <SessionContext.Provider value={{ sessionData, updateSessionData, clearSessionData }}>
      {children}
    </SessionContext.Provider>
  );
};

export const useSession = () => {
  return useContext(SessionContext);
};