
import {  useState, useEffect } from "react";
import {  TableContainer,  Tr, Td, Th, Table, Thead, Tbody, Icon, Button, Box, Heading, useToast, Modal,  ModalOverlay,  ModalContent,  ModalHeader,  ModalFooter,  ModalBody, useDisclosure, Popover, PopoverTrigger, 
          Portal, PopoverCloseButton, PopoverArrow, PopoverContent, PopoverHeader, PopoverBody, PopoverFooter, Stack, FormControl, FormLabel, Select, Input, CloseButton, RadioGroup, Text, Radio, Textarea,
          GridItem, Grid} from "@chakra-ui/react";
import { MdSearch, MdUploadFile} from "react-icons/md";
import { EditIcon, InfoIcon, SearchIcon} from '@chakra-ui/icons';
import { react_api } from "../config";
import { HTTP_STATUS_CODES } from "../GrinsCostant";
import { CASE_TYPE } from "../CaseTypeCostant";


function DatasetView (){
  const [catalog, setCatalog] = useState([]);
  const { isOpen: isUploadModalOpen, onOpen: onUploadModalOpen, onClose: onUploadModalClose } = useDisclosure();
  const toast = useToast();
  const [activeIndex, setActiveIndex] = useState(null);
  const onOpen = (index) => setActiveIndex(index);
  const onClose = () => setActiveIndex(null);


  /*
  Carica i dataset in una tabella ogni volta che viene caricata la pagina (useEffect) interrogando l'endpoint con 
  l'invio di un json contente il token di identificaione, l'username dellutente e una costante caseType per indicare al backend 
  di quale chiamata si tratta e quali paremetri deve inviarci. Per consultare i case type consultare il file CaseTypeConstant.js  
  */
    useEffect(() => {
      const fetchCatalog = async () => {
      try {
        
          const richiesta = {
            BearerToken : sessionStorage.getItem("token"),
            username: sessionStorage.getItem("username"),
            caseType: CASE_TYPE.CATALOG_ALL,
          }
          const options =   {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(richiesta),
          }
          
          
          const urlFetch = `${react_api}/privateCatalog/getCatalogADP`;
         
          const response = await fetch(urlFetch, options); 
         
          const result = await response.json();
          if( result.status === HTTP_STATUS_CODES.OK){
          setCatalog(result.catalog);
          
        }
      } catch (error) {
        toast({
            title: 'Chiamata in errore',
            description: String(error),
            status: 'warning',
            duration: 4000,
            isClosable: true});
      }
        };
      fetchCatalog();}
  , []);


  

    
    return(<>
<Box alignItems={"center"} justifyContent={"space-between"} display={"flex"} color={"rgba(11,78,90,255)"} bgColor={"#f6f3ec"} ><Heading>Datasets</Heading>
          <Box><Popover >
            <PopoverTrigger>
              <Button  color={"rgba(11,78,90,255)"} bgColor={"#f6f3ec"} h={"100%"}><Icon as={MdSearch}/>Search</Button>
            </PopoverTrigger>
            <Portal>
              <PopoverContent bgColor={"white"} borderWidth={1} borderColor={"#0888BE"}>
                <PopoverArrow />
                <PopoverHeader color={"rgba(11,78,90,255)"} fontSize='lg' fontWeight='semibold'>Search in the table</PopoverHeader>
                <PopoverCloseButton color={"rgba(11,78,90,255)"} />
                <PopoverBody>
                <Stack spacing='4'>
                <Box>
                    <FormControl>
                    <FormLabel fontSize='lg' color="rgba(11,78,90,255)" fontWeight='semibold'>Category:</FormLabel>
                    <Select>
                      <option value='OWNER' variant="filled" defaultChecked>Manager</option>
                      <option value='visibility' variant="filled">Visibility</option>
                    </Select>
                    </FormControl>
                    </Box>
                    <Box>
                    <FormControl isRequired>
                    <FormLabel fontSize='lg' color="rgba(11,78,90,255)" fontWeight='semibold'>Insert value:</FormLabel>
                    <Input variant='filled' placeholder='value' name='valueForSearch' type='text' bg='white'/>
                    </FormControl>
                    </Box>
                    </Stack>
                  
                </PopoverBody>
                <PopoverFooter><Button colorScheme='green'><Icon as={MdSearch } />Search</Button></PopoverFooter>
              </PopoverContent>
            </Portal>
          </Popover>
          <Button color={"rgba(11,78,90,255)"}  bgColor={"#f6f3ec"} h={"100%"} onClick={onUploadModalOpen}><Icon as={MdUploadFile} />Upload Dataset</Button>
          </Box>
</Box>
<Box  overflowY="auto" direction="column" h="100%" w="100%" boxShadow="lg" >
<TableContainer px={"1%"} paddingTop={"1%"} > 
<Table variant='striped' w="100%"  >
    <Thead bgColor={"#0888BE"} position="sticky" top="0" >  
   
      <Tr>
        <Th align="left" w={"20%"} color={"white"}>Datasets</Th>
        <Th  w={"16%"} whiteSpace={"normal"} wordBreak={"break-word"} color={"white"}>Category</Th>
        <Th   w={"28%"} whiteSpace={"normal"} wordBreak={"break-word"} color={"white"}>Description</Th>
        <Th  w={"16%"} whiteSpace={"normal"} wordBreak={"break-word"} color={"white"}>Manager</Th>
        <Th  w={"16%"} whiteSpace={"normal"} wordBreak={"break-word"} color={"white"}>Visibility</Th>
        <Th w={"4%"} color={"white"}>Actions</Th>
        
      </Tr>
    </Thead>
    <Tbody>
      
    {catalog ? 
    (catalog.map((item, index) => {
      const isOpen = activeIndex === index;    return (
    <Tr>
       {/*nome dataset*/} <Td whiteSpace={"normal"} wordBreak={"break-word"}> {item.TBL_NAME}</Td>
       {/*categoria*/}<Td whiteSpace={"normal"} wordBreak={"break-word"}>{item.category}</Td>
       {/*description*/} <Td whiteSpace={"normal"} wordBreak={"break-word"}>{item.description}</Td>
       {/*responsabile*/}<Td whiteSpace={"normal"} wordBreak={"break-word"}>{item.OWNER}</Td>
       {/*visibilità*/} <Td whiteSpace={"normal"} wordBreak={"break-word"}>{item.visibility}</Td>
       
        {/*azioni*/}<Td><Grid  templateColumns='repeat(3, 1fr)' w="50%">
          <GridItem colSpan={1}><Icon as={InfoIcon} onClick={() => onOpen(index)} _hover={{color: "#0888BE"}} /></GridItem>
          {item.visibility === "public" ? ( <GridItem colSpan={1}><Icon as={SearchIcon} _hover={{color: "#0888BE"}} w={"100%"}/></GridItem> ):
            (item.visibility === "private" ? (item.OWNER === sessionStorage.username ? 
              (<GridItem colSpan={2}><Icon as={SearchIcon} _hover={{color: "#0888BE"}} w={"100%"}/><Icon as={EditIcon} _hover={{color: "#0888BE"}} w={"100%"}/></GridItem>) : 
                ("")) : 
              (item.visibility === "obtained" ? (<GridItem colSpan={1}><Icon as={SearchIcon} _hover={{color: "#0888BE"}} w={"100%"}/></GridItem>) : (""))
              )}</Grid>
        
          <Modal  onClose={onClose} isOpen={isOpen} isCentered w ="50%">
                              <ModalOverlay />
                              <ModalContent>
                                <ModalHeader bgColor={"#0888BE"}>Vista Metadati: Dataset {item.TBL_NAME}</ModalHeader>
                                <ModalBody>
                                <Table size="sm" variant='striped' >
                                  <Thead fontFamily={"-moz-initial"} bg={"#0888BE"} position="sticky" top="0" zIndex={1} >
                                    <Tr>
                                      <Th  color={"black"} >Category</Th>
                                      <Th color={"black"} >Descrizione</Th>
                                      <Th color={"black"} >Manager</Th>
                                      <Th color={"black"} >Visibilità</Th>
                                    </Tr>
                                  </Thead>
                                  <Tbody> <Tr>
                                      <Td  color={"black"} >{item.category}</Td>
                                      <Td  color={"black"} >{item.description}</Td>
                                      <Td  color={"black"} >{item.OWNER}</Td>
                                      <Td color={"black"} >{item.visibility}</Td>
                                    </Tr> </Tbody>
                                  </Table> 
                                </ModalBody>
                                <ModalFooter>
                                  
                                </ModalFooter>
                              </ModalContent>
                            </Modal> </Td>
        
      </Tr>);})) : ("")}
     
    </Tbody>
   
  </Table>
</TableContainer>
<Modal  onClose={onUploadModalClose} isOpen={isUploadModalOpen} isCentered>
                              <ModalOverlay />
                              <ModalContent>
                                <ModalHeader display="flex" justifyContent="space-between"><Box>Upload Dataset</Box> <Box><CloseButton onClick={onUploadModalClose} /></Box></ModalHeader>
                                <ModalBody>
                                <Stack spacing='4'>
                    <Box>
                    <FormControl>
                    <FormLabel fontSize='lg' color="rgba(11,78,90,255)" fontWeight='semibold'>Select file format:</FormLabel>
                    <Select>
                      <option value='' variant="filled" defaultChecked>(in attesa del backend)</option>
                     
                    </Select>
                    </FormControl>
                    </Box>
                    <Box>
                    <FormControl isRequired>
                    <FormLabel fontSize='lg' color="rgba(11,78,90,255)" fontWeight='semibold' >Select file:</FormLabel>
                    <Input placeholder='Select file' name='file' type='file' bg='white' />
                    </FormControl>
                    </Box>
                    <Box>
                    <FormControl>
                    <FormLabel fontSize='lg' color="rgba(11,78,90,255)" fontWeight='semibold'>Category:</FormLabel>
                    
                    <Select>
                      <option value='' variant="filled" defaultChecked>(in attesa del beckend)</option>
                      <option value='' variant="filled"></option>
                      
                    </Select>
                    </FormControl>
                    </Box>
                    <Box>
                    <RadioGroup >
                      <Stack direction='row'>
                        <Text color="rgba(11,78,90,255)">Type:</Text>
                        <Radio value='public'>Public</Radio>
                        <Radio value='private'>Private</Radio>
                      </Stack>
                    </RadioGroup>
                    </Box>
                    <Box>
                    <FormLabel fontSize='lg' color="rgba(11,78,90,255)" fontWeight='semibold'>Description:</FormLabel>
                    <Textarea placeholder='Description' />
                    </Box></Stack>
                                </ModalBody>
                                <ModalFooter>
                                <Button colorScheme='green' mr={3} >
                                    Submit
                                  </Button>
                                </ModalFooter>
                              </ModalContent>
                            </Modal> </Box></>
)
}

export default DatasetView;
