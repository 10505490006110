import { react_api } from "../config";
import { HTTP_STATUS_CODES } from "../GrinsCostant";

const  logout = async (clearSessionData, navigate ,toast,axiosInstance) => {
  
    try {
      const richiesta = {
        BearerToken : sessionStorage.getItem("token"),
        username : sessionStorage.getItem("username")
       
      }

      
      const options =   {
        
        headers: {
          'Content-Type': 'application/json',
        },
       
      }
      console.info("Chiamata verso ", `${react_api}`);
     
      const urlFetch = `${react_api}/logoutADP`;
      const response = await axiosInstance.post(urlFetch,richiesta, options);
      console.info("response: ", response);
      const result =  response.data;
      console.info("result: ", result);
      if (result.status == HTTP_STATUS_CODES.OK ){
       

        const toastId = 'session-expired'; // Un ID unico per il toast
    
        if (!toast.isActive(toastId)) { 
          toast({
            id: toastId,
            title: 'Sessione Scaduta',
            description: 'La tua sessione è scaduta. Verrai disconnesso a breve.',
            position: "top",
            status: 'warning',
            duration: 4000, // Durata del messaggio in millisecondi
            isClosable: true, // Permette di chiudere il toast
            onCloseComplete: ()=> {
  
              clearSessionData();
              navigate('/home');
              window.location.reload();
            }, 
          });
        }

       

  
  }
    } catch (error) {
      toast({
        title: 'Chiamata in errore',
        description: String(error),
        position: "top",
        status: 'warning',
        duration: 4000,
        isClosable: true});
    }

}
    

export default logout