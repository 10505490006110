import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Icon,
    VStack,
    CheckboxGroup,
    Flex,
    FormControl,
    FormLabel,
    Input,
    RadioGroup,
    Radio,
    Stack,
    Checkbox,
    Textarea,
    Switch,
    Box,
    useToast,
    IconButton,
  } from "@chakra-ui/react";
  import { ArrowForwardIcon, ArrowBackIcon } from "@chakra-ui/icons";
  import { useState, useEffect } from "react";
  import { useSession } from '../SessionContext';
  import { useNavigate } from "react-router-dom";
  import logout from "../services/authService";
  import createAxiosInstance from "../interceptors/useAxiosInterceptors";
  import { react_api } from "../config";
  import { DndProvider, useDrag, useDrop } from "react-dnd";
  import { HTML5Backend } from "react-dnd-html5-backend";
  import { MdDelete } from "react-icons/md";
  import { HTTP_STATUS_CODES } from "../GrinsCostant";

  function MetadataModal({ isOpen, onClose, content }) {
    const [page, setPage] = useState(1);
    const [metadataEntered, setMetadataEntered] = useState(false);
    const [metadataEnteredData, setMetadataEnteredData] = useState([]);
    const [initFunctionIsCalled, setInitFunctionIsCalled] = useState(false);
    const [payloadUploadMetadata, setPayloadUploadMetadata] = useState({ encoding: "utf8", visibility: "private"});


    const DraggableItem = ({ id, text ,content}) => {
      

      const [, drag] = useDrag(() => ({
        type: "ITEM",
        item: content,
      }));
      
        return (
        <Button ref={drag} bg="gray" color="white" borderWidth={1} >
          {text}
        </Button>
      );
      
    };


    // Droppable Component
const DroppableArea = ({ onDrop, children}) => {


  const [{ isOver }, drop] = useDrop(() => ({
    accept: "ITEM",
    drop: (item) => {
    

      

      onDrop(
        item
      );
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  return (
    
    <Box
    ref={drop} // Riferimento per il drop (drag-and-drop)
    borderWidth="2px" // Spessore del bordo
    borderStyle="solid" // Stile del bordo (può essere 'dashed', 'dotted', ecc.)
    borderColor="black" // Colore del bordo
    borderRadius="md" // Bordo arrotondato
    p={4} // Padding interno della box
    mb={3} // Margine inferiore
    h="100%" // Altezza della box
    bgColor={isOver ? "green.200" : "gray.200"} // Colore di sfondo condizionale
  >
    {children}
  </Box>
  );
};





const handleDropTag = (obj) => {

  // Update the tagsDropped state by adding the dropped tag
  setTagsDropped((prevTagDropped) => {
    const updatedDroppedTags = [...prevTagDropped, obj];

    handleChangePayloadUploadMetadata(updatedDroppedTags.map(tag => tag.tag_ID), "tags")
    // Sort the dropped tags alphabetically (assuming obj has a 'name' property)
    return updatedDroppedTags.sort((a, b) => a.tag_ID- b.tag_ID)
  });

  // Update the tags state by filtering out the dropped tag and sorting
  setTags((prevTag) => {
    const updatedTags = prevTag.filter((column) => column.tag_ID !== obj.tag_ID);

    // Sort the remaining tags alphabetically
    return updatedTags.sort((a, b) => a.tag_ID- b.tag_ID)
  });
};




const handleOnClickConcludi  = () => {
  
  setPayloadUploadMetadata((prevMetadata) => ({
    ...prevMetadata,
    tableId: content.id,
  })); 


  if(payloadUploadMetadata.encoding == null)
    payloadUploadMetadata.encoding = encodings[0].value

 

  payloadUploadMetadata["tableId"] = parseInt(content.id)

  
  
  if(
    payloadUploadMetadata.description  != null &&
    payloadUploadMetadata.otherId  != null &&
    payloadUploadMetadata.urlSito  != null &&
    payloadUploadMetadata.owner  != null &&
    payloadUploadMetadata.publisher  != null &&
    payloadUploadMetadata.provider  != null &&
    payloadUploadMetadata.contactProvider  != null &&
    payloadUploadMetadata.creationDate  != null &&
    payloadUploadMetadata.languages  != null && payloadUploadMetadata.languages.length >0 &&
    payloadUploadMetadata.frequenceUpdate  != null &&
    payloadUploadMetadata.encoding  != null &&
    payloadUploadMetadata.dataThemes  != null && payloadUploadMetadata.dataThemes.length >0 &&
    payloadUploadMetadata.licence_id  != null &&
    payloadUploadMetadata.tableId  != null
    
    
  ) {
    uploadMetadata(payloadUploadMetadata).then(()=> {

    })


  }else {
    toast({
      title: "Inserire Campi Obbligatori",
      description: "" ,
      position: "top",
      status: "warning",
      duration: 4000,
      isClosable: true,
    });
  }

 
 

 
  

  
}


const uploadMetadata = async(payloadUploadMetadata) => {
  try{
    
   
   


  

    payloadUploadMetadata["dtm_manager"] = sessionStorage.getItem("username")

   
    let licence = licenses.find(licence => licence.value === payloadUploadMetadata.licence_id)

    

    if(licence && licence.id)
      payloadUploadMetadata.licence_id = licence.id.toString()


    

    
    payloadUploadMetadata.dataThemes =   datasetThemes.filter(datasetTheme => payloadUploadMetadata.dataThemes.includes(datasetTheme.value) ).map(datasetTheme => datasetTheme.id)


    if(!payloadUploadMetadata.visibility) 
      payloadUploadMetadata.visibility = 'private'

    


    if(!payloadUploadMetadata.prov_elab) 
        payloadUploadMetadata.prov_elab =false
    
     


    
      
      const formData = new FormData();
      

      Object.keys(payloadUploadMetadata).forEach(payloadUploadRequestKey => {
        formData.append(payloadUploadRequestKey, payloadUploadMetadata[payloadUploadRequestKey]);
      })
  
     
    
      const urlFetch = `${react_api}/stagingAreaResult/uploadMetadataADP`;
      const response = await axiosInstance.post(urlFetch, formData, {
        headers : {
          
          'Authorization': sessionStorage.getItem('token')
        }
      })

    if(response.data.status === HTTP_STATUS_CODES.OK) {
 
      toast({
        title: "Prima parte metadati salvata",
        description: "" ,
        position: "top",
        status: "info",
        duration: 4000,
        isClosable: true,
      });
      onClose()
      setMetadataEntered(true);

      getTableCol(content.id).then(res => {
        setMetadataEnteredData(res.columns);
      });


    

      setInitFunctionIsCalled(false)
    }
     


  }
  catch(error){

  console.log(error)
    toast({
      title: 'Chiamata in errore',
      description: String(error),
      status: 'warning',
      duration: 4000,
      isClosable: true,
    });
    return null;
  }






}

const handleChangePayloadUploadMetadata = (e, field) => {
  const value = e.target ? e.target.value : e;
 
  setPayloadUploadMetadata((prev) => ({
    ...prev,
    [field]: value,
  }));
};


const handleRemoveTag = (obj) => {
  
  // Update the tagsDropped state by filtering out the removed tag and sorting alphabetically
  setTagsDropped((prevTagsDropped) => {
    const updatedTagsDropped = prevTagsDropped.filter((tag) => tag.tag_ID !== obj.tag_ID);
    handleChangePayloadUploadMetadata(updatedTagsDropped.map(tag => tag.tag_ID), "tags")
    
    // Sort the remaining dropped tags alphabetically (assuming obj has a 'name' property)
    return updatedTagsDropped.sort((a, b) => a.tag_ID- b.tag_ID)
  });

  // Update the tags state by adding the removed tag and sorting alphabetically
  setTags((prevTags) => {
    const updatedTags = [...prevTags, obj];
    
    // Sort the updated tags alphabetically
    return updatedTags.sort((a, b) => a.tag_ID- b.tag_ID);
  });
};




  const [datasetThemes, setDatasetThemes] = useState([])
  const [datasetLangs, setDatasetLangs] = useState([])
  const [frequenzeDiAggiornamento, setFrequenzeDiAggiornamento] = useState([])
  const [licenses, setLicenses] = useState([])
  const [encodings, setEncodings] = useState([])





  
  const [tags, setTags] = useState([]);




  const [tagsDropped, setTagsDropped] = useState([]);

  




    const [descriptionMetadataPayload, setDescriptionMetadataPayload] = useState({
      "tableId": "",
      "cols": {
          
      }
  });

    const toast = useToast();
    const { clearSessionData } = useSession();
    const navigate = useNavigate();
    
    const axiosInstance = createAxiosInstance(async (axiosInstance) => {
      logout(clearSessionData, navigate, toast, axiosInstance);
    });
  
    const checkMetadata = async (id) => {
      try {
        const urlFetch = `${react_api}/stagingAreaResult/checkMetadataADP`;
        const response = await axiosInstance.post(urlFetch,{tableId: id} ,{
          headers: { 'Content-Type': 'application/json' },
        });
        return response.data;
      } catch (error) {
        toast({
          title: 'Chiamata in errore',
          description: String(error),
          status: 'warning',
          duration: 4000,
          isClosable: true,
        });
        return null;
      }
    };
  
    const getTableCol = async (id) => {
      try {
        const urlFetch = `${react_api}/stagingAreaResult/getTableColADP`;
        const response = await axiosInstance.post(urlFetch, {tableId: id},{
          headers: { 'Content-Type': 'application/json' },
        });
        return response.data;
      } catch (error) {
        toast({
          title: 'Chiamata in errore',
          description: String(error),
          status: 'warning',
          duration: 4000,
          isClosable: true,
        });
        return null;
      }
    };

    const getSystemTable = async () => {
      try {
        const urlFetch = `${react_api}/getSystemTablesADP`;
        const response = await axiosInstance(urlFetch)

        
        return response.data;
      } catch (error) {
        toast({
          title: 'Chiamata in errore',
          description: String(error),
          status: 'warning',
          duration: 4000,
          isClosable: true,
        });
        return null;
      }
    };


    const getTags = async () => {
      try {
        const urlFetch = `${react_api}/getTagsADP`;
        const response = await axiosInstance(urlFetch)

        
        return response.data;
      } catch (error) {
        toast({
          title: 'Chiamata in errore',
          description: String(error),
          status: 'warning',
          duration: 4000,
          isClosable: true,
        });
        return null;
      }
    };


    const handleChangeDescriptionMetadata =  (event,item) => {   

    let val = event.target.value;
    
    setDescriptionMetadataPayload(payloadUploadRequest => {
    // Creiamo una copia di cols
    const updatedCols = { ...payloadUploadRequest.cols };

    // Se il valore è vuoto, rimuoviamo la proprietà
    if (val.trim() === "") {
      delete updatedCols[item.name];
    } else {
      // Altrimenti, aggiorniamo con il nuovo valore
      updatedCols[item.name] = val;
    }

    return {
      ...payloadUploadRequest,
      tableId: content.id,
      cols: updatedCols
    };

  });

  }
    

    const handleClickInvia = async () => {  
    
        
        const allNamesPresent = metadataEnteredData.map(metadataEntered => metadataEntered.name).every(name =>  descriptionMetadataPayload.cols.hasOwnProperty(name) )
        
        if(!allNamesPresent) 
          return

          try {
          
           
        const urlFetch = `${react_api}/stagingAreaResult/uploadColDescADP`;
         const response = await axiosInstance.post(urlFetch,descriptionMetadataPayload, {
          headers: { 'Content-Type': 'application/json' },
        });

        
        if(response.data.status === HTTP_STATUS_CODES.OK) {
 
          toast({
            title: response.data.message,
            description: "" ,
            position: "top",
            status: "info",
            duration: 4000,
            isClosable: true,
          });
          onClose(descriptionMetadataPayload)
          
        }


       


        
        } catch (error) {
        toast({
          title: 'Chiamata in errore',
          description: String(error),
          status: 'warning',
          duration: 4000,
          isClosable: true,
        });
        return null;
      
    
    }

        
    }


    useEffect(() => {
      
      getSystemTable().then(res =>{


        setDatasetThemes([])
        setDatasetLangs([])
        setFrequenzeDiAggiornamento([])
        setLicenses([])
        setEncodings([])
       
        const datasetLang = res.tables_info.find(tblInfo => tblInfo.name === "Lingue del dataset");
        const frequenzeDiAggiornamento = res.tables_info.find(tblInfo => tblInfo.name === "Frequenza di aggiornamento");
        const encodings = res.tables_info.find(tblInfo => tblInfo.name === "Encoding");

        const datasetTheme = res.tables_info.find(tblInfo => tblInfo.name === "Temi dei dataset");
        const licenses = res.tables_info.find(tblInfo => tblInfo.name === "Licenze");

      

        if (datasetLang && datasetLang.values) {
          // Generate new themes with IDs
          const newLang = datasetLang.values.map((data, index) => ({
              id: index + 1 + datasetLang.length, // Dynamically set ID
              value: data
          }));

          // Update the state with new themes
          setDatasetLangs(prevState => [
              ...prevState,
              ...newLang
          ]);
      }

      if (frequenzeDiAggiornamento && frequenzeDiAggiornamento.values) {
        // Generate new themes with IDs
        const newFrequenzaAggiornamento = frequenzeDiAggiornamento.values.map((data, index) => ({
            id: index + 1 + frequenzeDiAggiornamento.length, // Dynamically set ID
            value: data
        }));

        // Update the state with new themes
        setFrequenzeDiAggiornamento(prevState => [
            ...prevState,
            ...newFrequenzaAggiornamento
        ]);
    }


    if (encodings && encodings.values) {
      // Generate new themes with IDs
      const newEncoding = encodings.values.map((data, index) => ({
          id: index + 1 + encodings.length, // Dynamically set ID
          value: data
      }));
  
      // Update the state with new themes
      setEncodings(prevState => [
          ...prevState,
          ...newEncoding
      ]);
  }

        
        if (datasetTheme && datasetTheme.values) {
            // Generate new themes with IDs
            const newThemes = datasetTheme.values.map((data, index) => ({
                id: index  +1, // Dynamically set ID
                value: data
            }));

            // Update the state with new themes
            setDatasetThemes(prevState => [
                ...prevState,
                ...newThemes
            ]);
        }

        


   

    
        if (licenses && licenses.values) {
          // Update the state with new licenses
          setLicenses(prevState => {
            // Generate new licenses with dynamically set IDs based on prevState length
            const newLicense = licenses.values.map((data, index) => ({
                id: index + 1 + prevState.length, // Dynamically set ID based on previous length
                value: data
            }));
        
            return [
              ...prevState,
              ...newLicense
            ];
          });
        }


  



      
      
      })


     

      if (isOpen && content != null && content.id != null) {
        setPage(1)
        setPayloadUploadMetadata({})
        setMetadataEnteredData([])
        setDescriptionMetadataPayload({})

        setTagsDropped([])
        if (!initFunctionIsCalled) {
          checkMetadata(content.id).then(res => {
            if (res != null && res.metadataEntered === 'true') {
              setMetadataEntered(true);

              getTableCol(content.id).then(res => {
                setMetadataEnteredData(res.columns);
              });

            } else {
              setMetadataEntered(false);
             
            }
            setInitFunctionIsCalled(true);
          });
        }
      }

      getTags().then(res => {
        setTags(res.tags)
        
      })

    }, [isOpen, content, initFunctionIsCalled]); // Run effect on isOpen or content changes
  
    return (
      <DndProvider backend={HTML5Backend}>
      <Modal isOpen={isOpen} onClose={() => { onClose(); setInitFunctionIsCalled(false); setMetadataEntered(true); }} size="xl">
        <ModalOverlay />
        {!metadataEntered && initFunctionIsCalled ? (
          <ModalContent>
            <ModalHeader>Pubblicazione dei metadati</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {/* Pagina 1 */}
              {page === 1 && (
                <>
                  <FormControl mb={4}>
                    <FormLabel>Nome della tabella</FormLabel>
                    {content && content.tableName ? (
                      <p>{content.tableName}</p>
                    ) : null}
                  </FormControl>
                 
                  <FormControl mb={4} >
                    <FormLabel>Id dei dataset da cui deriva separati da ;</FormLabel>
                    <Input required value={payloadUploadMetadata["versionOf"]} placeholder="es: 0AAA19;0AAB99;0AAB54" onChange={(e)=>handleChangePayloadUploadMetadata(e, 'versionOf')} />
                  </FormControl>
                  <FormControl mb={4}>
                    <FormLabel>Tipologia</FormLabel>
                    <RadioGroup defaultValue="private" value={payloadUploadMetadata.visibility} onChange={(e)=>handleChangePayloadUploadMetadata(e, 'visibility')}>
                      <Stack direction="row">
                        <Radio value="public">Pubblica</Radio>
                        <Radio value="private">Privata</Radio>
                      </Stack>
                    </RadioGroup>
                  </FormControl>
                  <FormControl mb={4} isRequired>
                    <FormLabel>Descrizione</FormLabel>
                    <Textarea required placeholder="Inserisci descrizione"  value={payloadUploadMetadata.description} onChange={(e)=>handleChangePayloadUploadMetadata(e, 'description')} />
                  </FormControl>
                </>
              )}
              {/* Pagina 2 */}
              {page === 2 && (
                <>
                
                  <FormControl mb={4} isRequired>
                    <FormLabel>Url del sito di provenienza</FormLabel>
                    <Input placeholder="Inserisci URL" required  value={payloadUploadMetadata.urlSito} onChange={(e)=>handleChangePayloadUploadMetadata(e, 'urlSito')} />
                  </FormControl>
                  <FormControl mb={4} isRequired>
                    <FormLabel>Altro identificativo</FormLabel>
                    <Input placeholder="Inserisci identificativo" required   value={payloadUploadMetadata.otherId}  onChange={(e)=>handleChangePayloadUploadMetadata(e, 'otherId')}/>
                  </FormControl>
                  <FormControl mb={4} isRequired>
                    <FormLabel>Titolare</FormLabel>
                    <Input placeholder="Inserisci nome del titolare" required value={payloadUploadMetadata.owner} onChange={(e)=>handleChangePayloadUploadMetadata(e, 'owner')} />
                  </FormControl>
                  <FormControl mb={4} isRequired>
                    <FormLabel>Editore del dataset</FormLabel>
                    <Input placeholder="Editore del Dataset" required  value={payloadUploadMetadata.publisher} onChange={(e)=>handleChangePayloadUploadMetadata(e, 'publisher')}/>
                  </FormControl>
                  <FormControl mb={4} isRequired>
                    <FormLabel>Creatore</FormLabel>
                    <Input placeholder="Nome del creatore" required value={payloadUploadMetadata.provider}  onChange={(e)=>handleChangePayloadUploadMetadata(e, 'provider')} />
                  </FormControl>
                </>
              )}
              {/* Pagina 3 */}
              {page === 3 && (
                <>

                
                   
                  
                  <FormControl mb={4} isRequired>
                    <FormLabel>Contatto</FormLabel>
                    <Input placeholder="Inserisci contatto email" required value={payloadUploadMetadata.contactProvider} onChange={(e)=>handleChangePayloadUploadMetadata(e, 'contactProvider')} />
                  </FormControl>
                  <FormControl mb={4} isRequired>
                    <FormLabel>Data di Creazione</FormLabel>
                    <Input type="date" required value={payloadUploadMetadata.creationDate}  onChange={(e)=>handleChangePayloadUploadMetadata(e, 'creationDate')} />
                  </FormControl>
                  <FormLabel>Copertura Temporale</FormLabel>
                  <FormControl mb={4}>
                    <FormLabel fontSize="12px">Data di Inizio</FormLabel>
                    <Input type="date"value={payloadUploadMetadata.startDate} onChange={(e)=>handleChangePayloadUploadMetadata(e, 'startDate')}  />
                  </FormControl>

                  <FormControl mb={4}>
                    <FormLabel fontSize="12px">Data di Fine</FormLabel>
                    <Input type="date" value={payloadUploadMetadata.endDate}  onChange={(e)=>handleChangePayloadUploadMetadata(e, 'endDate')} />
                  </FormControl>
 
               
                    <FormLabel>Lingue del dataset <Box as="span" color={"red"}>*</Box></FormLabel>
                    <CheckboxGroup     defaultValue={payloadUploadMetadata.languages} onChange={(e)=>{handleChangePayloadUploadMetadata(e, 'languages')}}>
                    <Stack  spacing={4} direction="row">
                      <Checkbox  value="Italiano"  >Italiano</Checkbox>
                      <Checkbox value="Inglese" >Inglese</Checkbox>
                      <Checkbox value="Tedesco" >Tedesco</Checkbox>
                    </Stack>
                    </CheckboxGroup>
                 

                </>
              )}

               {/* Pagina 4 */}
               {page === 4 && (
                <>

                
                   
                  
<FormControl as="fieldset" mb={4} isRequired>
  <FormLabel as="legend">Frequenza di Aggiornamento</FormLabel>
  <RadioGroup 
    value={payloadUploadMetadata.frequenceUpdate} 
    onChange={(value) => {
      
      handleChangePayloadUploadMetadata(value, 'frequenceUpdate'); // Aggiorna il payload
    }} 
    spacing={4} 
    direction={"row"}
  >
    <VStack align="start">
      {frequenzeDiAggiornamento.map((frequenzaAggiornamento, index) => (
        <Radio key={index} value={frequenzaAggiornamento.value}>
          {frequenzaAggiornamento.value}
        </Radio>
      ))}
    </VStack>
  </RadioGroup>
</FormControl>


<FormControl as="fieldset" mb={4} isRequired>
  <FormLabel as="legend">Encoding</FormLabel>
  <RadioGroup 
    value={payloadUploadMetadata.encoding} 
    defaultValue={encodings[0].value}
    onChange={(value) => {
      
      handleChangePayloadUploadMetadata(value, 'encoding'); // Aggiorna il payload
    }}
  >
    <VStack align="start">
      {encodings.map((encoding, index) => (
        <Radio key={index} value={encoding.value}>
          {encoding.value}
        </Radio>
      ))}
    </VStack>
  </RadioGroup>
</FormControl>


 
      <FormLabel as="legend">Temi dei dataset  <Box as="span" color={"red"}>*</Box></FormLabel>
      <CheckboxGroup  value={payloadUploadMetadata.dataThemes} onChange={(e)=>handleChangePayloadUploadMetadata(e, 'dataThemes')}>
        <VStack align="start">
          {datasetThemes.map((theme, index) => (
            <Checkbox key={index} value={theme.value}>
              {theme.value}
            </Checkbox>
          ))}
        </VStack>
      </CheckboxGroup>
  



   

                </>
              )}
   {/* Pagina 5 */}
   {page === 5&& (
                <>
      
      <FormControl as="fieldset" mb={4} isRequired>
  <FormLabel as="legend">Licenze</FormLabel>
  <RadioGroup 
    value={payloadUploadMetadata.licence_id} 
    onChange={(value) => {
      
      handleChangePayloadUploadMetadata(value, 'licence_id'); // Aggiorna il payload
    }}
  >
    <VStack align="start">
      {licenses.map((licence, index) => (
        <Radio key={index} value={licence.value}>
          {licence.value}
        </Radio>
      ))}
    </VStack>
  </RadioGroup>
</FormControl>


    

                  </>
              )}


                {/* Pagina 5 */}
   {page === 6&& (
                <>



<FormLabel as="legend">Seleziona i tags più adatti per il tuo dataset:</FormLabel>
                      <Box id="sub_operations_content" mt={2}>
                        {tags.length > 0 &&
                          tags.map((item) => (
                            <DraggableItem
                              key={item.tag_ID}
                              id={item.tag_ID}
                              text={item.tag_name}
                              content={item}
                            />
                          ))}
                     
                      </Box>
                      
                      
                      <DroppableArea
                        id="selected_tag"
                        onDrop={handleDropTag}
                        
                      >
 
                      {tagsDropped.map((item, index) => (
                              <Button
                                key={index}
                                bg="gray" color="white" borderWidth={1}
                              >
                                {item.tag_name}
                                <Icon
                                  as={MdDelete} // Change this to the icon you want
                                  boxSize={5}
                                  // Add click handler
                                  onClick={() => handleRemoveTag(item)}
                                  cursor="pointer"
                                  ml={2} // Margin left to space the icon from the text
                                />
                              </Button>
                            ))}
                      </DroppableArea>
                    

     <FormLabel>Inserisci un nuovo Tag:</FormLabel>
    
    
     <FormControl as="fieldset" mb={4} >
    <FormLabel fontSize="12px">Nome tag:</FormLabel>
    <Input value={payloadUploadMetadata.newTagName} onChange={(e)=> { handleChangePayloadUploadMetadata(e, 'newTagName')}} />
    </FormControl>

    <FormControl as="fieldset" mb={4} >
    <FormLabel fontSize="12px">Descrizione tag:</FormLabel>
    <Input  value={payloadUploadMetadata.newTagDescription}  onChange={(e)=> { handleChangePayloadUploadMetadata(e, 'newTagDescription')}}/>
    </FormControl>

  <FormControl display='flex' alignItems='center'>
  <FormLabel htmlFor='email-alerts' mb='0'>Proveniente da elaborazione</FormLabel>
  <Switch 
    id='provElab' 
    isChecked={payloadUploadMetadata.prov_elab} 
    onChange={(e) => handleChangePayloadUploadMetadata(e.target.checked, "prov_elab")} 
  />
</FormControl>

{payloadUploadMetadata.prov_elab && (
  <FormControl as="fieldset" mb={4} isRequired>
    <FormLabel fontSize="12px">Inserisci Descrizione</FormLabel>
    <Input 
      value={payloadUploadMetadata.type_elab} 
      onChange={(e) => handleChangePayloadUploadMetadata(e.target.value, "type_elab")}
    />
  </FormControl>
)}


    

     

                  </>
              )}
              

            </ModalBody>
            <ModalFooter>
              
            <Flex justify="center" alignItems="center" mt={4} width="100%">
  {page > 1 && page < 6 && (
    <>
      <IconButton
        marginRight={"7%"} // Margine destro quando entrambi i bottoni sono visibili
        icon={<ArrowBackIcon />}
        onClick={() => setPage(page - 1)}
        aria-label="Pagina precedente"
        colorScheme="teal"
      />
      <IconButton
        icon={<ArrowForwardIcon />}
        onClick={() => setPage(page + 1)}
        aria-label="Pagina successiva"
        colorScheme="teal"
      />
    </>
  )}

  {page === 1 && (
    <IconButton
      icon={<ArrowForwardIcon />}
      onClick={() => setPage(page + 1)}
      aria-label="Pagina successiva"
      colorScheme="teal"
    />
  )}

  {page === 6 && (
    <IconButton
      icon={<ArrowBackIcon />}
      onClick={() => setPage(page - 1)}
      aria-label="Pagina precedente"
      colorScheme="teal"
    />
  )}
</Flex>


                {page === 6 ? (
                  <Button colorScheme="blue" onClick={handleOnClickConcludi}>
                    Concludi
                  </Button>
                ) : (
                  ""
                )}
              
            </ModalFooter>
          </ModalContent>
        ) : (

          metadataEnteredData != null && metadataEnteredData.length >0 ? (
          
          <ModalContent>
          <ModalHeader>Inserimento descrizione campi</ModalHeader>
          <ModalCloseButton />
          <ModalBody overflowY="auto" maxHeight="600px"> {/* Imposta un'altezza massima per il contenuto */}
            {metadataEnteredData.map(item => (
              <FormControl mb={4} isRequired key={item.name}>
                <FormLabel>Nome: {item.name}, Tipo: {item.type}</FormLabel>
                <Input placeholder="Inserire Descrizione" onChange={(e) =>handleChangeDescriptionMetadata(e,item)} />
              </FormControl>
            ))}
          </ModalBody>
          <ModalFooter> {/* Sezione fissa per i bottoni */}
            <Flex justify="center" width="100%">
              <Button marginRight="6%" onClick={onClose}>
                Chiudi
              </Button>
              <Button onClick={handleClickInvia}>
                Invia
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
          ): ("")

          

        )}
      </Modal>
      </DndProvider>
    );
  }
  
  export default MetadataModal;
  