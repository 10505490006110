import { useEffect, useState } from "react";
import { TableContainer, Tr, Td, Th, Table, Thead, Tbody, Icon,  Heading, Box, useToast, Link} from "@chakra-ui/react";
import { HTTP_STATUS_CODES } from "../GrinsCostant";
import { SearchIcon} from '@chakra-ui/icons';
import { react_api } from "../config";




export default function DashboradGuest (){
  const [catalog, setCatalog] = useState([]);
  const toast = useToast();

  /*
  Carica le dashboards in una tabella ogni volta che viene caricata la pagina (useEffect) interrogando l'endpoint con 
  l'invio di un json contente il token di identificaione, l'username dellutente e una costante caseType per indicare al backend 
  di quale chiamata si tratta e quali paremetri deve inviarci. Per consultare i case type consultare il file CaseTypeConstant.js  
  */
  useEffect(() => {
    const fetchCatalog = async () => {
    try {
      
        
        const options =   {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
         
        }
       
        
        const urlFetch = `${react_api}/guestDashboard/getDashboardADP`;
       
        const response = await fetch(urlFetch, options); 
       
        const result = await response.json();
        if( result.status === HTTP_STATUS_CODES.OK){
        setCatalog(result.dashboards);
        
      }
    } catch (error) {
      toast({
          title: 'Chiamata in errore',
          description: String(error),
          position: "top",
          status: 'warning',
          duration: 4000,
          isClosable: true});
    }
      };
    fetchCatalog();}
, []);
  



    return(
        <>
        <Box alignItems={"center"} justifyContent={"center"} display={"flex"} color={"rgba(11,78,90,255)"} bgColor={"#f6f3ec"}><Heading>Dashboards</Heading></Box>
        <TableContainer px={"1%"} paddingTop={"1%"} >
        
        <Table variant='striped' borderRadius={"10%"}>
        
      <Thead bgColor={"#0888BE"}>
        <Tr>
          <Th w={"15%"} color={"white"}>Dashboard name</Th>
          <Th w={"15%"} color={"white"}>Dashboard Id</Th>
          <Th w={"10%"} color={"white"}>Description</Th>
          <Th w={"20%"} color={"white"}>Categories</Th>
          
        </Tr>
      </Thead>
     
      <Tbody  maxHeight="100%" overflowY="auto" borderWidth={1} boxShadow="lg">
      {catalog.length > 0 ? 
      
    (catalog.map(item=> (
   
    <Tr>
       {/*nome dataset*/} <Td whiteSpace={"normal"} wordBreak={"break-word"}> {item.name}</Td>
       {/*visibilità*/} <Td whiteSpace={"normal"} wordBreak={"break-word"}>{item.id}</Td>
       {/*categorie*/} <Td whiteSpace={"normal"} wordBreak={"break-word"}>{item.description}</Td>
       {/*responsabile*/}<Td whiteSpace={"normal"} wordBreak={"break-word"}>{item.categories}</Td>
       
       </Tr>))): (<Tr><Td colSpan="6" textAlign="center">Nessun dato disponibile</Td></Tr>)}

      </Tbody>
      
     
    </Table> 
  </TableContainer>
  </>



    )


}






