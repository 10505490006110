import { Box, Grid, GridItem, Heading, useToast } from "@chakra-ui/react";
import Dataset from './Dataset';
import DatasetOttenuti from './DatasetOttenuti';
import Risultati from "./Risultati";
import Elaborazioni from "./Elaborazioni"; 
import { useEffect, useState } from "react";
import StagingArea from "./StagingArea";
//import { useSession } from '../SessionContext';
//  import  {prova_variabile} from '../config';
//import BGImage from './BgImage.jpg';
import DatasetPubblici from './DatasetPubblici';

export default function Home() {
    //var accesso = 0;
    const toast = useToast();
    
    //const { sessionData } = useSession();   
   // const { isOpen, onClose } = useDisclosure( {defaultIsOpen: true} );
    
       
          
    return (
      
           <Box h="100%" w="100%" bgColor={"#f6f3ec"} overflow={"auto"}>
            
           
            
            
            <Grid templateRows='repeat(3, 1fr)' templateColumns='repeat(4, 1fr)' h='100%'  w='99%' gap="2" margin={2}>
            <GridItem colSpan={2} p={"10px"} borderWidth={2} borderColor={"#0888BE"} borderRadius={"20px"} bgColor={"#f6f3ec"}>
                <Dataset></Dataset>
              </GridItem>
              <GridItem colSpan={2} p={"10px"} borderWidth={2} borderColor={"#0888BE"} borderRadius={"20px"} bgColor={"#f6f3ec"}>
                <DatasetOttenuti></DatasetOttenuti>
              </GridItem>
              <GridItem colSpan={2} p={"10px"}  borderWidth={2} borderColor={"#0888BE"} borderRadius={"20px"} bgColor={"#f6f3ec"}>
                <StagingArea></StagingArea>
              </GridItem> 
              <GridItem colSpan={2} p={"10px"}  borderWidth={2} borderColor={"#0888BE"} borderRadius={"20px"} bgColor={"#f6f3ec"}> 
                <DatasetPubblici></DatasetPubblici>
              </GridItem> 
              <GridItem colSpan={2} p={"10px"} borderWidth={2} borderColor={"#0888BE"} borderRadius={"20px"} bgColor={"#f6f3ec"}>
                <Elaborazioni></Elaborazioni>
              </GridItem>
              <GridItem colSpan={2} p={"10px"} borderWidth={2} borderColor={"#0888BE"} borderRadius={"20px"} bgColor={"#f6f3ec"}>
                  <Risultati></Risultati>       
              </GridItem>
              
            </Grid></Box>
          )
           



        
    



}
