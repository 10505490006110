import { Component } from "react";
import {Box} from '@chakra-ui/react';


class Footer extends Component {
    render(){
        return(
            <Box h="100%" w="100%" paddingBottom={0} ><Box w="100%" h="100%"  border={1} borderRadius={'5'} color={'white'} fontFamily={'inherit'} fontSize={'md'} paddingLeft={'45%'}> Copyright © 2023 - Amelia </Box></Box>



        )
    }
}
export default Footer;