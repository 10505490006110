

import React, {Component} from 'react';
import logoGrins from './images/LogoGrins.png';
import logo_extra from './images/logo_extra.png';
import {  Image, Box, Text} from '@chakra-ui/react';

class Header extends Component {

   

    render(){
        return(
            <Box p="10px" w={"100%"} bg={"rgba(11,78,90,255)"} display="flex" justifyContent="space-between" alignItems="center">
              <Box paddingLeft={0}>
                <Image src={logoGrins} alt='Logo Grins Foundation' height={"90%"} width={"90%"} />
              </Box>
              <Box display="flex" gap={"40px"} >
                <Image src={logo_extra} alt="finanziato dall'Unione Europea" height={"90%"} width={"90%"} />
                <Box w="20%" h="10%" borderRadius={"10px"} bgColor={"yellowgreen"} padding={"1%"}><Text color={"white"} fontSize={"sm"} textAlign={"center"}>Copyright © 2023  Amelia Test</Text></Box>
              </Box>
          </Box>
            
        )
    }
    



    
  
}

export default Header;
